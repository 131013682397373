/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
 *
 * PriceListCustomer
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, intlShape } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import moment from 'moment';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import styled from 'styled-components';
import SimpleFormikField from 'novigo-simple-formik-field';
import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import { Segment, Header, Grid, Divider } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import makeSelectPriceListCustomer from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import FormikDate from '../../components/FormikDate';
import { WrapperTool, ListWrapper, Page } from '../../components/Layout';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import Modal from '../../components/Modal';
import { ModalNewBtn, EditBtn } from '../../components/Buttons';
import destinationService from '../../shared/services/destination';
import PermissionManager from '../../components/Permission';
import RelatedData from '../../components/RelatedData';
/*
 * Specific import
 */

import { API } from '../../global-constants';

import request from '../../shared/services/request';
import priceListCustomerService from '../../shared/services/pricelistcustomer';
import PriceListCustomerForm, {
  validation,
} from '../../components/Forms/PriceListCustomer';
import PriceListFormModal from '../../components/FormManagerModal';
import ProductPriceCustomer from '../ProductPriceCustomer';
import { CanManage } from '../Can/utils';

/* eslint-disable react/prefer-stateless-function */
export class PriceListCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showModal: false,
      reloadIndex: 0,
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'priceListCustomer',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'modify' ||
      this.props.match.params.action === 'view'
    ) {
      this.setState({ showForm: true });
    } else if (
      this.props.match.params.action === 'delete' ||
      (CanManage(this.props.user) &&
        this.props.match.params.action === 'manage') ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showModal: true });
      this.setState({ showForm: false });
    } else {
      this.setState({ showModal: false });
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
    this.props.history.push('/price-list-customer');
  }

  reloadData = () => {
    this.closeModal();
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));
  };

  transformData = data => ({
    ...data,
    startDate: data.startDate ? moment(data.startDate) : null,
    endDate: data.endDate ? moment(data.endDate) : null,
    customer: _.get(data, '_embedded.customer') && {
      value: _.get(data, '_embedded.customer.id'),
      label: _.get(data, '_embedded.customer.displayName'),
    },
    destination: _.get(data, '_embedded.destination') && {
      value: _.get(data, '_embedded.destination.id'),
      label: _.get(data, '_embedded.destination.businessName'),
    },
  });

  cleanData = data => {
    const cleanData = _.cloneDeep(data);

    _.unset(cleanData, 'id');
    _.unset(cleanData, '_links');
    _.unset(cleanData, '_embedded');
    _.unset(cleanData, 'createdAt');
    _.unset(cleanData, 'updatedAt');
    _.unset(cleanData, 'deletedAt');
    _.unset(cleanData, 'createdBy');
    _.unset(cleanData, 'prices');
    _.unset(cleanData, 'readAt');
    _.unset(cleanData, 'writeAt');

    cleanData.startDate =
      _.get(cleanData, 'startDate') &&
      moment(cleanData.startDate).format('YYYY-MM-DD');

    cleanData.endDate =
      _.get(cleanData, 'endDate') &&
      moment(cleanData.endDate).format('YYYY-MM-DD');

    cleanData.customer = _.get(cleanData, 'customer.value', cleanData.customer);

    cleanData.destination = _.get(
      cleanData,
      'destination.value',
      cleanData.destination,
    );
    return cleanData;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <Page>
          <BreadcrumbsItem to="/price-list-customer">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/price-list-customer/manage">
                {this.context.intl.formatMessage(messages.manage)}
              </BreadcrumbsItem>
              <Header as="h2" style={{ marginBottom: '1px' }}>
                <FormattedMessage {...messages.manage_price_list} />
              </Header>
              <Divider />
              <ProductPriceCustomer {...this.props} />
            </>
          ) : (
            <>
              <Header as="h2" dividing>
                <FormattedMessage {...messages.title} />
              </Header>
              {this.state.showModal && (
                <>
                  <BreadcrumbsItem to="/price-list-customer/edit">
                    {actionToBreadcrumb({
                      action: this.props.match.params.action,
                      intl: this.context.intl,
                    })}
                  </BreadcrumbsItem>
                  <PriceListFormModal
                    {...this.props}
                    modalSize="tiny"
                    open={this.state.showModal}
                    onClose={() => this.closeModal()}
                    reloadData={this.reloadData}
                    modalProps={{ style: { width: 400 } }}
                    modalTitle={actionToBreadcrumb({
                      action: this.props.match.params.action,
                      intl: this.context.intl,
                    })}
                    formManagerProps={{
                      key: this.props.match.params.action,
                      router: this.props,
                      entityName: 'price-list-customer',
                      permissions: this.props.user,
                      onCleanData: this.cleanData,
                      afterCreate: () => this.reloadData(),
                      client: pars =>
                        request(pars).then(res => this.transformData(res.data)),
                      validation: values =>
                        validation(values, this.context.intl),
                      mask: fmProps => (
                        <PriceListCustomerForm
                          {...fmProps}
                          editRoute={`/price-list-customer/${this.props.match.params.id
                          }/modify`}
                          history={this.props.history}
                        />
                      ),
                    }}
                  />
                </>
              )}
              <ListWrapper>
                <ListManager
                  entityName="price-list-customer"
                  sessionKey="price-list-customer"
                  basePath={API.BASE_URL}
                  locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                  permissions={this.props.user}
                  router={this.props}
                  client={pars => request(pars).then(res => res.data)}
                  defaultOrder={[
                    {
                      type: 'field',
                      field: 'createdAt',
                      direction: 'desc',
                    },
                  ]}
                  reloadIndex={this.state.reloadIndex}
                  canCreate={{
                    active: CanManage(this.props.user),
                  }}
                  canExport={{
                    exportLabelPrefix: this.context.intl.formatMessage(
                      appMessages.export_to,
                    ),
                  }}
                  aliases={[
                    {
                      type: 'leftjoin',
                      field: 'destination',
                      alias: 'destination',
                    },
                  ]}
                  persistentFilter={[
                    {
                      type: 'eq',
                      where: 'and',
                      field: 'isActive',
                      value: `true`,
                    },
                  ]}
                  columns={[
                    {
                      key: 'code',
                      name: this.context.intl.formatMessage(messages.code),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'businessName',
                      useAlias: 'destination',
                      name: this.context.intl.formatMessage(
                        messages.business_name,
                      ),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, '_embedded.destination.businessName'),
                      exportFormatter: ({ data }) =>
                        _.get(data, '_embedded.destination.businessName'),
                    },
                    {
                      key: 'code',
                      useAlias: 'destination',
                      name: this.context.intl.formatMessage(
                        messages.destination,
                      ),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, '_embedded.destination.code'),
                      exportFormatter: ({ data }) =>
                        _.get(data, '_embedded.destination.code'),
                    },
                    {
                      key: 'description',
                      name: this.context.intl.formatMessage(
                        messages.description,
                      ),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'startDate',
                      name: this.context.intl.formatMessage(messages.startDate),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, 'startDate', null) &&
                        moment(data.startDate).format('DD/MM/YYYY'),
                      exportFormatter: ({ data }) =>
                        _.get(data, 'startDate', null) &&
                        moment(data.startDate).format('DD/MM/YYYY'),
                    },
                    {
                      key: 'endDate',
                      name: this.context.intl.formatMessage(messages.endDate),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, 'endDate', null) &&
                        moment(data.endDate).format('DD/MM/YYYY'),
                      exportFormatter: ({ data }) =>
                        _.get(data, 'endDate', null) &&
                        moment(data.endDate).format('DD/MM/YYYY'),
                    },
                  ]}
                  canSearchCustom={{
                    active: true,
                    title: this.context.intl.formatMessage(appMessages.search),
                    buttonLabel: this.context.intl.formatMessage(
                      appMessages.search,
                    ),
                    formStructure: (
                      <>
                        <Grid.Column>
                          <SimpleFormikField
                            name="code"
                            label={this.context.intl.formatMessage(
                              messages.code,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <SimpleFormikField
                            name="description"
                            label={this.context.intl.formatMessage(
                              messages.description,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <FormikDate
                            name="startDate"
                            label={this.context.intl.formatMessage(
                              messages.startDate,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <FormikDate
                            name="endDate"
                            label={this.context.intl.formatMessage(
                              messages.endDate,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <FormikAsyncSelect
                            name="destination"
                            label={this.context.intl.formatMessage(
                              messages.destination,
                            )}
                            loadOptions={(searchText, callback) =>
                              destinationService.getOptions(
                                searchText,
                                callback,
                              )
                            }
                          />
                        </Grid.Column>
                      </>
                    ),
                    onApplyFilter: (
                      values,
                      formikActionsBag,
                      applyFilterFunction,
                    ) => {
                      const filters = [];
                      const filterLabels = [];

                      if (values.code) {
                        filterLabels.push({
                          key: 'code',
                          name: this.context.intl.formatMessage(messages.code),
                          value: values.code,
                          filterField: 'code',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'code',
                          value: `%${values.code}%`,
                        });
                      }

                      if (values.description) {
                        filterLabels.push({
                          key: 'description',
                          name: this.context.intl.formatMessage(
                            messages.description,
                          ),
                          value: values.description,
                          filterField: 'description',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'description',
                          value: `%${values.description}%`,
                        });
                      }

                      if (values.startDate) {
                        filterLabels.push({
                          key: 'startDate',
                          name: this.context.intl.formatMessage(
                            messages.startDate,
                          ),
                          value: moment(values.startDate).format('YYYY-MM-DD'),
                          filterField: 'startDate',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'startDate',
                          value: `%${values.startDate.format('YYYY-MM-DD')}%`,
                        });
                      }

                      if (values.endDate) {
                        filterLabels.push({
                          key: 'endDate',
                          name: this.context.intl.formatMessage(
                            messages.endDate,
                          ),
                          value: moment(values.endDate).format('YYYY-MM-DD'),
                          filterField: 'endDate',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'endDate',
                          value: `%${values.endDate.format('YYYY-MM-DD')}%`,
                        });
                      }

                      if (values.destination) {
                        filterLabels.push({
                          key: 'destination',
                          name: this.context.intl.formatMessage(
                            messages.destination,
                          ),
                          value: values.destination.label,
                          filterField: 'id',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          alias: 'destination',
                          field: 'id',
                          value: `%${values.destination.value}%`,
                        });
                      }

                      applyFilterFunction(
                        filters,
                        filterLabels,
                        formikActionsBag,
                      );
                    },
                  }}
                  defaultActions={{
                    visualize: PermissionManager.CanI(
                      'read',
                      'priceListCustomer',
                    ),
                    modify: CanManage(this.props.user),
                    delete: CanManage(this.props.user),
                    grouped: false,
                    moreActions: [
                      PermissionManager.CanI(
                        'list',
                        'productPriceCustomer',
                      ) && {
                        icon: 'th list',
                        label: this.context.intl.formatMessage(
                          appMessages.manage,
                        ),
                        key: 'manageRelatedData',
                        action: data => {
                          this.props.history.push(
                            `/price-list-customer/${data.id}/manage`,
                          );
                        },
                      },
                    ].filter(o => o),
                  }}
                />
              </ListWrapper>
            </>
          )}
        </Page>
      </>
    );
  }
}

PriceListCustomer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  values: PropTypes.object,
  entity: PropTypes.string,
  history: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  priceListCustomer: makeSelectPriceListCustomer(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'priceListCustomer', reducer });
const withSaga = injectSaga({ key: 'priceListCustomer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PriceListCustomer);

PriceListCustomer.contextTypes = {
  intl: PropTypes.object.isRequired,
};
