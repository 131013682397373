/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form, TextArea, Loader, Dimmer } from 'semantic-ui-react';
import Select from 'react-select';
import { PaddedColumn } from '../../Layout';
import FormikField from '../../FormikField';
import messages from '../Anagrafica/messages';
import FormikDate from '../../FormikDate';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import UserService from '../../../shared/services/users';
import StaffService from '../../../shared/services/staff';
import NoteService from '../../../shared/services/note';

export const BaseForm = props => {
  const { isReadOnly = false, values, setFieldValue } = props;
  const [noteTypes, setNoteTypes] = useState([]);
  const [noteReasons, setNoteReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Preselect simple note if we don't have one
    if (_.isEmpty(props, 'values.noteType.value')) {
      setFieldValue(
        'noteType',
        props.noteTypes.find(type => type.label === 'Semplice'),
      );
    }
    NoteService.getNoteTypeOptions().then((res = []) => {
      setNoteTypes(res);
      if (_.get(values, 'noteType.value') === '') {
        setFieldValue('noteType', res.find(type => type.label === 'Semplice'));
      }
    });
  }, []);

  /**
   * When the note type changes, the list of available reasons can be different.
   */
  useEffect(
    () => {
      NoteService.getReasonTypeOptions().then(res => {
        if (_.get(props, 'values.noteType.label') === 'Richiesta Intervento') {
          setNoteReasons(noteReasons.filter(item => item.technical));

          // Reset current note reason because it's not technical.
          if (!_.get(props, 'values.noteReason.technical', false)) {
            props.setFieldValue('noteReason', null);
          }

          return;
        }

        setNoteReasons(res);
      });
    },
    [props.values.noteType],
  );

  useEffect(
    () => {
      if (!_.isEmpty(values) || _.has(values, 'noteType')) {
        setLoading(false);
      }
    },
    [values],
  );

  return (
    <>
      {loading && (
        <Dimmer>
          <Loader size="medium" />
        </Dimmer>
      )}
      <Form
        autoComplete="off"
        onSubmit={props.handleSubmit}
        loading={props.isLoading || props.isSubmitting}
      >
        <Grid columns="equal">
          <Grid.Row>
            <PaddedColumn width={8}>
              <FormikField
                readOnly={isReadOnly}
                name="noteType"
                label={props.intl.formatMessage(messages.note_type)}
                {...props}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={values.noteType}
                  name="noteType"
                  options={noteTypes}
                  onChange={data => setFieldValue('noteType', data)}
                />
              </FormikField>
            </PaddedColumn>

            <PaddedColumn width={16}>
              <FormikAsyncSelect
                {...props}
                name="assignee"
                label={props.intl.formatMessage(messages.assignee)}
                readOnly={isReadOnly}
                onChange={val => setFieldValue('assignee', val)}
                asyncSelectProps={{
                  disabled: isReadOnly,
                }}
                loadOptions={(searchText, callback) =>
                  UserService.getAsyncUserOptions(searchText, callback)
                }
              />
            </PaddedColumn>

            <PaddedColumn width={16}>
              <FormikAsyncSelect
                {...props}
                name="users"
                asyncSelectProps={{
                  isMulti: true,
                  disabled: isReadOnly,
                }}
                value={values.users || []}
                readOnly={isReadOnly}
                label={props.intl.formatMessage(messages.send_mail_to)}
                onChange={val => setFieldValue('users', val)}
                loadOptions={(searchText, callback) =>
                  UserService.getAsyncUserOptions(searchText, callback)
                }
              />
            </PaddedColumn>

            {values.noteType &&
              values.noteType.label === 'Richiesta Intervento' && (
                <>
                  <PaddedColumn width={16}>
                    <FormikField
                      readOnly={isReadOnly}
                      name="noteReason"
                      label={props.intl.formatMessage(messages.note_motivation)}
                      {...props}
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={values.noteReason}
                        options={noteReasons}
                        name="noteReason"
                        onChange={data => setFieldValue('noteReason', data)}
                      />
                    </FormikField>
                  </PaddedColumn>
                  <PaddedColumn width={8}>
                    <FormikAsyncSelect
                      {...props}
                      name="staff"
                      label={props.intl.formatMessage(messages.talked_to)}
                      readOnly={isReadOnly}
                      onChange={val => setFieldValue('staff', val)}
                      loadOptions={(searchText, callback) =>
                        StaffService.getOptions(searchText, callback)
                      }
                    />

                    {/* <SimpleFormikField
                  {...props}
                  name="talkedTo"
                  label={props.intl.formatMessage(messages.talked_to)}
                  placeholder={props.intl.formatMessage(messages.talked_to)}
                  disabled={isReadOnly}
                /> */}
                  </PaddedColumn>
                </>
              )}

            <PaddedColumn width={16}>
              <FormikField
                readOnly={isReadOnly}
                required
                name="noteText"
                label={props.intl.formatMessage(messages.note)}
                {...props}
              >
                <TextArea
                  onChange={(e, data) => setFieldValue('noteText', data.value)}
                  value={_.get(props, 'values.noteText', '')}
                  placeholder={props.intl.formatMessage(
                    messages.note_placeholder,
                  )}
                />
              </FormikField>
            </PaddedColumn>
            <PaddedColumn width={8}>
              <FormikDate
                required
                name="expireDate"
                label={props.intl.formatMessage(messages.expire_date)}
                disabled={isReadOnly}
                readOnly={isReadOnly}
              />
            </PaddedColumn>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isReadOnly: PropTypes.bool,
};

export default injectIntl(BaseForm);
