import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';
import { intlShape } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import AgentService from '../../../shared/services/agent';

import { PaddedColumn } from '../../Layout';
import DestinationService from '../../../shared/services/destination';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import OrderService from '../../../shared/services/order';
import messages from './messages';

const SharedSearchBar = ({ intl, ...props }) => {
  const { values, setFieldValue } = props;

  /**
   * When the noteDate changes, than check if it's equal to the current day in order to set the 'onlyToday' field.
   */
  useEffect(
    () => {
      setFieldValue(
        'onlyToday',
        values.noteDate
          ? moment().isSame(moment(values.noteDate), 'day')
          : null,
      );
    },
    [values.noteDate],
  );

  return (
    <>
      <PaddedColumn>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.orderNumber)}
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          isClearable
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            DestinationService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          isClearable
          name="state"
          label={intl.formatMessage(messages.state)}
          loadOptions={(searchText, callback) =>
            OrderService.getStateOptions(callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          name="agent1"
          label={intl.formatMessage(messages.agent)}
          entityName="agent"
          loadOptions={(searchText, callback) =>
            AgentService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn width={2}>
        <FormikDate
          name="createdAtFrom"
          label={intl.formatMessage(messages.start)}
        />
      </PaddedColumn>
      <PaddedColumn width={2}>
        <FormikDate
          name="createdAtTo"
          label={intl.formatMessage(messages.end)}
        />
      </PaddedColumn>
    </>
  );
};

SharedSearchBar.defaultProps = {
  isDestination: false,
};

SharedSearchBar.propTypes = {
  isDestination: PropTypes.bool,
};

SharedSearchBar.contextTypes = {
  intl: PropTypes.shape(intlShape),
};

export { SharedSearchBar };
