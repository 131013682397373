/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import _, { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import Styled from 'styled-components';
import {
  Dimmer,
  Divider,
  Header,
  Loader,
  Grid,
  Button,
  Form,
  List,
  Dropdown,
  Icon,
  Popup,
  Label,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import moment from 'moment';
import OfferManageForm from '../../components/Forms/OfferForm/manageForm';
import { OfferRowColumns, OfferStateColorMap } from './listColumns';
import { Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import NoteSection from '../../components/Forms/OfferForm/manageForm/formParts/NoteSection';
import DiscountTypeService from '../../shared/services/discountType';
/*
* Specific import
*/
import OfferTypologyService from '../../shared/services/offertypology';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import {
  convertUTCToLocal,
  downloadFileFromAjaxResponse,
} from '../../utils/functions';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import {
  OFFER_STATES,
  OFFER_STATE_ACCEPTED,
  OFFER_STATE_ALL,
  OFFER_STATE_LOSS,
  OFFER_STATE_NOT_ACCEPTED,
  OFFER_STATE_TO_BE_ACCEPTED,
  OFFER_STATE_WIN,
} from './constants';
import messages from './messages';
import BaseSearch from '../../components/BaseSearch';
import { OfferSearchBar } from '../../components/SearchBars/Offer';
import FilterBox from '../../components/FilterBox';
import { getFilterData } from '../../components/SearchBars/Offer/functions';
import OfferService from '../../shared/services/offer';
import { FormBackBtn } from '../../components/Buttons';
import SideBarOffer from './sidebar';
import DeterchimicaTableGridCustom from '../../components/DeterchimicaTableGridCustom';
import OfferRowService from '../../shared/services/offerrow';
import ChangeCustomerModal from '../../components/Forms/OfferForm/manageForm/modals/changeCustomerModal';
import AddProductModal from '../../components/Forms/OfferForm/manageForm/modals/addProductModal';
import {
  createOffer,
  deleteOffer,
  duplicateOffer,
  exportOfferPdf,
  exportOfferWord,
  modifyOffer,
  setOfferState,
  updateOffer,
  viewOffer,
} from './actions';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikInput from '../../components/FormikInput';
import FormikDate from '../../components/FormikDate';
import ButtonDropdownCollapse from '../../components/DeterchimicaEntityGrid/components/ButtonDropdownCollapse';
import ProductService from '../../shared/services/product';
import { usePrevious } from '../../shared/lib/common';

const Offer = props => {
  const { history, intl, stylesSetting } = props;
  const persistentFilters = [
    {
      type: 'leftjoin',
      field: 'destination',
      alias: 'id',
    },
    {
      type: 'leftjoin',
      field: 'typology',
      alias: 'typology',
    },
    {
      type: 'leftjoin',
      field: 'rows',
      alias: 'offerrow',
    },
    { type: 'eq', field: 'finalized', value: 1 },
    {
      type: 'groupby',
      field: 'id',
    },
  ];

  /** Forms and modals display */
  const [showOfferForm, setShowOfferForm] = useState(false);
  const [notesOpen, setNotesOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showGoodsDiscountModal, setShowGoodsDiscountModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  /** Filters */
  const [sortFilter, setSortFilter] = useState({
    type: 'field',
    field: 'createdAt',
    direction: 'desc',
  });
  const [filters, setFilters] = useState([
    {
      type: 'isnotnull',
      field: 'state',
    },
  ]);
  const [initialValues, setInitialValues] = useState({
    state: {
      label: OFFER_STATE_ALL,
      value: null,
    },
  });
  const [filterLabels, setFilterLabels] = useState([
    {
      filterField: 'state',
      name: 'State',
      value: OFFER_STATE_ALL,
    },
  ]);
  const [removedFilter, setRemovedFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchedValue, setSearchedValue] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });

  /** Selected offer data */
  const [selectedOffer, setSelectedOffer] = useState(null);
  const previousSelectedOffer = usePrevious(selectedOffer);
  const [selectedOfferRows, setSelectedOfferRows] = useState([]);
  const [selectedOfferRowsUndo, setSelectedOfferRowsUndo] = useState([]);
  const [selectedOfferErrors, setSelectedOfferErrors] = useState([]);

  /** Loading flags */
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [isCreatingOffer, setIsCreatingOffer] = useState(false);
  const [isSettingOfferWin, setIsSettingOfferWin] = useState(false);
  const [isSettingOfferLost, setIsSettingOfferLost] = useState(false);
  const [isSettingOfferNotApproved, setIsSettingOfferNotApproved] = useState(
    false,
  );
  const [isDuplicatingOffer, setIsDuplicatingOffer] = useState(false);
  const [isApprovingOffer, setIsApprovingOffer] = useState(false);

  const [isLoadingOfferRows, setIsLoadingOfferRows] = useState(false);
  const [isInsertingOfferRow, setIsInsertingOfferRow] = useState(false);
  const [isSavingOfferRows, setIsSavingOfferRows] = useState(false);

  const [prodottiTypology, setProdottiTypology] = useState('');
  const [offers, setOffers] = useState({});
  const [isRowEditable, setIsRowEditable] = useState(false);

  /** Handles component mount */
  useEffect(() => {
    checkPermission();
    getProdottiTypology();
  }, []);

  /** Handles the change of url and url parameters. */
  useEffect(
    () => {
      checkPermission();
      // Reset offer selection
      setSelectedOffer(null);
      // Show or hide the offer form based on the url action
      if (
        props.match.params.action === 'modify' ||
        props.match.params.action === 'new'
      ) {
        setShowOfferForm(true);
      } else if (
        props.match.params.action === 'view' ||
        props.match.params.action === 'delete' ||
        props.match.params.action === 'manage' ||
        props.match.params.action === 'new' ||
        props.match.params.id === 'new'
      ) {
        setShowOfferForm(true);
      } else {
        setShowOfferForm(false);
      }
    },
    [props.location.key, props.match.params.action, props.match.params.id],
  );

  /**
   * Handles the change of current page.
   */
  useEffect(
    () => {
      loadOfferData();
    },
    [pagination.page],
  );

  /**
   * Handles the change of form visibility.
   */
  useEffect(
    () => {
      if (!showOfferForm) {
        loadOfferData();
      }
    },
    [showOfferForm],
  );

  /**
   * Handles the change of filters and sort.
   */
  useEffect(
    () => {
      // Reset pagination to page 1. This will also trigger a new data load.
      if (pagination.page !== 1) {
        setPagination({
          ...pagination,
          page: 1,
        });
      } else loadOfferData();
    },
    [filters, sortFilter],
  );

  /** Handles the change of offers list. */
  useEffect(
    () => {
      // Refresh the selected offer if any
      if (selectedOffer) {
        const foundOffer = offers.find(o => o.id === selectedOffer.id);
        if (foundOffer) setSelectedOffer(transformData(foundOffer));
      }
    },
    [offers],
  );

  /**
   * Handles the change of the selected offer.
   */
  useEffect(
    () => {
      if (selectedOffer) {
        // Reset the editable mode if the offer id has changed
        if (
          selectedOffer &&
          previousSelectedOffer &&
          selectedOffer.id !== previousSelectedOffer.id
        ) {
          setIsRowEditable(false);
        }
        // Set the compliant errors
        setSelectedOfferErrors([
          // Average discount error
          ...(selectedOffer.nonCompliantAverageDiscount
            ? [intl.formatMessage(messages.non_compliant_average_discount)]
            : []),
          // Product discount error
          ...(selectedOffer.nonCompliantProductDiscount > 0
            ? [
              `${intl.formatMessage(messages.non_compliant_product_discount, {
                value: selectedOffer.nonCompliantProductDiscount,
              })}`,
            ]
            : []),
          // Product markup error
          ...(selectedOffer.nonCompliantProductMarkup > 0
            ? [
              `${intl.formatMessage(messages.non_compliant_product_markup, {
                value: selectedOffer.nonCompliantProductMarkup,
              })}`,
            ]
            : []),
          // Insolvent error
          ...(selectedOffer.nonCompliantInsolvent
            ? [intl.formatMessage(messages.non_compliant_insolvent)]
            : []),
        ]);
        let rows = [];
        setIsLoadingOfferRows(true);
        // Get the following information for the offer rows:
        // - is ordered
        // - last offer price
        const ids = selectedOffer._embedded.rows.map(el => el.product.id);
        const destination = get(selectedOffer, '_embedded.destination.id', '');
        ProductService.getProductsDetailsByIds(destination, ids)
          .then(res => {
            const { details } = res.data;

            selectedOffer._embedded.rows.forEach(row => {
              const newRow = {
                ...row,
                product: {
                  label: `${get(row, 'product.productCode', null)} - ${get(
                    row,
                    'product.title',
                    null,
                  )}`,
                  value: get(row, 'product.id', null),
                  productCode: get(row, 'product.productCode', null),
                  title: get(row, 'product.title', null),
                  sellLoan: get(row, 'product.sellLoan', null),
                },
              };
              // Assign a class to each offer based on the its compliancy
              newRow.rowClassKey = row.notCompliant
                ? 'row-not-compliant'
                : 'row-compliant';
              // Assign the ordered flag if present
              const detail = details.find(d => d.id === row.product.id);
              newRow.isOrdered = detail ? detail.ordered : false;
              newRow.offerPrice = detail ? detail.offerPrice : 0;
              newRow.netPrice = parseFloat(newRow.netPrice).toFixed(2);
              newRow.discountValue = parseFloat(newRow.discountValue).toFixed(
                2,
              );
              newRow.extraDiscount = parseFloat(newRow.extraDiscount).toFixed(
                2,
              );
              rows.push(newRow);
            });
            rows = rows.sort((a, b) => (a.rowNumber < b.rowNumber ? -1 : 1));
            rows = rows.map(row => {
              if (row.loan || row.goodsDiscount) {
                row.discountDisabled = true;
                row.discountExtraDisabled = true;
                row.netPriceDisabled = true;
              } else {
                row.discountDisabled = false;
                row.discountExtraDisabled = false;
                row.netPriceDisabled = false;
              }
              return row;
            });

            setSelectedOfferRows(rows);
            // We make a deepy copy in order to restore the original state
            setSelectedOfferRowsUndo(JSON.parse(JSON.stringify(rows)));
            setIsLoadingOfferRows(false);
          })
          .catch(err => {
            addNotification({
              title: intl.formatMessage(appMessages.error),
              message: _.get(
                err,
                'detail',
                intl.formatMessage(appMessages.an_error_has_occurred),
              ),
              isError: true,
            });
            setIsLoadingOfferRows(false);
          });
      } else {
        setSelectedOfferErrors([]);
        setSelectedOfferRows([]);
        setSelectedOfferRowsUndo([]);
      }
    },
    [selectedOffer],
  );

  /**
   * Checks if the user has required permissions.
   */
  const checkPermission = () => {
    PermissionManager.checkRESTPermission(
      'offer',
      props.history,
      props.match,
      intl,
    );
  };

  /**
   * Gets the offer typology 'Prodotti'.
   */
  const getProdottiTypology = () => {
    OfferTypologyService.searchByName('Prodotti').then(res => {
      const typologies = _.get(
        res,
        'data._embedded.offer-typology[0].id',
        null,
      );
      setProdottiTypology(typologies);
    });
  };

  /**
   * Gets the offers.
   * @param {*} resetSelectedOffer
   * @param {*} resetEditableMode
   */
  const loadOfferData = (
    resetSelectedOffer = true,
    resetEditableMode = true,
  ) => {
    // Reset selected offer if needed
    if (resetSelectedOffer) setSelectedOffer(null);
    setIsLoadingOffers(true);
    // Get offers
    OfferService.getOffers(
      pagination.page,
      pagination.pageSize,
      filters.concat(persistentFilters),
      [sortFilter],
    )
      .then(result => {
        setIsLoadingOffers(false);
        if (resetEditableMode) setIsRowEditable(false);
        const resultOffers = result.data._embedded.offer;

        // Assign a class to each offer based on the its compliancy
        resultOffers.forEach((row, index) => {
          let rows = row._embedded.rows;
          rows = rows.map(r => {
            if (r.buyPrice === null || r.buyPrice === undefined || r.buyPrice === '' || r.buyPrice === 0) {
              r.buyPrice = "0.00";
            }
            return r;
          });
          resultOffers[index].rowClassKey = row.nonCompliant
            ? 'row-not-compliant'
            : 'row-compliant';
        });

        // Update offers and pagination
        setOffers(resultOffers);
        setPagination({
          ...pagination,
          totalItems: result.data.total_items,
          pageCount: result.data.page_count,
        });
      })
      .catch(error => {
        addNotification({
          title: intl.formatMessage(appMessages.error),
          message: get(
            error,
            'data.detail',
            intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
        setIsLoadingOffers(false);
      });
  };

  /**
   * Handles the selection of an offer.
   * @param {*} data
   */
  const onSelectOffer = data => {
    setSelectedOffer(transformData(data));
  };

  /**
   * Transform data after fetch request.
   * @param {*} data
   * @returns
   */
  const transformData = data => ({
    ...data,
    typology: {
      label: `${_.get(data, 'typology.code')} -
        ${_.get(data, 'typology.description')}`,
      value: _.get(data, 'typology.id'),
    },
    expirationDate: data.expirationDate ? moment(data.expirationDate) : null,
  });

  /**
   * Handles the changes of a single offer row data.
   * @param {*} value
   * @param {*} field
   * @param {*} id
   */
  const onChangeRowData = (value, field, id) => {
    const rows = [...selectedOfferRows];
    const row = rows.find(r => r.id === id);
    row[field] = value;

    setSelectedOfferRows(rows);
  };

  /**
   * Manages the actions that can be executed on the offer rows.
   * @param {} action
   */
  const onOfferRowAction = (action, data) => {
    switch (action) {
      case 'delete':
        deleteOfferRow(data.id);
        break;
      default:
        break;
    }
  };

  /**
   * Removes the selected offer row.
   * @param {*} id
   */
  const deleteOfferRow = id => {
    OfferRowService.remove([{ id }])
      .then(res => {
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
        loadOfferData(false, false);
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  /**
   * Handles the save of all the offer rows.
   */
  const onSaveOfferRows = async () => {
    // Set loading flag
    setIsSavingOfferRows(true);
    const rows = [];

    const discountType = await DiscountTypeService.searchByName('Percentage');

    // Build the payload for each row
    selectedOfferRows.forEach(row => {
      const rowPayload = {
        ...row,
        discountType: _.get(row, 'discountType.value', null),
        discountValue:
          _.get(row, 'discountValue.value', null) ||
          _.get(row, 'discountValue', null),
        extraDiscount:
          _.get(row, 'extraDiscount.value', null) ||
          _.get(row, 'extraDiscount', null),
        flatValue:
          _.get(row, 'flatValue.value', null) || _.get(row, 'flatValue', null),
        goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
        isSample: _.get(row, 'isSample') === true ? 1 : 0,
        loan: _.get(row, 'loan') === true ? 1 : 0,
        markup: _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
        netPrice:
          _.get(row, 'netPriceRow.value', null) || _.get(row, 'netPrice', null),
        product: _.get(row, 'product.value', null),
        quantity: get(row, 'minQuantity', 0),
        difference: _.get(row, 'difference', null),
        competitorMarkup: _.get(row, 'competitorMarkup', null),
      };

      const transformedPayload = {
        id: rowPayload.id,
        product: rowPayload.product,
        competitorNote: rowPayload.competitorNote,
        discountType: discountType.data._embedded['discount-type'][0].id,
        amount: rowPayload.amount,
        quantity: rowPayload.quantity,
        buyPrice: rowPayload.buyPrice,
        competitorPrice: rowPayload.competitorPrice,
        competitorMarkup: parseFloat(rowPayload.competitorMarkup).toFixed(2),
        difference: parseFloat(rowPayload.difference).toFixed(2),
        discountValue: rowPayload.discountValue,
        discountExtra: rowPayload.extraDiscount,
        netPrice: rowPayload.netPrice,
        loan: rowPayload.loan,
        goodsDiscount: rowPayload.goodsDiscount,
        description: rowPayload.description,
      };
      rows.push(transformedPayload);
    });
    const payLoad = {
      offerRows: rows,
    };
    // Send the update command
    OfferRowService.updateRows(payLoad)
      .then(() => {
        // Reset loading flag
        setIsSavingOfferRows(false);
        // Reload the results
        loadOfferData(false);
        // Notify the result
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: props.intl.formatMessage(appMessages.success),
          isError: false,
        });
      })
      .catch(err => {
        // Reset loading flag
        setIsSavingOfferRows(false);
        // Notify the error
        addNotification({
          title: null,
          message: get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  /**
   * Handles filter removal.
   * @param {} val
   */
  const onRemoveFilter = val => {
    // Remove the filter label
    const tmpFilterLabels = filterLabels.filter(el => el.filterField !== val);
    // Remove the filter
    const tmpFilters = filters.filter(el => el.filterField !== val);
    // Reset the field into initialValues, this is necessary to reset the searchbar formikbag
    const tmpInitialValues = initialValues;
    tmpInitialValues[val] = '';
    // If state filter was removed, set it to "ALL"
    if (val === 'state') {
      tmpInitialValues.state = {
        label: OFFER_STATE_ALL,
        value: null,
      };
      tmpFilterLabels.push({
        filterField: 'state',
        name: 'State',
        value: OFFER_STATE_ALL,
      });
    }

    // Update state
    setRemovedFilter(removedFilter ? '' : val);
    setFilterLabels(tmpFilterLabels);
    setFilters(tmpFilters);
    setInitialValues(tmpInitialValues);
  };

  /**
   * Handles a new search.
   * @param {*} values
   * @param {*} param1
   * @returns
   */
  const onSearch = (values, { setSubmitting }) => {
    const {
      filterLabels: tmpFilterLabels,
      filters: tmpFilters,
    } = getFilterData({ values, intl });

    return new Promise(resolve => {
      setFilters(tmpFilters);
      setInitialValues(values);
      setFilterLabels(tmpFilterLabels);
      resolve(true);
      setSubmitting(false);
    });
  };

  /**
   * Handles the filters reset.
   */
  const onReset = () => {
    // Reset filters
    setFilters([
      {
        type: 'isnotnull',
        field: 'state',
      },
    ]);
    // Reset filter labels
    setFilterLabels([
      {
        filterField: 'state',
        key: 'state',
        name: 'State',
        value: OFFER_STATE_ALL,
      },
    ]);
    // Set all the initialValues to empty values
    const tmpInitialValues = initialValues;
    Object.keys(tmpInitialValues).forEach(key => {
      tmpInitialValues[key] = '';
    });
    // Reset state to "ALL"
    tmpInitialValues.state = {
      label: OFFER_STATE_ALL,
      value: null,
    };
    setInitialValues(tmpInitialValues);
  };

  /**
   * Handles the offers data sorting when clicked on the column header.
   */
  const onSortOffer = (field, direction) => {
    let sort = '';

    if (direction === 'none') {
      setSortFilter({
        type: 'field',
        field: 'createdAt',
        direction: 'desc',
      });
      return;
    }

    switch (field) {
      case 'code':
        sort = {
          type: 'field',
          field: 'code',
          alias: 'destination',
          direction,
        };
        break;
      case 'number':
        sort = {
          type: 'field',
          field: 'number',
          direction,
        };
        break;
      case 'businessName':
        sort = {
          type: 'field',
          field: 'businessName',
          alias: 'destination',
          direction,
        };
        break;
      case 'createdAt':
        sort = {
          type: 'field',
          field: 'createdAt',
          direction,
        };
        break;
      case 'totalPriceAmount':
        sort = {
          type: 'field',
          field: 'totalPriceAmount',
          direction,
        };
        break;
      case 'avarageDiscount':
        sort = {
          type: 'field',
          field: 'avarageDiscount',
          direction,
        };
        break;
      case 'maxDiscount':
        sort = {
          type: 'field',
          field: 'maxDiscount',
          direction,
        };
        break;
      default:
        sort = {
          type: 'field',
          field: 'sentAt',
          direction,
        };
        break;
    }
    setSortFilter(sort);
  };

  const canEditOffer = () =>
    PermissionManager.CanI('edit', 'offer') &&
    selectedOffer &&
    selectedOffer.state !== OFFER_STATE_ACCEPTED;

  const OfferColumns = () => [
    {
      key: 'code',
      name: intl.formatMessage(messages.customer),
      useAlias: 'destination',
      searchable: true,
      sortable: true,
      formatter: data => get(data, 'data._embedded.destination.code', '--'),
      exportFormatter: data =>
        get(data, 'data._embedded.destination.code', '--'),
    },
    {
      key: 'number',
      name: intl.formatMessage(messages.offer_number_year),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const number = data.documentNumber || data.number || '--';
        const year = moment(data.createdAt.date).year();

        return (
          <div>
            {number}
            <br />
            {year}
          </div>
        );
      },
    },
    {
      key: 'businessName',
      name: intl.formatMessage(messages.businessName),
      useAlias: 'destination',
      searchable: true,
      sortable: true,
      width: 400,
      formatter: data => {
        const businessName = get(
          data,
          'data._embedded.destination.businessName',
          '--',
        );
        const { note, delivery, state } = data.data;
        const stateColor = OfferStateColorMap[state] || 'grey';

        return (
          <div
            style={{ height: '100%', display: 'flex', position: 'relative' }}
          >
            <div
              style={{
                background: `${stateColor}`,
                width: '4px',
                borderRadius: '2px',
                height: 'calc(100% + 8px)',
                position: 'absolute',
                top: '-4px',
              }}
            />
            <div style={{ marginLeft: '1rem' }}>
              {businessName}
              {delivery && (
                <>
                  <br />
                  <b>{intl.formatMessage(messages.destinationDelivery)}</b>
                  {delivery}
                </>
              )}
              {note && (
                <>
                  <br />
                  <b>{intl.formatMessage(messages.offerNote)}</b>
                  {note}
                </>
              )}
            </div>
          </div>
        );
      },
      exportFormatter: data =>
        get(data, 'data._embedded.destination.businessName', '--'),
    },
    {
      key: 'agent',
      name: 'Agente',
      formatter: ({ data }) => {
        const agent = get(data, '_embedded.destination.agent1', null);
        return agent ? `${agent.displayName} (${agent.code})` : '--';
      },
      exportFormatter: ({ data }) => {
        const agent = get(data, '_embedded.destination.agent1', null);
        return agent ? `${agent.displayName} (${agent.code})` : '--';
      },
      searchable: false,
      sortable: false,
    },
    {
      key: 'sentAt',
      name: intl.formatMessage(messages.offerDate),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const { sentAt } = data;
        const localCreatedAt = sentAt ? convertUTCToLocal(sentAt.date, 'DD/MM/YYYY HH:mm')
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) => {
        if (get(data, 'sentAt', false)) {
          return moment(get(data, 'sentAt.date')).format('DD/MM/YYYY');
        }
        return '--';
      },
    },
    {
      key: 'totalPriceAmount',
      formatter: ({ data }) => {
        const totalPriceAmount = data.totalPriceAmount || '0.00';
        return totalPriceAmount;
      },
      name: intl.formatMessage(messages.totalPriceAmount),
      searchable: true,
      sortable: true,
    },
    {
      key: 'avarageDiscount',
      formatter: ({ data }) => {
        const averageDiscount = data.avarageDiscount || '0.00';
        return parseFloat(averageDiscount).toFixed(2);
      },
      name: intl.formatMessage(messages.averageDiscount),
      searchable: true,
      sortable: true,
    },
    {
      key: 'maxDiscount',
      formatter: ({ data }) => {
        const maxDiscount = data.maxDiscount || '0.00';
        return maxDiscount;
      },
      name: intl.formatMessage(messages.maxDiscount),
      searchable: true,
      sortable: true,
    },
    {
      key: 'actions',
      name: intl.formatMessage(messages.actions),
      searchable: false,
      sortable: false,
      formatter: ({ data }) => OfferActions(data),
    },
  ];

  const OfferActions = data => {
    // Permission to view the offer
    const canView = PermissionManager.CanI('read', 'offer');
    // Permission to edit the offer
    const canEdit =
      PermissionManager.CanI('edit', 'offer') &&
      data.state !== OFFER_STATE_ACCEPTED &&
      !data.imported;
    // Permission to print pdf or doc
    const canPrint = PermissionManager.CanI('edit', 'offer');

    return (
      <div>
        {/* Show button */}
        {canView ? (
          <span className="order-action">
            <Popup
              basic
              content={<FormattedMessage {...appMessages.action_view} />}
              trigger={
                <Button
                  circular
                  color="blue"
                  icon="eye"
                  onClick={() => viewOffer(history, data.id)}
                />
              }
            />
          </span>
        ) : null}
        {/* Edit button */}
        {canEdit ? (
          <span className="order-action">
            <Popup
              basic
              content={<FormattedMessage {...appMessages.action_modify} />}
              trigger={
                <Button
                  circular
                  color="blue"
                  icon="edit"
                  onClick={() => modifyOffer(history, data.id)}
                />
              }
            />
          </span>
        ) : null}
        <span className="order-action">
          <ButtonDropdownCollapse
            key="collapse-button"
            dropdownItems={[
              // Delete button
              ...(canEdit
                ? [
                  <Dropdown.Item
                    style={{ color: 'red' }}
                    key={<FormattedMessage {...appMessages.action_delete} />}
                    onClick={() => deleteOffer(history, data.id)}
                  >
                    <Icon name="trash" />
                    <FormattedMessage {...appMessages.action_delete} />
                  </Dropdown.Item>,
                ]
                : []),
              // Print PDF button
              ...(canPrint
                ? [
                  <Dropdown.Item
                    style={{ color: '#F40F02' }}
                    key={<FormattedMessage {...messages.action_print} />}
                    onClick={() => exportOfferPdf(data.id, false, intl)}
                  >
                    <Icon name="print" />
                    <FormattedMessage {...messages.action_print} />
                  </Dropdown.Item>,
                ]
                : []),
              // Print PDF group button
              ...(canPrint
                ? [
                  <Dropdown.Item
                    style={{ color: '#F40F02' }}
                    key={
                      <FormattedMessage {...messages.action_print_group} />
                    }
                    onClick={() => exportOfferPdf(data.id, true, intl)}
                  >
                    <Icon name="print" />
                    <FormattedMessage {...messages.action_print_group} />
                  </Dropdown.Item>,
                ]
                : []),
              // Print DOC button
              ...(canPrint
                ? [
                  <Dropdown.Item
                    style={{ color: '#2b579a' }}
                    key={<FormattedMessage {...messages.action_export_doc} />}
                    onClick={() => exportOfferWord(data.id, false, intl)}
                  >
                    <Icon name="file word" />
                    <FormattedMessage {...messages.action_export_doc} />
                  </Dropdown.Item>,
                ]
                : []),
              // Print DOC group button
              ...(canPrint
                ? [
                  <Dropdown.Item
                    style={{ color: '#2b579a' }}
                    key={
                      <FormattedMessage
                        {...messages.action_export_doc_group}
                      />
                    }
                    onClick={() => exportOfferWord(data.id, true, intl)}
                  >
                    <Icon name="file word" />
                    <FormattedMessage {...messages.action_export_doc_group} />
                  </Dropdown.Item>,
                ]
                : []),
              // Print CSV group button
              ...(canPrint
                ? [
                  <Dropdown.Item
                    style={{ color: '#009900' }}
                    key={
                      <FormattedMessage {...messages.action_export_excel} />
                    }
                    onClick={() => OfferService.exportOfferCSV(data.id)}
                  >
                    <Icon name="file excel" />
                    <FormattedMessage {...messages.action_export_excel} />
                  </Dropdown.Item>,
                ]
                : []),
            ].filter(o => o)}
            isGrouped={false}
          />
        </span>
      </div>
    );
  };

  const handleOnArticleRowChange = (products, data) => {
    setIsLoadingOfferRows(true);
    OfferRowService.remove([{ id: data.id }]).then(removeResponse => {
      const payload = {
        destination: selectedOffer._embedded.destination.id,
        offer: selectedOffer.id,
        products: [products],
        quantity: data.minQuantity, // NOTE: Different from orders
        flatValue: data.flatValue,
        discountValue: data.discountValue,
        extraDiscount: data.extraDiscount,
        domain: _.get(selectedOffer, 'productDomain.value', null),
        frequency: _.get(selectedOffer, 'frequency.value', null),
        competitorNote: data.competitorNote,
        description: data.description,
        goodsDiscount: data.goodsDiscount,
        loan: data.loan,
      };

      OfferRowService.create(payload)
        .then(() => {
          setIsLoadingOfferRows(false);
          setIsLoadingOffers(true);
          OfferService.findOneById(selectedOffer.id).then(offer => {
            setSelectedOffer(transformData(offer.data));
            if (_.get(offer, 'data.state', null)) {
              // FIX: setFieldValue('state', _.get(offer, 'data.state', ''));
            }
            setIsLoadingOffers(false);
          });
        })
        .catch(err => {
          setIsLoadingOfferRows(false);
          addNotification({
            title: props.intl.formatMessage(appMessages.error),

            message: _.get(
              err,
              'data.detail',
              props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          });
        });
    });
  };

  return (
    <>
      {/* Right menu sidebar */}
      {!showOfferForm && (
        <SideBarOffer
          {...props}
          collapsed={false}
          items={
            <Formik
              enableReinitialize
              initialValues={selectedOffer}
              onSubmit={values => {
                const payload = {
                  expirationDate: values.expirationDate
                    ? values.expirationDate.format('YYYY-MM-DD')
                    : null,
                  id: values.id,
                  state: OFFER_STATE_ACCEPTED,
                  typology: values.typology.value,
                };
                updateOffer(payload, intl, loadOfferData, setIsApprovingOffer);
              }}
            >
              {({ values, handleSubmit }) => {
                const isOfferSelected = values && values.id;
                // Permission to change state to win
                const canWin =
                  isOfferSelected &&
                  PermissionManager.CanI('statusUpdateApproved', 'custom') &&
                  ![OFFER_STATE_WIN, OFFER_STATE_ACCEPTED].includes(
                    values.state,
                  ) &&
                  !values.imported;
                // Permission to change state to loss
                const canLose =
                  isOfferSelected &&
                  PermissionManager.CanI('statusUpdateApproved', 'custom') &&
                  ![OFFER_STATE_LOSS, OFFER_STATE_ACCEPTED].includes(
                    values.state,
                  ) &&
                  !values.imported;
                // Permission to make changes to the offer
                const canEdit =
                  isOfferSelected &&
                  PermissionManager.CanI('edit', 'offer') &&
                  values.state !== OFFER_STATE_ACCEPTED &&
                  !values.imported;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Grid>
                      <Grid.Column style={{ textAlign: 'center' }}>
                        <Grid.Row>
                          <Icon
                            name="window maximize"
                            style={{
                              fontSize: '18px',
                              marginRight: '10px',
                              color: '#2185d0',
                            }}
                          />
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '18px',
                            }}
                          >
                            {intl.formatMessage(messages.actions)}
                          </span>
                        </Grid.Row>
                        {/* New offer button */}
                        <Grid.Row>
                          <Button
                            color="blue"
                            content={intl.formatMessage(messages.new_offer)}
                            icon="file"
                            labelPosition="left"
                            loading={isCreatingOffer}
                            onClick={() =>
                              createOffer(
                                history,
                                prodottiTypology,
                                intl,
                                setIsCreatingOffer,
                              )
                            }
                            type="button"
                          />
                        </Grid.Row>
                        {/* Buttons and fields related to the selected offer */}
                        {/* Duplicate button */}
                        {selectedOffer && (
                          <Grid.Row>
                            <Button
                              basic
                              color="blue"
                              content={intl.formatMessage(
                                messages.duplicateOffer,
                              )}
                              icon="copy"
                              loading={isDuplicatingOffer}
                              onClick={() =>
                                duplicateOffer(
                                  selectedOffer,
                                  intl,
                                  loadOfferData,
                                  setIsDuplicatingOffer,
                                )
                              }
                              type="button"
                            />
                          </Grid.Row>
                        )}
                        {canEdit && (
                          <>
                            {/* Change customer button */}
                            <Grid.Row>
                              <Button
                                basic
                                color="blue"
                                content={intl.formatMessage(
                                  messages.change_customer,
                                )}
                                icon="arrows alternate horizontal"
                                onClick={() => setShowCustomerModal(true)}
                                type="button"
                              />
                            </Grid.Row>
                            {/* Win button */}
                            {canWin && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="green"
                                  content={intl.formatMessage(
                                    messages.action_win,
                                  )}
                                  icon="checkmark"
                                  loading={isSettingOfferWin}
                                  onClick={() =>
                                    setOfferState(
                                      selectedOffer.id,
                                      OFFER_STATE_WIN,
                                      intl,
                                      loadOfferData,
                                      setIsSettingOfferWin,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Lost button */}
                            {canLose && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="orange"
                                  content={intl.formatMessage(
                                    messages.action_lose,
                                  )}
                                  icon="remove"
                                  loading={isSettingOfferLost}
                                  onClick={() =>
                                    setOfferState(
                                      selectedOffer.id,
                                      OFFER_STATE_LOSS,
                                      intl,
                                      loadOfferData,
                                      setIsSettingOfferLost,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Not approved button */}
                            {canLose && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="orange"
                                  content={intl.formatMessage(
                                    messages.action_not_approve,
                                  )}
                                  icon="ban"
                                  loading={isSettingOfferNotApproved}
                                  onClick={() =>
                                    setOfferState(
                                      selectedOffer.id,
                                      OFFER_STATE_NOT_ACCEPTED,
                                      intl,
                                      loadOfferData,
                                      setIsSettingOfferNotApproved,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Delete offer button */}
                            <Grid.Row>
                              <Button
                                basic
                                color="red"
                                content={intl.formatMessage(
                                  messages.deleteOffer,
                                )}
                                icon="trash"
                                onClick={() =>
                                  deleteOffer(history, selectedOffer.id)
                                }
                                type="button"
                              />
                            </Grid.Row>
                          </>
                        )}
                        <hr />
                        {selectedOffer && (
                          <>
                            {/* Expiration date */}
                            <Grid.Row>
                              <FormikDate
                                label={intl.formatMessage(
                                  messages.expirationDate,
                                )}
                                name="expirationDate"
                                readOnly={!canEdit}
                              />
                            </Grid.Row>
                            {/* Offer type */}
                            <Grid.Row>
                              <FormikAsyncSelect
                                label={intl.formatMessage(messages.offerType)}
                                loadOptions={(searchText, callback) =>
                                  OfferTypologyService.getOptions(
                                    searchText,
                                    callback,
                                  )
                                }
                                name="typology"
                                required
                                readOnly={!canEdit}
                              />
                            </Grid.Row>
                          </>
                        )}
                        {/* Approve button */}
                        {canEdit && (
                          <Grid.Row>
                            <Button
                              color="green"
                              content={intl.formatMessage(
                                messages.approveOffer,
                              )}
                              icon="send"
                              labelPosition="left"
                              loading={isApprovingOffer}
                            />
                          </Grid.Row>
                        )}
                        {/* List of errors on the offer */}
                        {selectedOfferErrors.length > 0 ? (
                          <Grid.Row>
                            <span>
                              <FormattedMessage {...messages.errors} />
                            </span>
                            <div>
                              <List bulleted style={{ borderWidth: 'thin' }}>
                                {selectedOfferErrors.map(err => (
                                  <List.Item key={err} style={{ color: 'red' }}>
                                    {err}
                                  </List.Item>
                                ))}
                              </List>
                            </div>
                          </Grid.Row>
                        ) : null}
                      </Grid.Column>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          }
          openMenu
          stylesSetting={stylesSetting}
        />
      )}
      <Helmet>
        <title>{intl.formatMessage(messages.title)}</title>
        <meta name="description" content="Offers" />
      </Helmet>
      <Page
        style={{ marginRight: stylesSetting.SIDEBAR_WIDTH }}
        className="restyle"
      >
        <BreadcrumbsItem to="/offer">
          {intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        {/* Offer table */}
        {!showOfferForm && (
          <>
            <Header as="h2" dividing>
              <FormattedMessage {...messages.title} />
            </Header>
            {showModal && (
              <>
                <BreadcrumbsItem to="/offers/edit">
                  {actionToBreadcrumb({
                    action: props.match.params.action,
                    intl,
                  })}
                </BreadcrumbsItem>
              </>
            )}

            <Grid>
              {isLoadingOffers && (
                <Dimmer active={isLoadingOffers}>
                  <Loader size="big" />
                </Dimmer>
              )}
              {/* Search bar */}
              <Grid.Row>
                <Grid.Column>
                  <BaseSearch
                    initialValues={initialValues}
                    onSearch={onSearch}
                    onReset={onReset}
                    customSearchFilterNumber={filters.length || 0}
                    formStructure={formik => (
                      <OfferSearchBar intl={intl} {...formik} />
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Active filter labels */}
              {filterLabels.length > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <FilterBox
                      filterLabels={filterLabels}
                      removeFilter={onRemoveFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* Offers table */}
              <Grid.Row>
                <Grid.Column>
                  <DeterchimicaTableGridCustom
                    canSelect={{
                      active: true,
                      onSelect: onSelectOffer,
                    }}
                    canPaginate={{
                      active: true,
                      pageCount: pagination.pageCount,
                      pageSize: pagination.pageSize,
                      totalItems: pagination.totalItems,
                      page: pagination.page,
                      onSelect: pageNumber => {
                        setPagination({
                          ...pagination,
                          page: pageNumber,
                        });
                      },
                      pageMax: Math.min(5, pagination.pageCount),
                    }}
                    canSort={{
                      active: true,
                      onSort: onSortOffer,
                    }}
                    columns={OfferColumns()}
                    elements={offers}
                    rowClassKey="rowClassKey"
                    selectedElement={selectedOffer}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Offer rows commands */}
              {canEditOffer() && (
                <>
                  <Grid.Row className="detail-header">
                    <div style={{ height: 'fit-content' }}>
                      <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                        {selectedOffer._embedded.destination.code}{' '}
                        {selectedOffer._embedded.destination.businessName}
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      {/* Add product button */}
                      <Button
                        basic
                        color="blue"
                        content={intl.formatMessage(messages.add_product)}
                        icon="plus"
                        loading={isInsertingOfferRow}
                        onClick={() => setShowAddProductModal(true)}
                        style={{ maxHeight: '36px' }}
                        type="button"
                      />
                      {/* Edit button */}
                      <Button
                        basic
                        color="blue"
                        content={intl.formatMessage(
                          isRowEditable ? messages.cancel : messages.edit,
                        )}
                        icon={isRowEditable ? 'cancel' : 'edit'}
                        onClick={() => {
                          if (isRowEditable)
                            setSelectedOfferRows(selectedOfferRowsUndo);
                          setIsRowEditable(!isRowEditable);
                        }}
                        type="button"
                      />
                      {/* Save button */}
                      <Button
                        color="blue"
                        content={intl.formatMessage(messages.save)}
                        disabled={!isRowEditable}
                        icon="save"
                        loading={isSavingOfferRows}
                        onClick={() => onSaveOfferRows()}
                        type="button"
                      />
                    </div>
                  </Grid.Row>
                </>
              )}
              {/* Offer row table */}
              {selectedOffer && (
                <Grid.Row>
                  {isLoadingOfferRows && (
                    <Dimmer active={isLoadingOfferRows}>
                      <Loader size="big" />
                    </Dimmer>
                  )}
                  <Grid.Column>
                    <Formik>
                      <Form>
                        <DeterchimicaTableGridCustom
                          canSelect={{ active: false }}
                          rowClassKey="rowClassKey"
                          elements={selectedOfferRows}
                          columns={OfferRowColumns(
                            intl,
                            onChangeRowData,
                            onOfferRowAction,
                            isRowEditable,
                            handleOnArticleRowChange,
                          )}
                          hiddenHeaderIfEmpty
                          emptyResults={
                            <div>
                              {props.intl.formatMessage(appMessages.no_results)}
                            </div>
                          }
                        />
                      </Form>
                    </Formik>
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* Offer rows total labels */}
              {selectedOffer && (
                <Grid.Row>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span style={{ marginRight: 10, fontWeight: 'bold' }}>
                      {`${intl.formatMessage(messages.total_offer)} € ${selectedOffer.totalPriceAmount
                        ? parseFloat(selectedOffer.totalPriceAmount).toFixed(
                          2,
                        )
                        : 0
                        } -
                      ${intl.formatMessage(messages.avarageDiscount)} % ${selectedOffer.avarageDiscount
                          ? parseFloat(selectedOffer.avarageDiscount).toFixed(2)
                          : 0
                        } -
                      ${intl.formatMessage(messages.avarageMarkup)} % ${selectedOffer.avarageMarkup
                          ? parseFloat(selectedOffer.avarageMarkup).toFixed(2)
                          : 0
                        }
 `}
                    </span>
                  </div>
                </Grid.Row>
              )}
              {selectedOffer && (
                <Grid.Row style={{ margin: 10 }}>
                  <Formik
                    enableReinitialize
                    initialValues={getInitialValuesForNotes(selectedOffer)}
                  >
                    {formikProps => (
                      <Form>
                        <NoteSection
                          {...props}
                          {...formikProps}
                          readOnly
                          isOrder={false}
                          isOpen
                          setIsNotesOpen={setNotesOpen}
                        />
                      </Form>
                    )}
                  </Formik>
                </Grid.Row>
              )}
            </Grid>

            {/* Customer change modal */}
            {selectedOffer && (
              <ChangeCustomerModal
                intl={intl}
                open={showCustomerModal}
                onClose={() => {
                  setShowCustomerModal(false);
                }}
                onUpdate={() => {
                  loadOfferData(false);
                }}
                offer={selectedOffer}
              />
            )}

            {/* Add product modal */}
            {selectedOffer && (
              <AddProductModal
                intl={intl}
                open={showAddProductModal}
                onClose={() => {
                  setShowAddProductModal(false);
                }}
                onUpdate={() => {
                  loadOfferData(false);
                }}
                offerId={selectedOffer.id}
                destinationId={selectedOffer._embedded.destination.id}
              />
            )}
          </>
        )}
        {/* Offer form */}
        {showOfferForm && (
          <>
            <BreadcrumbsItem to="/offer/edit">
              {actionToBreadcrumb({
                action: props.match.params.action,
                intl,
              })}
            </BreadcrumbsItem>
            <Header as="h2" style={{ marginBottom: '1px' }}>
              {props.match.params.action === 'modify' ? (
                <FormattedMessage {...messages.manage_offer} />
              ) : (
                <FormattedMessage {...messages.new_offer} />
              )}
            </Header>

            <Divider />
            <FormBackBtn history={props.history} />
            <OfferManageForm
              {...props}
              offerStates={OFFER_STATES}
              exportOfferPdf={exportOfferPdf}
              offerData={offers}
              updateOffer={loadOfferData}
            />
          </>
        )}
      </Page>
    </>
  );
};

function getInitialValuesForNotes(selectedOffer) {
  return {
    delivery: selectedOffer.delivery ? selectedOffer.delivery : selectedOffer._embedded.destination.delivery,
    note: selectedOffer.note != null ? selectedOffer.note : '',
    number: selectedOffer.number != null ? selectedOffer.number : '',
    state: selectedOffer.state != null ? selectedOffer.state : '',
    subject: selectedOffer.subject != null ? selectedOffer.subject : '',
    payment: selectedOffer.payment != null ? selectedOffer.payment : '',
    port: selectedOffer.port != null ? selectedOffer.port : '',
    typology:
      selectedOffer.typology != null
        ? selectedOffer.typology
        : { label: '', value: '' },
    totalBuyPriceAmount:
      selectedOffer.totalBuyPriceAmount != null
        ? selectedOffer.totalBuyPriceAmount
        : 0,
    totalGrossPriceAmount:
      selectedOffer.totalGrossPriceAmount != null
        ? selectedOffer.totalGrossPriceAmount
        : 0,
    totalPriceAmount:
      selectedOffer.totalPriceAmount != null
        ? selectedOffer.totalPriceAmount
        : 0,
    finalized:
      selectedOffer.finalized != null ? selectedOffer.finalized : false,

    expirationDate:
      selectedOffer.expirationDate != null
        ? selectedOffer.expirationDate
        : null,
    createdAt: selectedOffer.createdAt != null ? selectedOffer.createdAt : null,
    updatedAt: selectedOffer.updatedAt != null ? selectedOffer.updatedAt : null,

    maxDiscount:
      selectedOffer.maxDiscount != null ? selectedOffer.maxDiscount : 0,
    avarageDiscount:
      selectedOffer.avarageDiscount != null ? selectedOffer.avarageDiscount : 0,

    avarageMarkup:
      selectedOffer.avarageMarkup != null ? selectedOffer.avarageMarkup : 0,
    nonCompliant:
      selectedOffer.nonCompliant != null ? selectedOffer.nonCompliant : false,
    nonCompliantAverageDiscount:
      selectedOffer.nonCompliantAverageDiscount != null
        ? selectedOffer.nonCompliantAverageDiscount
        : false,
    nonCompliantInsolvent:
      selectedOffer.nonCompliantInsolvent != null
        ? selectedOffer.nonCompliantInsolvent
        : false,
    nonCompliantProductDiscount:
      selectedOffer.nonCompliantProductDiscount != null
        ? selectedOffer.nonCompliantProductDiscount
        : 0,

    rowClassKey:
      selectedOffer.rowClassKey != null ? selectedOffer.rowClassKey : '',
  };
}

export default Offer;

// Offer.propTypes = {
//   user: PropTypes.object,
//   location: PropTypes.object,
//   match: PropTypes.object,
//   history: PropTypes.object,
// };

// const mapStateToProps = createStructuredSelector({});

// function mapDispatchToProps(dispatch) {
//   return {
//     dispatch,
//   };
// }

// const withConnect = connect(
//   mapStateToProps,
//   mapDispatchToProps,
// );

// const withReducer = injectReducer({ key: 'offer', reducer });
// const withSaga = injectSaga({ key: 'offer', saga });

// export default compose(
//   withReducer,
//   withSaga,
//   withConnect,
// )(Offer);

// Offer.contextTypes = {
//   intl: PropTypes.object.isRequired,
// };
