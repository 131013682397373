/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { Icon, Label, Button, Pagination } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import styled from 'styled-components';
import { RemoveBtn, EditBtn, ModalNewBtn, ExecuteBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import NoteForm from '../../CustomerNote';
import NoteExecutionForm from '../../CustomerExecutionNote';
import { addNotification } from '../../../../utils/notification';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import DestinationNoteService from '../../../../shared/services/destinationnote';
import NoteService from '../../../../shared/services/note';
import { convertUTCToLocal } from '../../../../utils/functions';

const TableOverflow = styled.div`
  display: block;
  margin: -14px -14px -10px;
  overflow-x: auto;
  padding: 14px;
  & td {
    position: relative;
  }
`;

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function NotesPart(props) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [noteTypes, setNoteTypes] = useState([]);
  const [noteReasons, setNoteReasons] = useState([]);
  const [closureNoteOptions, setClosureNoteOptions] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalExecutionNote, setModalExecutionNote] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const [isExecutingNote, setIsExecutingNote] = useState(false);
  const { entity } = props;

  const transformData = data => ({
    ...data,
    expireDate: _.get(data, 'expireDate')
      ? moment(data.expireDate.date, 'YYYY-MM-DD')
      : null,
    users: _.get(data, 'users', []).map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
    assignee: {
      value: _.get(data, 'assignee.id', ''),
      label: `${_.get(data, 'assignee.firstname', '')} ${_.get(
        data,
        'assignee.lastname',
        '',
      )}`,
    },
  });

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  useEffect(() => {
    NoteService.getNoteTypeOptions().then(res => {
      setNoteTypes(res);
    });
    NoteService.getReasonTypeOptions().then(res => {
      setNoteReasons(res);
    });
    DestinationNoteService.getClosureNoteOptions().then(res => {
      setClosureNoteOptions(res);
    });
  }, []);

  const loadData = page => {
    setLoading(true);
    DestinationNoteService.getByOwner(
      props.values.id,
      page || paginationData.page,
    ).then(res => {
      const data = _.get(res, 'data', { ...paginationDefault });
      setPaginationData({
        page: data.page,
        page_count: data.page_count,
        page_size: data.page_size,
        total_items: data.total_items,
      });
      setLoading(false);
      setItems(_.get(res, `data._embedded.${entity}-note`), []);
    });
  };

  /**
   * Close the note edit modal.
   */
  const closeNoteModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
  };

  /**
   * Open the note edit modal.
   */
  const openNoteModal = () => {
    setSelectedItem({});
    setModalOpened(true);
  };

  /**
   * Close the note execution text modal.
   */
  const closeExecutionNoteModal = () => {
    setModalExecutionNote(false);
  };

  /**
   * Open the note execution text modal.
   */
  const openExecutionNoteModal = () => {
    setModalExecutionNote(true);
  };

  /**
   * Set the given note to be executed, setting to null executedAt field.
   * @param {*} data
   */
  const handleCancelExecution = data => {
    setIsExecutingNote(true);
    const payload = {
      id: data.id || null,
      executedAt: null,
    };
    DestinationNoteService.patch(payload)
      .then(() => {
        setIsExecutingNote(false);
        loadData();
      })
      .catch(error =>
      {
        setIsExecutingNote(false);
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      },
      );
  };

  /**
   * Set the given note as executed.
   * @param {*} data
   */
  const handleExecute = data => {
    setIsExecutingNote(true);
    const config = closureNoteOptions.find(
      el => el.noteType === data.noteType.description,
    );

    /**
     * If the closure note is enabled for this note type, we display the modal to insert the closure note text
     * otherwise we directly set the selected note as executed
     *
     * The following conditions trigger the closure note flow:
     * - note type (must be enabled into closureNoteOptions)
     * - assignee (must be set)
     * - expire date (must be set)
     */
    if (config.isEnabled && data.assignee && data.expireDate) {
      setIsExecutingNote(false);
      setSelectedItem(data);
      openExecutionNoteModal();
    } else {
      DestinationNoteService.executeNote(data.id, '')
        .then(() => {
          loadData();
          setIsExecutingNote(false);
        })
        .catch(error =>
        {
          setIsExecutingNote(false);
          addNotification({
            title: null,
            message: error.data.detail,
            isError: true,
          });
        },
        );
    }
  };

  const columns = () =>
    [
      {
        key: 'noteNumber',
        name: props.intl.formatMessage(messages.noteNumber),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => {
          const message = data.closedNote !== null ? 'closure' : data.executedAt !== null ? 'done' : 'to_be_done';
          const translatedMessage = props.intl.formatMessage(messages[message]);

          const color = data.closedNote !== null ? 'teal' : data.executedAt !== null ? 'green' : 'red';

          return (
            <div>
              <Label
                ribbon
                style={{ marginBottom: 5 }}
                content={translatedMessage}
                color={color} />
              <br />
              {_.get(data, 'noteNumber', '')}
            </div>
          );
        },
      },
      {
        key: 'date',
        name: props.intl.formatMessage(messages.date),
        formatter: ({ data }) =>
        {
          const { createdAt } = data;
          const localCreatedAt = createdAt ? convertUTCToLocal(createdAt.date) : '--';
          return localCreatedAt;
        },
      },
      {
        key: 'expireDate',
        name: props.intl.formatMessage(messages.expire_date),
        formatter: ({ data }) =>
          _.get(data, 'expireDate.date', null)
            ? moment(data.expireDate.date).format('DD-MM-YYYY HH:mm')
            : null,
      },
      {
        key: 'executedAt',
        name: props.intl.formatMessage(messages.execution_date),
        formatter: ({ data }) => {
          const { executedAt } = data;
          const localCreatedAt = executedAt ? convertUTCToLocal(executedAt.date) : '--';
          return localCreatedAt;
        },
      },
      {
        key: 'user',
        name: props.intl.formatMessage(messages.user),
        formatter: ({ data }) =>
          `${_.get(data, 'createdBy.firstname', '')} ${_.get(
            data,
            'createdBy.lastname',
            '',
          )}`,
      },
      {
        key: 'noteType',
        name: props.intl.formatMessage(messages.note_type),
        formatter: ({ data }) => _.get(data, 'noteType.description', null),
      },
      {
        key: 'noteText',
        name: props.intl.formatMessage(messages.note),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 120 }}>
            {/* Execute note button */}
            {data.closedNote === null && data.executedAt === null && (
              <ExecuteBtn loading={isExecutingNote} action={() => handleExecute(data)} />
            )}
            {/* To be executed note button */}
            {data.closedNote === null && data.executedAt !== null && (
              <Button
                loading={isExecutingNote}
                small
                circular
                icon="redo"
                color="yellow"
                onClick={() => handleCancelExecution(data)}
              />
            )}
            {/* Edit button */}
            <EditBtn
              action={() => {
                const transformedData = transformData(data);
                setSelectedItem(transformedData);
                setModalOpened(true);
              }}
            />
            {/* Remove button */}
            <RemoveBtn
              action={() => {
                DestinationNoteService.remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.note_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <TableOverflow>
        <Table
          elements={items}
          rowClassKey="role-row-class"
          columns={columns()}
          canSort={{
            active: true,
          }}
          isLoading={loading}
          canPaginate={{
            active: paginationData.total_items > 0,
            render: (
              <Pagination
                activePage={paginationData.page}
                boundaryRange={1}
                onPageChange={(_e, { activePage }) =>
                  setPaginationData({ page: activePage }, () => this.loadData(this.state.lastFilters))
                }
                siblingRange={1}
                totalPages={paginationData.page_count}
                ellipsisItem="..."
                lastItem={null}
              />
            ),
          }}
          canAction={{
            active: !props.readOnly,
            actions: [
              <WrapperTool key="wrapper">
                <Modal
                  open={modalOpened}
                  onClose={() => {
                    closeNoteModal();
                  }}
                  title={
                    <h2>
                      <Icon name="sticky note" />{' '}
                      {selectedItem === null ? props.intl.formatMessage(messages.new_note) : props.intl.formatMessage(messages.edit_note)  }
                    </h2>
                  }
                  trigger={
                    <ModalNewBtn onClick={openNoteModal}>
                      {props.intl.formatMessage(messages.new_note)}
                    </ModalNewBtn>
                  }
                  size="mini"
                  style={{ width: 650 }}
                  scrolling
                >
                  <NoteForm
                    entityId={props.values.id}
                    entity={props.entity}
                    noteService={DestinationNoteService}
                    noteTypes={noteTypes}
                    noteReasons={noteReasons}
                    close={closeNoteModal}
                    initialValues={selectedItem}
                    update={() => loadData(1)}
                  />
                </Modal>
              </WrapperTool>,
            ],
          }}
          hiddenHeaderIfEmpty
          emptyResults={
            <div>{props.intl.formatMessage(appMessages.no_results)}</div>
          }
        />
      </TableOverflow>

      {modalExecutionNote && (
        <Modal
          open={modalExecutionNote}
          onClose={() => {
            closeExecutionNoteModal();
          }}
          title={
            <h2>
              <Icon name="sticky note" />{' '}
              {props.intl.formatMessage(messages.execution_note_text)}
            </h2>
          }
          size="mini"
          style={{ width: 650 }}
          scrolling
        >
          <NoteExecutionForm
            entityId={props.values.id}
            entity={props.entity}
            noteService={DestinationNoteService}
            initialValues={{
              id: selectedItem.id,
            }}
            close={closeExecutionNoteModal}
            update={() => loadData(1)}
          />
        </Modal>
      )}
    </div>
  );
}

NotesPart.defaultProps = {
  values: {},
  readOnly: false,
};

NotesPart.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
};

export default injectIntl(NotesPart);
