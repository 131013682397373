/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form, TextArea } from 'semantic-ui-react';
import Select from 'react-select';
import moment from 'moment';
import { PaddedColumn } from '../../Layout';
import FormikField from '../../FormikField';
import messages from '../Anagrafica/messages';
import FormikDate from '../../FormikDate';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import UserService from '../../../shared/services/users';
import StaffService from '../../../shared/services/staff';

export const BaseForm = props => {
  const { isReadOnly = false } = props;

  const [noteReasons, setNoteReasons] = useState(props.noteReasons);
  const [isClosureNote, setIsClosureNote] = useState(false);

  useEffect(() => {
    // Preselect simple note if we don't have one
    if (props.values.noteType.value === '') {
      props.setFieldValue(
        'noteType',
        props.noteTypes.find(type => type.label === 'Semplice'),
      );
    }
    // Preselect expire date if it's a new note
    if (!props.values.id) {
      props.setFieldValue('expireDate', moment());
    }
  }, []);

  /**
   * Handles the changes of note type value.
   */
  useEffect(
    () => {
      // If technical request, limits the note reasons to technical ones
      if (_.get(props, 'values.noteType.label') === 'Richiesta Intervento') {
        setNoteReasons(noteReasons.filter(item => item.technical));
        // Reset current note reason because it's not technical.
        if (!_.get(props, 'values.noteReason.technical', false)) {
          props.setFieldValue('noteReason', null);
        }
        return;
      }
      // Update note reasons
      setNoteReasons(props.noteReasons);
    },
    [_.get(props, 'values.noteType')],
  );

  /**
   * Handles the changes of closed note value.
   */
  useEffect(
    () => {
      // Set the isClosureNote state from the closedNote field
      setIsClosureNote(_.get(props, 'values.closedNote', null) !== null);
    },
    [_.get(props, 'values.closedNote')],
  );

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row>
          {!isClosureNote && (
            <PaddedColumn width={8}>
              <FormikField
                readOnly={isReadOnly}
                name="noteType"
                label={props.intl.formatMessage(messages.note_type)}
                {...props}
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={props.values.noteType}
                  name="noteType"
                  options={props.noteTypes}
                  onChange={data => props.setFieldValue('noteType', data)}
                />
              </FormikField>
            </PaddedColumn>
          )}
          <PaddedColumn width={16}>
            <FormikAsyncSelect
              {...props}
              name="assignee"
              label={props.intl.formatMessage(messages.assignee)}
              readOnly={isReadOnly}
              value={props.values.assignee}
              onChange={val => props.setFieldValue('assignee', val)}
              asyncSelectProps={{
                disabled: isReadOnly,
              }}
              loadOptions={(searchText, callback) =>
                UserService.getAsyncUserOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn width={16}>
            <FormikAsyncSelect
              {...props}
              name="users"
              asyncSelectProps={{
                isMulti: true,
                disabled: isReadOnly,
              }}
              value={props.values.recipients || []}
              readOnly={isReadOnly}
              label={props.intl.formatMessage(messages.send_mail_to)}
              onChange={val => props.setFieldValue('users', val)}
              loadOptions={(searchText, callback) =>
                UserService.getAsyncUserOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          {props.values.noteType &&
            props.values.noteType.label === 'Richiesta Intervento' &&
            !isClosureNote && (
              <>
                <PaddedColumn width={16}>
                  <FormikField
                    readOnly={isReadOnly}
                    name="noteReason"
                    label={props.intl.formatMessage(messages.note_motivation)}
                    required
                    {...props}
                  >
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={props.values.noteReason}
                      options={noteReasons}
                      name="noteReason"
                      onChange={data => props.setFieldValue('noteReason', data)}
                    />
                  </FormikField>
                </PaddedColumn>
                <PaddedColumn width={8}>
                  <FormikAsyncSelect
                    {...props}
                    name="staff"
                    label={props.intl.formatMessage(messages.talked_to)}
                    readOnly={isReadOnly}
                    onChange={val => props.setFieldValue('staff', val)}
                    loadOptions={(searchText, callback) =>
                      StaffService.getOptions(searchText, callback)
                    }
                  />
                </PaddedColumn>
              </>
            )}
          <PaddedColumn width={16}>
            <FormikField
              readOnly={isReadOnly}
              required
              name="noteText"
              label={props.intl.formatMessage(messages.note)}
              {...props}
            >
              <TextArea
                onChange={(e, data) =>
                  props.setFieldValue('noteText', data.value)
                }
                value={_.get(props, 'values.noteText', '')}
                placeholder={props.intl.formatMessage(
                  messages.note_placeholder,
                )}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikDate
              name="expireDate"
              label={props.intl.formatMessage(messages.expire_date)}
              disabled={isReadOnly}
              readOnly={isReadOnly}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isReadOnly: PropTypes.bool,
};

export default injectIntl(BaseForm);
