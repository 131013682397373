import { connect } from 'formik';
import _, { get } from 'lodash';
import moment from 'moment';
import ListManager from 'novigo-entity-grid';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import FormikDate from '../../../FormikDate';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import ProductService from '../../../../shared/services/product';

import messages from '../../../../containers/Loan/messages';
import { API } from '../../../../global-constants';
import DestinationLoanService from '../../../../shared/services/destinationloan';
import request from '../../../../shared/services/request';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { convertUTCToLocal } from '../../../../utils/functions';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';

export const TableWithoutButtonActions = styled.div`
  .ui.table thead > tr > th {
    .ten {
      .ui.button {
        background-color: transparent;
        color: black;
        pointer-events: none;
      }
    }
  }
`;

const LoanTab = props => {
  const columns = () => [
    {
      key: 'displayName',
      name: props.intl.formatMessage(messages.customer),
      useAlias: 'customer',
      searchable: false,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
      exportformatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
    },
    {
      key: 'title',
      name: props.intl.formatMessage(messages.product),
      useAlias: 'product',
      searchable: true,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.product.productCode', '')} -
        ${_.get(data, 'data._embedded.product.title', '')}`,
    },
    {
      key: 'createdAt',
      name: props.intl.formatMessage(messages.insert_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const { createdAt } = data;
        const localCreatedAt = createdAt
          ? convertUTCToLocal(createdAt.date)
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) => {
        if (_.get(data, 'createdAt', false)) {
          return moment(_.get(data, 'createdAt.date')).format('DD/MM/YYYY');
        }
        return '--';
      },
    },
    {
      key: 'numberInstalled',
      name: props.intl.formatMessage(messages.number_installed),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => _.get(data, 'numberInstalled', '--'),
      exportFormatter: ({ data }) => _.get(data, 'numberInstalled', '--'),
    },
    {
      key: 'totalGrossPriceAmount',
      name: props.intl.formatMessage(messages.total_price_amount),
      searchable: true,
      sortable: true,
      formatter: ({ data }) =>
        currencyFormatter.format(_.get(data, 'totalGrossPriceAmount', '--')),
    },
  ];

  const getTotalLoanAmount = useCallback(
    () => {
      if (props.values.customer) {
        DestinationLoanService.getTotalLoanAmount(
          props.values.customer.value,
          props.values.id,
        ).then(response => {
          setTotalLoanAmount(response.data.totalGrossPriceAmount);
        });
      }
    },
    [props.values.customer],
  );

  const [totalLoanAmount, setTotalLoanAmount] = useState(getTotalLoanAmount());

  const totalLoans = () => [
    {
      label: props.intl.formatMessage(messages.total_loan_amount, {
        value: currencyFormatter.format(totalLoanAmount || 0),
      }),
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <TableWithoutButtonActions>
          <ListManager
            entityName="destination-loan"
            basePath={API.BASE_URL}
            locale={_.get(props, 'user.defaultLanguage', 'it')}
            permissions={props.user}
            router={props}
            client={pars => request(pars).then(res => res.data)}
            aliases={[
              {
                type: 'leftjoin',
                field: 'product',
                alias: 'product',
              },
              {
                type: 'leftjoin',
                field: 'customer',
                alias: 'customer',
              },
            ]}
            columns={columns()}
            canSearchCustom={Filters()}
            canSelect={{
              active: false,
              actions: totalLoans(),
            }}
            canCreate={{
              active: false,
            }}
            canExport={{
              active: false,
            }}
            defaultActions={{
              visualize: false,
              modify: false,
              delete: false,
              grouped: false,
              moreActions: [],
            }}
            persistentFilter={[
              // {
              //   type: 'eq',
              //   field: 'customer',
              //   value: get(props, 'values.customer.value', ''),
              // },
              {
                type: 'eq',
                field: 'destination',
                value: get(props, 'values.id', ''),
              },
            ].filter(o => o)}
          />
        </TableWithoutButtonActions>
      </TableGlobalSearch>
    </ListWrapper>
  );
};

const Filters = () => connect(({
  active: true,
  title: "Filtri",
  buttonLabel: "Filtra",
  formStructure: (
    <>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="product"
          label="Prodotto"
          loadOptions={(searchText, callback) => ProductService.getOptions(searchText, callback)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="quantity"
          label="Installati"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="totalPriceAmount"
          label="Valore"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikDate
          name="date"
          label="Data inserimento"
          alias="readAt"
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    const title = _.get(values, 'product.value', null);
    const date = _.get(values, 'date', null);
    const quantity = _.get(values, 'quantity', null);
    const totalPriceAmount = _.get(values, 'totalPriceAmount', null);

    if (title) {
      filterLabels.push({
        key: 'title',
        name: "STATO",
        value: title.label,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'id',
        alias: 'product',
        value: `%${title}%`,
      });
    }

    // NOTE: This is a date picker!
    if (date) {
      filterLabels.push({
        key: 'date',
        name: "Data inserimento",
        value: moment(date).format('DD-MM-YYYY'),
        filterField: 'id',
      });

      filters.push({
        type: 'eq',
        where: 'and',
        field: 'readAt',
        value: moment(date).format('YYYY-MM-DD'),
      });
    }

    if (quantity) {
      filterLabels.push({
        key: 'quantity',
        name: "STATO",
        value: quantity,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'numberInstalled',
        value: `%${quantity}%`,
      });
    }

    if (totalPriceAmount) {
      filterLabels.push({
        key: 'totalPriceAmount',
        name: "STATO",
        value: totalPriceAmount.replace(/,/g, '.'),
        filterField: 'id',
      });

      filters.push({
        type: 'eq',
        where: 'and',
        field: 'totalPriceAmount',
        value: totalPriceAmount.replace(/,/g, '.'),
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
}));

LoanTab.defaultProps = {
  values: {},
};

LoanTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(LoanTab);
