import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { connect } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import moment from 'moment';
import { ORDER_STATE_TO_BE_ACCEPTED } from './constants';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import { PaddedColumn } from '../../components/Layout';
import DestinationService from '../../shared/services/destination';
import OrderService from '../../shared/services/order';
import messages from './messages';
import appMessages from '../App/messages';

const SearchBar = props => {
  const { intl } = props;
  const {
    filters,
    initialValues,
    formik: { setFieldValue },
    removedFilter,
    onRemoveFilter,
    preSelectState,
  } = props;

  useEffect(() => {
    if (preSelectState) {
      setFieldValue('state', {
        value: ORDER_STATE_TO_BE_ACCEPTED,
        label: ORDER_STATE_TO_BE_ACCEPTED,
      });
    }
  }, []);

  useEffect(
    () => {
      if (
        Array.isArray(filters) &&
        filters.length > 0 &&
        Boolean(initialValues)
      ) {
        Object.keys(initialValues).forEach(item => {
          setFieldValue(item, initialValues[item]);
        });
      }
    },
    [filters],
  );

  useEffect(
    () => {
      if (!initialValues && preSelectState) {
        setFieldValue('state', {
          value: ORDER_STATE_TO_BE_ACCEPTED,
          label: ORDER_STATE_TO_BE_ACCEPTED,
        });
      }
    },
    [initialValues],
  );

  useEffect(
    () => {
      if (removedFilter) {
        setFieldValue(removedFilter, '');
        if (onRemoveFilter) onRemoveFilter();
      }
    },
    [removedFilter, onRemoveFilter],
  );

  return (
    <React.Fragment>
      <Grid.Column>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.orderNumber)}
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            DestinationService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="createdAtFrom"
          label={intl.formatMessage(messages.start)}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="createdAtTo"
          label={intl.formatMessage(messages.end)}
        />
      </Grid.Column>
      <PaddedColumn>
        <FormikAsyncSelect
          name="state"
          label={intl.formatMessage(messages.state)}
          loadOptions={(searchText, callback) =>
            OrderService.getStateOptions(callback)
          }
        />
      </PaddedColumn>

      <PaddedColumn>
        <FormikAsyncSelect
          name="agent"
          label={intl.formatMessage(messages.agent)}
          entityName="agent"
        />
      </PaddedColumn>
    </React.Fragment>
  );
};

SearchBar.propTypes = {
  filters: PropTypes.array,
  initialValues: PropTypes.object,
  formik: PropTypes.object,
  removedFilter: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  preSelectState: PropTypes.bool,
  intl: IntlShape,
};

const ConnectedSearchBar = connect(SearchBar);

const ApplyFilter = props => {
  // eslint-disable-next-line no-unused-vars
  const { values, formActionsBag, applyFilterFunction, intl, onSearch } = props;

  const filters = [];
  const filterLabels = [];

  if (values.number) {
    filterLabels.push({
      key: 'number',
      name: intl.formatMessage(messages.orderNumber),
      value: values.number,
      filterField: 'number',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'number',
      value: `%${values.number}%`,
    });
  }

  if (values.destination) {
    filterLabels.push({
      key: 'destination',
      name: intl.formatMessage(messages.destination),
      value: values.destination.label,
      filterField: 'id',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      alias: 'destination',
      field: 'id',
      value: values.destination.value,
    });
  }

  if (values.createdAtFrom) {
    filterLabels.push({
      key: 'createdAtFrom',
      name: intl.formatMessage(messages.start),
      value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} `,
      filterField: 'createdAtFrom',
    });
    filters.push({
      type: 'gte',
      where: 'and',
      field: 'createdAt',
      value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} 00:00:00`,
      format: 'Y-m-d H:i:s',
    });
  }

  if (values.createdAtTo) {
    filterLabels.push({
      key: 'createdAtTo',
      name: intl.formatMessage(messages.end),
      value: `${moment(values.createdAtTo).format('YYYY-MM-DD')}`,
      filterField: 'referenceDate',
    });
    filters.push({
      type: 'lte',
      where: 'and',
      field: 'createdAt',
      value: `${moment(values.createdAtTo).format('YYYY-MM-DD')} 23:59:59`,
      format: 'Y-m-d H:i:s',
    });
  }
  if (values.state) {
    filterLabels.push({
      key: 'state',
      name: intl.formatMessage(messages.state),
      value: values.state.label,
      filterField: 'state',
    });
    if (values.state.value) {
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'state',
        value: `${values.state.value}`,
      });
    } else {
      filters.push({
        type: 'isnotnull',
        where: 'and',
        field: 'state',
      });
    }
  }
  // FIXME: CHECK ON SUBMIT IF WORKS CORRECTLY AFTER BE ADDS IT
  if (values.agent) {
    filterLabels.push({
      key: 'agent',
      name: intl.formatMessage(messages.agent),
      value: values.agent.label,
      filterField: 'agent',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'agent1',
      alias: 'destination',
      value: values.agent.value,
    });
  }

  onSearch(filters, values, filterLabels).then(() => {
    applyFilterFunction(filters, filterLabels, formActionsBag);
  });
};

const OrderSearch = ({
  intl,
  initialSearch,
  filters,
  initialValues,
  onSearch,
  onRemoveFilter,
  removedFilter,
  preSelectState,
}) => ({
  formStructure: (
    <ConnectedSearchBar
      intl={intl}
      initialSearch={initialSearch}
      filters={filters}
      initialValues={initialValues}
      onRemoveFilter={onRemoveFilter}
      removedFilter={removedFilter}
      preSelectState={preSelectState}
    />
  ),
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  onApplyFilter: (values, formActionsBag, applyFilterFunction) => {
    ApplyFilter({
      values,
      formActionsBag,
      applyFilterFunction,
      intl,
      onSearch,
    });
  },
});

OrderSearch.propTypes = {};

export default OrderSearch;
