/* eslint-disable no-unused-vars */
import React from 'react';
import _, { get } from 'lodash';
import { Grid } from 'semantic-ui-react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
// import { Icon } from 'semantic-ui-react';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import messages from '../../../../containers/Loan/messages';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { convertUTCToLocal } from '../../../../utils/functions';
import FormikDate from '../../../FormikDate';
import ProductService from '../../../../shared/services/product';

const SamplingTab = props => {
  const columns = () => [
    {
      key: 'displayName',
      name: props.intl.formatMessage(messages.customer),
      useAlias: 'customer',
      searchable: false,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
      exportformatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
    },
    {
      key: 'title',
      name: props.intl.formatMessage(messages.product),
      useAlias: 'product',
      searchable: true,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.product.productCode', '')} -
        ${_.get(data, 'data._embedded.product.title', '')}`,
    },
    {
      key: 'createdAt',
      name: props.intl.formatMessage(messages.insert_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const { createdAt } = data;
        const localCreatedAt = createdAt
          ? convertUTCToLocal(createdAt.date)
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) => {
        if (_.get(data, 'createdAt', false)) {
          return moment(_.get(data, 'createdAt.date')).format('DD/MM/YYYY');
        }
        return '--';
      },
    },
    {
      key: 'quantity',
      name: props.intl.formatMessage(messages.number_installed),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => _.get(data, 'quantity', '--'),
      exportFormatter: ({ data }) => _.get(data, 'quantity', '--'),
    },
    {
      key: 'totalPriceAmount',
      name: props.intl.formatMessage(messages.total_price_amount),
      searchable: true,
      sortable: true,
      formatter: ({ data }) =>
        currencyFormatter.format(_.get(data, 'totalPriceAmount', '--')),
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="product-sampling"
          basePath={API.BASE_URL}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          canSearchCustom={Filters()}
          aliases={[
            {
              type: 'leftjoin',
              field: 'product',
              alias: 'product',
            },
            {
              type: 'leftjoin',
              field: 'customer',
              alias: 'customer',
            },
          ]}
          columns={columns()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'customer',
              value: get(props, 'values.customer.value', ''),
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

const Filters = () => connect(({
  active: true,
  title: "Filtri",
  buttonLabel: "Filtra",
  formStructure: (
    <>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="product"
          label="Prodotto"
          loadOptions={(searchText, callback) => ProductService.getOptions(searchText, callback)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="quantity"
          label="Installati"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="totalPriceAmount"
          label="Valore"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikDate
          name="date"
          label="Data inserimento"
          alias="createdAt"
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    const title = _.get(values, 'product.value', null);
    const date = _.get(values, 'date', null);
    const quantity = _.get(values, 'quantity', null);
    const totalPriceAmount = _.get(values, 'totalPriceAmount', null);

    if (title) {
      filterLabels.push({
        key: 'title',
        name: "STATO",
        value: title.label,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'id',
        alias: 'product',
        value: `%${title}%`,
      });
    }

    // NOTE: This is a date picker!
    if (date) {
      filterLabels.push({
        key: 'date',
        name: "Data inserimento",
        value: moment(date).format('DD-MM-YYYY'), // NOTE: Correct format for date picker
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'createdAt',
        value: `%${moment(date).format('YYYY-MM-DD')}%`,
      });
    }

    if (quantity) {
      filterLabels.push({
        key: 'quantity',
        name: "STATO",
        value: quantity,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'quantity',
        value: `%${quantity}%`,
      });
    }

    if (totalPriceAmount) {
      filterLabels.push({
        key: 'totalPriceAmount',
        name: "STATO",
        value: totalPriceAmount.replace(/,/g, '.'),
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'totalPriceAmount',
        value: `%${totalPriceAmount.replace(/,/g, '.')}%`,
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
}));

SamplingTab.propTypes = {
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(SamplingTab);
