import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../Layout';
import DestinationService from '../../../shared/services/destination';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import messages from './messages';
import OfferService from '../../../shared/services/offer';
import OfferTypologyService from '../../../shared/services/offertypology';
import ProductService from '../../../shared/services/product';
import AgentService from '../../../shared/services/agent';

const OfferSearchBar = ({ intl, ...props }) => {
  const loadTypes = (searchText, callback) =>
    OfferTypologyService.getOptions(searchText, callback);

  const loadProducts = (searchText, callback) =>
    ProductService.getOptions(searchText, callback);

  return (
    <>
      <PaddedColumn>
        <SimpleFormikField
          {...props}
          name="number"
          label={intl.formatMessage(messages.offer_number)}
        />
      </PaddedColumn>
      <PaddedColumn width={4}>
        <FormikAsyncSelect
          {...props}
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            DestinationService.getOptions(searchText, callback, true)
          }
        />
      </PaddedColumn>
      <PaddedColumn width={3}>
        <FormikDate
          {...props}
          name="sentAt"
          label={intl.formatMessage(messages.insert_date)}
        />
      </PaddedColumn>
      <PaddedColumn width={3}>
        <FormikDate
          {...props}
          name="expirationDate"
          label={intl.formatMessage(messages.expirationDate)}
        />
      </PaddedColumn>
      <PaddedColumn width={4}>
        <FormikAsyncSelect
          {...props}
          name="state"
          label={intl.formatMessage(messages.status)}
          loadOptions={(searchText, callback) =>
            OfferService.getStateOptions(callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn width={4}>
        <FormikAsyncSelect
          {...props}
          name="typology"
          label={intl.formatMessage(messages.offer_type)}
          loadOptions={loadTypes}
        />
      </PaddedColumn>
      <PaddedColumn width={4}>
        <FormikAsyncSelect
          {...props}
          name="product"
          label={intl.formatMessage(messages.product)}
          loadOptions={loadProducts}
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          name="agent1"
          label={intl.formatMessage(messages.agent)}
          entityName="agent"
          loadOptions={(searchText, callback) =>
            AgentService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
    </>
  );
};

OfferSearchBar.defaultProps = {
  isDestination: false,
};

OfferSearchBar.propTypes = {
  isDestination: PropTypes.bool,
};

OfferSearchBar.contextTypes = {
  intl: PropTypes.shape(intlShape),
};

export { OfferSearchBar };
