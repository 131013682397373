/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';

import _ from 'lodash';
import CurrencyFormat from 'react-currency-format';
import messages from '../messages';
import FormikAsyncSelect from '../../../FormikAsyncSelect';

import ProductWarehouseCategoryService from '../../../../shared/services/productwarehousecategory';
import ProductTaxRateService from '../../../../shared/services/producttaxrate';
import CurrencyFormatter from '../../../CurrencyFormatter';
import PercentageFormatter from '../../../PercentageFormatter';
import ProductService from '../../../../shared/services/product';
import { addNotification } from '../../../../utils/notification';
import appMessages from '../../../../containers/App/messages';

function BillingTab(props) {
  const { isNew, values } = props;
  const [productPriceMaster, setProductPriceMaster] = useState(0);

  useEffect(
    () => {
      if (!isNew) {
        ProductService.getProductPriceMasterByIds([values.id])
          .then(res => {
            if (!res.data.prices || res.data.prices.length === 0) {
              return;
            }
            const priceArray = res.data.prices.map(el => ({
              id: el.id,
              productId: el.product,
              price: el.masterPrice || 0,
            }));

            const attData = [];
            priceArray.forEach(price => {
              const item = {
                ...price,
              };
              attData.push(item);
            });

            setProductPriceMaster(attData[0].price);
          })
          .catch(err =>
            addNotification({
              title: props.intl.formatMessage(appMessages.error),
              message: _.get(
                err,
                'detail',
                props.intl.formatMessage(appMessages.an_error_has_occurred),
              ),
              isError: true,
            }),
          );
      }
    },
    [values.id],
  );

  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              label={props.intl.formatMessage(messages.productMasterPrice)}
            >
              {() => (
                <CurrencyFormat
                  value={+productPriceMaster}
                  readOnly
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="€"
                />
              )}
            </SimpleFormikField>
          </Grid.Column>
          <Grid.Column>
            <CurrencyFormatter
              {...props}
              name="productStock.buyPrice"
              readOnly
              label={props.intl.formatMessage(messages.buyPrice)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="taxRate"
              readOnly
              label={props.intl.formatMessage(messages.taxCode)}
              loadOptions={(searchText, callback) =>
                ProductTaxRateService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <PercentageFormatter
              {...props}
              name="maxDiscount"
              label={props.intl.formatMessage(messages.maxDiscount)}
            />
          </Grid.Column>
          <Grid.Column>
            <PercentageFormatter
              {...props}
              name="maxDiscountPerQuantity"
              label={props.intl.formatMessage(messages.maxDiscountPerQuantity)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="minQuantity"
              type="number"
              label={props.intl.formatMessage(messages.minQuantity)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="warehouseCategory"
              label={props.intl.formatMessage(
                messages.productWarehouseCategory,
              )}
              loadOptions={(searchText, callback) =>
                ProductWarehouseCategoryService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

BillingTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(BillingTab);
