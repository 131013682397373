/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/**
 *
 * Staff
 *
 */
/* eslint-disable indent */

import _, { get, isEmpty, isNull } from 'lodash';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Header, Label } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import ListManager from '../../components/DeterchimicaEntityGrid/index';
import DestinationForm, {
  validation,
} from '../../components/Forms/DestinationForm';
import { ListWrapper, Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import AnagraficaSearchBar from '../../components/SearchBars/shared/Anagrafica/SearchBar';
/*
 * Specific import
 */
import { API } from '../../global-constants';
import destinationService from '../../shared/services/destination';
import MunicipalityService from '../../shared/services/municipality';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { getRibbonColor, handleCleanData, transformData } from './functions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectDestination from './selectors';
import { getEntityError } from '../../utils/getError';
import { GetCurrentRoles } from '../Can/utils';

/* eslint-disable react/prefer-stateless-function */
export class Destination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      headerTitle: '',
      aliases: [
        {
          type: 'leftjoin',
          field: 'destinationState',
          alias: 'destinationState',
        },
        {
          type: 'leftjoin',
          field: 'destinationArea',
          alias: 'area',
        },
        {
          type: 'leftjoin',
          field: 'agent1',
          alias: 'agent',
        },
        {
          type: 'groupby',
          field: 'id',
        },
      ],
      filters: [],
      filterLabels: [],
      searchFilter: [],
      values: {},
      initialValues: {},
      searchedValue: '',
      removedFilter: '',
      reloadIndex: 0,
      isUserAgent: false,
      userAgentId: '',
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'destination',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({
        showForm: false,
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
    this.handleInitialFilters();
    this.setState({
      headerTitle: this.context.intl.formatMessage(messages.title),
    });
  }

  handleInitialFilters() {
    const { /* aliases, */ filters, initialValues, filterLabels } = this.state;

    const roles = GetCurrentRoles();
    if (
      _.intersection(roles, [
        'ROLE_AGENTE',
        'ROLE_ISPETTORE',
        'ROLE_RESPONSABILE_AGENTI',
        'ROLE_RESPONSABILE_AREA_COMMERCIALE',
      ]).length > 0
    ) {
      filterLabels.push({
        key: 'isOnlyMine',
        name: this.context.intl.formatMessage(messages.onlyMine),
        value: this.context.intl.formatMessage(messages.yes),
        filterField: 'isOnlyMine',
      });

      filters.push({
        where: 'and',
        type: 'search_agent_destination',
      });
      initialValues.isOnlyMine = true;

      this.setState({
        initialValues,
        filterLabels,
        isUserAgent: true,
      });
    }
  }

  reloadData = () =>
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));

  handleSearch = (filters, values) =>
    new Promise(resolve => {
      if (_.isEmpty(values)) {
        this.setState(
          {
            filters,
            aliases: [
              {
                type: 'leftjoin',
                field: 'destinationState',
                alias: 'destinationState',
              },
              {
                type: 'leftjoin',
                field: 'destinationArea',
                alias: 'area',
              },
            ],
          },
          () => {
            resolve(filters);
          },
        );
      } else {
        this.setState(
          () => ({
            filters,
          }),
          () => {
            resolve(filters);
          },
        );
      }
    });

  handleRemovedFilter = name => {
    const { values } = this.state;
    values[name] = null;
    this.setState({
      values,
      removedFilter: '',
    });
  };

  exportPDF = () => {
    destinationService
      .exportPDF()
      .then(response => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/"/g, '')
          .trim();
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  onRemoveFilter = filterName => {
    this.setState(prev => ({
      filters:
        prev.filters.filter(el => el.field !== filterName) || prev.filters,
      removedFilter: filterName,
    }));
  };

  handleTitle = title => {
    if (!title) {
      this.setState({
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    } else {
      this.setState({ headerTitle: title });
    }
  };

  /**
   * Return the function for manage the router
   * @param {} action
   */
  manageAction = (data, action) => {
    const routerManager = get(this.props, 'history');
    const routerPath = isEmpty(data.customer) ? 'prospect' : 'destination';
    if (routerManager) {
      routerManager.push(`/${routerPath}/${data.id}/${action}`);
    } else {
      addNotification({
        title: this.context.intl.formatMessage(appMessages.error),
        message: this.context.intl.formatMessage(
          appMessages.an_error_has_occurred,
        ),
        isError: true,
      });
    }
  };

  actions = [
    PermissionManager.CanI('read', 'destination') && {
      icon: 'eye',
      label: 'visualize',
      key: 'visualize',
      action: data => this.manageAction(data, 'view'),
    },
    PermissionManager.CanI('edit', 'destination') && {
      icon: 'edit',
      label: 'modify',
      key: 'modify',
      action: data => this.manageAction(data, 'modify'),
    },
    PermissionManager.CanI('remove', 'destination') && {
      icon: 'trash',
      label: 'delete',
      key: 'delete',
      color: 'red',
      action: data => this.manageAction(data, 'delete'),
    },
  ].filter(Boolean)

  render() {
    return (
      <>
        <Helmet>
          <title>Departments</title>
          <meta name="description" content="Destination" />
        </Helmet>
        <BreadcrumbsItem to="/destination">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            {this.state.headerTitle}
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/destination/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={`${this.props.match.params.action}-${this.props.match.params.id
                  }`} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars)
                    .then(res => transformData(res.data))
                    .catch(err => {
                      // If 404, navigate back to the previous page
                      if (err.status === 404) {
                        addNotification({
                          title: this.context.intl.formatMessage(
                            appMessages.error,
                          ),
                          message: getEntityError(err, this.context.intl),
                          isError: true,
                        });
                        window.history.back();
                      } else {
                        throw err;
                      }
                    })
                }
                mask={props => (
                  <DestinationForm
                    {...props}
                    id={this.props.match.params.id}
                    municipalityService={MunicipalityService}
                    editRoute={`${API.DESTINATION}/${this.props.match.params.id
                      }/modify`}
                    history={this.props.history}
                    mode="destination"
                    handleTitle={this.handleTitle}
                    user={this.props.user}
                    initialActiveTab={
                      this.props.history.location.state
                        ? this.props.history.location.state.activeIndex
                        : null
                    }
                  />
                )}
                data={
                  this.props.match.params.id === 'new' && {
                    registryType: 'L',
                    gender: 'M',
                  }
                }
                afterCreate={res =>
                  this.props.history.push(`${API.DESTINATION}/${res.id}/modify`)
                }
                afterUpdate={() => { }}
                afterDelete={() =>
                  this.props.history.push(`${API.DESTINATION}`)
                }
                validation={values =>
                  validation(
                    values,
                    this.context.intl,
                    values.customer === null,
                  )
                }
                onCleanData={handleCleanData}
                entityName="destination"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                ref={list => {
                  this.list = list;
                }}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(
                      messages.new_destination,
                    ),
                  },
                }}
                sessionKey="destination"
                reloadIndex={this.state.reloadIndex}
                entityName="destination"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                customSearchFilters={this.state.filters}
                searchedValue={this.state.searchedValue}
                searchFiler={this.state.searchFilter}
                onRemoveFilter={this.onRemoveFilter}
                onDefaultSearch={(val, filters) => {
                  this.setState({
                    searchFilter: filters,
                    searchedValue: val,
                  });
                }}
                client={pars => request(pars).then(res => res.data)}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                  pdf: false,
                }}
                canCreate={{
                  active: false,
                  label: <FormattedMessage {...messages.new_destination} />,
                }}
                canSelect={{
                  active: false,
                  selectAll: false,
                  actions: [],
                }}
                aliases={this.state.aliases}
                canSearchCustom={AnagraficaSearchBar({
                  intl: this.context.intl,
                  filters: this.state.filters,
                  aliases: this.state.alias,
                  initialSearch: {},
                  isDestination: true,
                  isUserAgent: this.state.isUserAgent,
                  userAgentId: this.state.userAgentId,
                  isHeadQuarter: false,
                  preSelectOnlyMine: this.state.isUserAgent,
                  removedFilter: this.state.removedFilter,
                  handleRemovedFilter: this.handleRemovedFilter,
                  initialValues: this.state.initialValues,
                  onSearch: (filters, values, labels, customAliases) =>
                    this.handleSearch(filters, values, customAliases),
                })}
                columns={[
                  {
                    key: 'code',
                    name: this.context.intl.formatMessage(
                      messages.destinationCode,
                    ),
                    searchable: true,
                    sortable: true,
                    formatter: ({ data }) => {
                      const { code } = data;
                      const isProspect = isNull(get(data, 'customer', null));
                      const state = _.get(
                        data,
                        'destinationState.description',
                        null,
                      );

                      return (
                        <>
                          {isProspect && (
                            <Label ribbon color="blue">
                              {this.context.intl.formatMessage(
                                messages.prospect,
                              )}
                              <br />
                            </Label>
                          )}
                          {!isProspect &&
                            state && (
                              <Label ribbon color={getRibbonColor(state)}>
                                {this.context.intl.formatMessage(
                                  messages[state],
                                )}
                                <br />
                              </Label>
                            )}
                          <div style={{ marginTop: state ? '5px' : '0' }}>
                            {code}
                          </div>
                        </>
                      );
                    },
                    width: '10%',
                  },
                  {
                    key: 'businessName',
                    name: (
                      <span>
                        {this.context.intl.formatMessage(messages.businessName)}
                        <br />
                        {this.context.intl.formatMessage(messages.shopSign)}
                      </span>
                    ),
                    searchable: true,
                    sortable: true,
                    width: 200,
                    formatter: ({ data }) => {
                      const businessName =
                        (data.customer
                          ? _.get(data, 'customer.businessName', '--')
                          : _.get(data, 'companyName', '--')) || '--';
                      const shopSign = _.get(data, 'businessName', '--');

                      return (
                        <span>
                          {businessName}
                          <br />
                          {shopSign}
                        </span>
                      );
                    },
                    exportFormatter: ({ data }) =>
                      _.get(data, 'businessName', '--'),
                  },
                  {
                    key: 'agent',
                    useAlias: 'agent',
                    filterName: 'displayName',
                    name: this.context.intl.formatMessage(messages.agent),
                    searchable: false,
                    sortable: true,
                    formatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                  },
                  {
                    key: 'businessAddressTitle',
                    name: this.context.intl.formatMessage(messages.address),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'phoneNumber',
                    name: this.context.intl.formatMessage(messages.phone),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'lastAction',
                    name: this.context.intl.formatMessage(messages.lastAction),
                    searchable: true,
                    sortable: true,
                  },
                ]}
                defaultActions={{
                  visualize: false,
                  modify: false,
                  delete: false,
                  grouped: false,
                  moreActions: this.actions,
                }}
                disableInitialLoad={this.state.isUserAgent}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Destination.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  destination: makeSelectDestination(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'destination', reducer });
const withSaga = injectSaga({ key: 'destination', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Destination);

Destination.contextTypes = {
  intl: PropTypes.object.isRequired,
};
