/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable indent */

import React from 'react';
import PropTypes from 'prop-types';
import _, { get, isNull } from 'lodash';
import moment from 'moment';
import { Form, Header, Button, Icon, Popup, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import validation from './validation';
import { FormTab } from '../../Layout';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import CustomerRegistryTab from './tabs/customerRegistryTab';
import GeneralTab from './tabs/generalTab';
import CommercialTab from './tabs/commercialTab';
import ContactsTab from './tabs/contactsTab';
import MarketingTab from './tabs/marketingTab';
import NotesTab from './tabs/notesTab';
import CommissionsTab from './tabs/commissionsTab';
import TechnicalInterventions from './tabs/technicalInterventionsTab';
import Orders from './tabs/orderTab';
import Invoices from './tabs/invoiceTab';
import Offers from './tabs/offerTab';
import Attachments from './tabs/attachmentTab';
import HistoricalTab from './tabs/historicalTab';
import LoanTab from './tabs/loanTab';
import SamplingTab from './tabs/samplingTab';
import Overdue from './tabs/overdueTab';
import LinkedDestinations from './tabs/linkedDestinationsTab';
import { addNotification } from '../../../utils/notification';
import ProspectToCustomerService from '../../../shared/services/prospecttocustomer';
import DestinationPaymentDeadlines from '../../../shared/services/destinationpaymentdeadlines';
import { handleCleanData } from '../../../containers/Prospect/functions';
import PermissionManager from '../../Permission';
import { handleHeaderLink } from './functions';
// import { TransformProspect } from './formParts/TransformProspect';
import DestinationService from '../../../shared/services/destination';
import ProfileService from '../../../shared/services/profile';
import './styles.css';
import ProductPurchaseTab from './tabs/ProductPurchaseTab';

const DestinationSubHeader = styled.div`
  width: fit-content;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding-bottom: 5px;
  & > div {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    & > .date-label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }
    & > .date-content {
      font-weight: bold;
      font-size: 13px;
    }
  }
`;

export class DestinationMask extends React.Component {
  state = {
    activeIndex: 0,
    allowedStates: [],
    allowedStatesLoading: true,
    hasOverdueRow: false,
    assigning: false,
    hasLinkedDestination: false,
    isLegalHeadquarter: false,
    isUserAgent: false,
    isDestinationAgent: false,
    agentLogged: null,
    showPrivateTabs: false,
    showHistoryTab: false,
    showCommissionTab: false,
    showOverdueTab: false,
    showNotProspectTabs: false,
    tabVisibility: [],
    isProspect: false,
  };

  /**
   * Called immediately after an update occurs.
   */
  componentDidUpdate(prevProps) {
    const isProspect =
      this.props.mode === 'prospect' || isNull(this.props.values.customer);

    /** If values changes */
    if (prevProps.values !== this.props.values) {
      // Update title
      const title = this.context.intl.formatMessage(
        messages[isProspect ? 'prospect' : 'customer'],
      );
      this.props.handleTitle(title);
    }

    /** If destination id changes */
    if (prevProps.values.id !== this.props.values.id) {
      // Read overdues
      if (this.props.values.customer && this.props.values.customer.value) {
        this.getOverdues(this.props.values.customer.value);
      }
      // Check if it's the legal headquarter
      this.setState({
        isLegalHeadquarter:
          !isProspect &&
          this.props.values.vatNumber === this.props.values.customer.vatNumber,
      });
      // Check if it has linked destinations
      if (!isProspect) {
        this.getLinkedDestinations(
          this.props.values.customer.value,
          this.props.values.id,
        );
      }

      if (window.history.state && window.history.state.activeIndex) {
        this.setActiveTab(window.history.state.activeIndex);
      }
    }

    const { isNew, user } = this.props;
    /** Preselect agent if new */
    if (
      user &&
      isNew &&
      isProspect &&
      this.state.agentLogged &&
      this.props.values.agent1.value !== this.state.agentLogged.value
    ) {
      this.props.setFieldValue('agent1', this.state.agentLogged);
    }
    /**
     * If the agent changed and the logged user is an agent
     * we check if the agent is associated to the current destination
     */

    if (
      get(this.props, 'values.agent1.value', null) !==
        get(prevProps, 'values.agent1.value', null) ||
        get(this.props, 'values.agent1.supervisor', null) !==
        get(prevProps, 'values.agent1.supervisor', null) ||
      get(this.props, 'values.agent2.value', null) !==
        get(prevProps, 'values.agent2.value', null) ||
        get(this.props, 'values.agent2.supervisor', null) !==
        get(prevProps, 'values.agent2.supervisor', null) ||
      get(this.props, 'values.subagent.value', null) !==
        get(prevProps, 'values.subagent.value', null) ||
      get(this.props, 'values.signaller.value', null) !==
        get(prevProps, 'values.signaller.value', null) ||
      get(this.props, 'values.signaller.supervisor', null) !==
        get(prevProps, 'values.signaller.supervisor', null) ||
      get(this.props, 'values.free', false) !==
        get(prevProps, 'values.free', false)
    ) {
      this.updateVisibleTabs();
    }
  }

  /**
   * Called immediately after the component is mounted.
   */
  componentDidMount() {
    /** Update the title */
    const isProspect =
      this.props.mode === 'prospect' || isNull(this.props.values.customer);
    const title = this.context.intl.formatMessage(
      messages[isProspect ? 'prospect' : 'customer'],
    );
    this.props.handleTitle(title);

    /**
     * Store the agent related to the current user, if any
     */
    const { user } = this.props;
    if (user) {
      ProfileService.getAgentOptionsByUserId(this.props.user.id, res => {
        if (res && res.length > 0) {
          this.setState(
            { isUserAgent: true, agentLogged: res[0] },
            this.updateVisibleTabs,
          );
        } else {
          this.updateVisibleTabs();
        }
      });
    }
  }

  /**
   * Handle the changes of the selected tab.
   * @param {*} e
   * @param {*} param1
   */
  handleTabChange = (e, { activeIndex }) => {
    this.setActiveTab(activeIndex);
    window.history.pushState({ activeIndex }, null);
  };

  /**
   * Updates the visible tabs basing on permissions.
   */
  updateVisibleTabs = () => {
    const { values, isNew } = this.props;
    const { agentLogged } = this.state;

    const agentLists = [
      get(this.props, 'values.agent1.value', null),
      get(this.props, 'values.agent1.supervisor', null),
      get(this.props, 'values.agent2.value', null),
      get(this.props, 'values.agent2.supervisor', null),
      get(this.props, 'values.subagent.value', null),
      get(this.props, 'values.signaller.supervisor', null),
    ].filter(a => a);

    /** If isDestinationAgent flag changed, we need to update all the visible tab basing on it */
    const isDestinationAgent =
      (agentLogged && agentLists.includes(agentLogged.value)) || values.free;

    const isCustomer = !!values.customer;

    /**
     * These are the rules to access all destination tabs (except CustomerRegistry and General which are public):
     * 1. accessCustomerTabs custom permissions
     * OR
     * 2. being the agent assigned to this destination
     */
    const showPrivateTabs =
      !isNew &&
      (isDestinationAgent ||
        PermissionManager.CanI('accessPrivateCustomerTabs', 'custom'));

    // Manage tabs custom permissions
    const showHistoryTab =
      showPrivateTabs &&
      PermissionManager.CanI('accessDestinationHistoric', 'custom');
    const showCommissionTab =
      showPrivateTabs &&
      isCustomer &&
      PermissionManager.CanI('accessCommission', 'custom');
    const showOverdueTab = !isNew && isCustomer;
    const showNotProspectTabs = showPrivateTabs && isCustomer;

    this.setState(
      {
        isDestinationAgent,
        tabVisibility: [
          true,
          true,
          showHistoryTab, // History
          showPrivateTabs, // Commercial data
          showPrivateTabs, // Closure
          showPrivateTabs, // Contacts
          showPrivateTabs, // Notes
          showCommissionTab, // Commissions
          showNotProspectTabs, // Technical assistance
          showNotProspectTabs, // Loan
          showOverdueTab, // Overdue
          showNotProspectTabs, // Sample
          showPrivateTabs, // Offers
          showNotProspectTabs, // Orders
          showNotProspectTabs, // Invoices
          showPrivateTabs, // Attachments
          showNotProspectTabs, // Linked destinations
          showNotProspectTabs, // Products acquired
        ],
        showPrivateTabs,
        showHistoryTab,
        showCommissionTab,
        showOverdueTab,
        showNotProspectTabs,
      },
      () => {
        /** Set active tab if specified */
        if (window.history.state && window.history.state.activeIndex) {
          this.setActiveTab(window.history.state.activeIndex);
        } else if (this.props.initialActiveTab) {
          this.setActiveTab(this.props.initialActiveTab);
        }
      },
    );
  };

  /**
   * Selects the specified tab as the active one.
   * @param {number} activeTab Active tab.
   */
  setActiveTab = activeTab => {
    if (this.state.tabVisibility[activeTab]) {
      this.setState({ activeIndex: activeTab });
    } else {
      this.setState({ activeIndex: 0 });
    }
  };

  /**
   * Gets the list of due payments for the specified customer.
   * @param {string} id Id of the customer.
   */
  getOverdues = id => {
    DestinationPaymentDeadlines.getByCustomer(id, true)
      .then(({ data }) => {
        const deadlines = _.get(
          data,
          '_embedded.destination-payment-deadlines',
          [],
        );
        if (Array.isArray(deadlines) && deadlines.length > 0) {
          this.setState({ hasOverdueRow: true });
        }
      })
      .catch(() => false);
  };

  /**
   * Gets the list of the destinations linked to the given customer.
   * @param {string} idCustomer Id of the customer.
   * @param {string} idDestination Id of the destination.
   */
  getLinkedDestinations = (idCustomer, idDestination) => {
    DestinationService.getLinkedDestinations(idCustomer, idDestination)
      .then(({ data }) => {
        const deadlines = _.get(data, '_embedded.destination', []);
        if (Array.isArray(deadlines) && deadlines.length > 0) {
          this.setState({ hasLinkedDestination: true });
        }
      })
      .catch(() => false);
  };

  /**
   * Assign a prospect to a selected customer, generating only the new destination entity.
   * @returns
   */
  assignToCustomer = () => {
    /** Perform validation */
    if (!this.validationAssignProspect(this.props.values)) {
      return;
    }

    const { id } = this.props.values;
    const data = handleCleanData(this.props.values);

    this.setState({ assigning: true });
    /** Save the current prospect with a customer assigned. This is the transformation prospect => destination */
    ProspectToCustomerService.saveProspect(id, data)
      .then(() => {
        addNotification({
          title: null,
          message: this.context.intl.formatMessage(
            messages.assign_to_customer_success,
          ),
          isError: false,
        });
        this.props.history.push(`/customer`);
      })
      .catch(error => {
        const validationMessages = _.get(
          error,
          'data.validation_messages',
          false,
        );
        if (validationMessages) {
          let message = '';
          Object.keys(validationMessages).forEach(key => {
            Object.keys(validationMessages[key]).forEach(msg => {
              const translatedKey = Object.keys(messages).includes(key)
                ? this.context.intl.formatMessage(messages[key])
                : key;
              message += `${translatedKey}: ${validationMessages[key][msg]}`;
              message += '\n\r';
            });
          });

          addNotification({
            title: null,
            message,
            isError: true,
          });
          // setErrors(validationMessages); TODO
        } else {
          addNotification({
            title: null,
            message: error.data.detail,
            isError: true,
          });
        }
      })
      .finally(() => this.setState({ assigning: false }));
  };

  /**
   * Validate the fields for prospect assignment to customer.
   * @param {*} values
   * @returns
   */
  validationAssignProspect = values => {
    if (!values.customer) {
      addNotification({
        title: null,
        message: this.context.intl.formatMessage(messages.customer_required),
        isError: true,
      });
      return false;
    }

    return true;
  };

  render() {
    const {
      handleSubmit,
      readOnly = false,
      isSubmitting = false,
      onDelete = false,
      isDeleting = false,
      isNew,
      showDeleteButton = false,
    } = this.props;

    // Force readOnly
    let forceReadOnly = false;
    if (
      !isNew &&
      !PermissionManager.CanI('accessPrivateCustomerTabs', 'custom') &&
      this.props.mode !== 'prospect'
    ) {
      forceReadOnly = true;
    }

    const { hasOverdueRow } = this.state;
    const panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.customer_registry),
        render: () => (
          <FormTab.Pane className="small-tab">
            <CustomerRegistryTab
              {...this.props}
              readOnly={this.props.readOnly || forceReadOnly}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.context.intl.formatMessage(messages.tab_general_data),
        render: () => (
          <FormTab.Pane>
            <GeneralTab
              {...this.props}
              readOnly={this.props.readOnly || forceReadOnly}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showHistoryTab
          ? this.context.intl.formatMessage(messages.tab_historical_data)
          : null,
        render: () => (
          <FormTab.Pane>
            <HistoricalTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_commercial_data)
          : null,
        render: () => (
          <FormTab.Pane>
            <CommercialTab
              {...this.props}
              readOnly={this.props.readOnly || forceReadOnly}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_marketing)
          : null,
        render: () => (
          <FormTab.Pane>
            <MarketingTab
              {...this.props}
              readOnly={this.props.readOnly || forceReadOnly}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_contacts)
          : null,
        render: () => (
          <FormTab.Pane>
            <ContactsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_general_notes)
          : null,
        render: () => (
          <FormTab.Pane>
            <NotesTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showCommissionTab
          ? this.context.intl.formatMessage(messages.tab_commisions)
          : null,
        render: () => (
          <FormTab.Pane>
            <CommissionsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(
              messages.tab_technical_interventions,
            )
          : null,
        render: () => (
          <FormTab.Pane>
            <TechnicalInterventions entity="staff-workorder" {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_loan)
          : null,
        render: () => (
          <FormTab.Pane>
            <LoanTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showOverdueTab ? (
          <Menu.Item className={`${hasOverdueRow ? 'hasOverdueRow' : ''}`}>
            {this.context.intl.formatMessage(messages.tab_overdue)}
          </Menu.Item>
        ) : null,
        render: () => (
          <FormTab.Pane>
            <Overdue {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_sampling)
          : null,
        render: () => (
          <FormTab.Pane>
            <SamplingTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_offers)
          : null,
        render: () => (
          <FormTab.Pane>
            <Offers entity="offer" {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_orders)
          : null,
        render: () => (
          <FormTab.Pane>
            <Orders entity="order" {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_invoices)
          : null,
        render: () => (
          <FormTab.Pane>
            <Invoices entity="invoice" {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showPrivateTabs
          ? this.context.intl.formatMessage(messages.tab_attachments)
          : null,
        render: () => (
          <FormTab.Pane>
            <Attachments {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_linked_destinations)
          : null,
        render: () => (
          <FormTab.Pane>
            <LinkedDestinations {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.state.showNotProspectTabs
          ? this.context.intl.formatMessage(messages.tab_product_purchase)
          : null,
        render: () => (
          <FormTab.Pane>
            <ProductPurchaseTab {...this.props} />
          </FormTab.Pane>
        ),
      },
    ].filter(o => o);

    // const showTransformButton =
    //   !this.props.isNew &&
    //   this.props.mode === 'prospect' &&
    //   PermissionManager.CanI('transformProspectToCustomer', 'custom');

    const showAssignButton =
      !this.props.isNew &&
      this.props.mode === 'prospect' &&
      PermissionManager.CanI('assignProspectToCustomer', 'custom');

    return (
      <>
        {!this.props.isNew && (
          <div>
            <Header />
            <DestinationSubHeader>
              {this.state.isLegalHeadquarter && (
                <Popup
                  trigger={
                    <div>
                      <span>
                        <Icon name="trophy" />
                      </span>
                    </div>
                  }
                >
                  <Popup.Header>
                    {this.context.intl.formatMessage(messages.headquarter)}
                  </Popup.Header>
                  <Popup.Content>
                    {this.context.intl.formatMessage(
                      messages.customer_is_legal_headquarter,
                    )}
                  </Popup.Content>
                </Popup>
              )}
              <div>
                <span className="date-content">
                  {handleHeaderLink(this.props.values)}
                </span>
                <span className="date-label" />
              </div>
              <div>
                <span className="date-content">
                  {_.get(this.props.values, 'lastInvoiceDate', null)
                    ? moment(
                        _.get(this.props.values, 'lastInvoiceDate', null),
                      ).format('DD/MM/YYYY')
                    : '--'}
                </span>
                <span className="date-label">
                  {this.context.intl.formatMessage(messages.ultima_fattura)}
                </span>
              </div>
              <div>
                <span className="date-content">
                  {_.get(this.props.values, 'lastInterventionDate', '')
                    ? moment(
                        _.get(this.props.values, 'lastInterventionDate', ''),
                      ).format('DD/MM/YYYY')
                    : '--'}
                </span>
                <span className="date-label">
                  {this.context.intl.formatMessage(messages.ultimo_intervento)}
                </span>
              </div>
              <div>
                <span className="date-content">
                  {_.get(this.props.values, 'lastOfferDate', null)
                    ? moment(
                        _.get(this.props.values, 'lastOfferDate', null),
                      ).format('DD/MM/YYYY')
                    : '--'}
                </span>
                <span className="date-label">
                  {this.context.intl.formatMessage(messages.ultima_offerta)}
                </span>
              </div>
              <div>
                <span className="date-content">
                  {_.get(this.props.values, 'lastNoteDate', '')
                    ? moment(
                        _.get(this.props.values, 'lastNoteDate', ''),
                      ).format('DD/MM/YYYY')
                    : '--'}
                </span>
                <span className="date-label">
                  {this.context.intl.formatMessage(messages.ultima_nota)}
                </span>
              </div>
              <div>
                <span className="date-content">
                  {_.has(this.props, 'values.agent1.value')
                    ? `${_.get(this.props, 'values.agent1.label', '')}`
                    : '--'}
                </span>
                <span className="date-label">
                  {this.context.intl.formatMessage(messages.agent)}
                </span>
              </div>
              {this.state.hasLinkedDestination && (
                <Popup
                  trigger={
                    <div>
                      <span>
                        <Icon name="code branch" />
                      </span>
                    </div>
                  }
                >
                  <Popup.Header>
                    {this.context.intl.formatMessage(
                      messages.linked_destinations,
                    )}
                  </Popup.Header>
                  <Popup.Content>
                    {this.context.intl.formatMessage(
                      messages.customer_has_linked_destinations,
                    )}
                  </Popup.Content>
                </Popup>
              )}
            </DestinationSubHeader>
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <FormTab
            panes={panes}
            activeIndex={this.state.activeIndex}
            onTabChange={this.handleTabChange}
            className="formTabs"
          />
          <FormActionsBar
            showDeleteButton={showDeleteButton}
            readOnly={readOnly}
            isValid
            isDeleting={isDeleting}
            isSubmitting={isSubmitting}
            editRoute={this.props.editRoute}
            onDelete={onDelete}
            history={this.props.history}
            moreButtons={[
              // showTransformButton && (
              //   <TransformProspect
              //     values={this.props.values}
              //     handleCleanData={handleCleanData}
              //     history={this.props.history}
              //   />
              // ),
              showAssignButton && (
                <Button
                  loading={this.state.assigning}
                  style={{ marginRight: '15px' }}
                  type="button"
                  icon
                  onClick={() => this.assignToCustomer()}
                  labelPosition="left"
                  color="blue"
                >
                  <Icon name="upload" />
                  {this.context.intl.formatMessage(messages.assign_to_customer)}
                </Button>
              ),
            ]}
          />
        </Form>
      </>
    );
  }
}

DestinationMask.defaultProps = {
  errors: [],
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

DestinationMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  handleTitle: PropTypes.func,

  /**
   * The form can be open in 3 different modes.
   */
  mode: PropTypes.oneOf(['prospect', 'customer', 'destination']).isRequired,
};

DestinationMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default DestinationMask;

export { validation };
