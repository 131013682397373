import moment from 'moment';
import messages from './messages';

export const getFilterData = ({ values, intl }) => {
  const filters = [];
  const filterLabels = [];

  // Number
  if (values.number) {
    filters.push({
      conditions: [
        {
          type: 'like',
          where: 'or',
          field: 'number',
          value: `%${values.number}%`,
        },
        {
          type: 'like',
          where: 'or',
          field: 'documentNumber',
          value: `%${values.number}%`,
        },
      ],
      filterField: 'number',
      type: 'orx',
      where: 'and',
    });
    filterLabels.push({
      filterField: 'number',
      key: 'number',
      name: intl.formatMessage(messages.orderNumber),
      value: values.number,
    });
  }
  // Destination
  if (values.destination) {
    filters.push({
      alias: 'destination',
      field: 'id',
      filterField: 'destination',
      type: 'like',
      value: `%${values.destination.value}%`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'destination',
      key: 'destination',
      name: intl.formatMessage(messages.destination),
      value: values.destination.label,
    });
  }
  // Creation date from
  if (values.createdAtFrom) {
    filters.push({
      field: 'referenceDate',
      filterField: 'referenceDate',
      format: 'Y-m-d H:i:s',
      type: 'gte',
      value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} 00:00:00`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'referenceDate',
      key: 'createdAtFrom',
      name: intl.formatMessage(messages.start),
      value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} `,
    });
  }
  // Creation date to
  if (values.createdAtTo) {
    filters.push({
      field: 'referenceDate',
      filterField: 'referenceDate',
      format: 'Y-m-d H:i:s',
      type: 'lte',
      value: `${moment(values.createdAtTo).format('YYYY-MM-DD')} 23:59:59`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'referenceDate',
      key: 'referenceDateTo',
      name: intl.formatMessage(messages.end),
      value: `${moment(values.createdAtTo).format('YYYY-MM-DD')}`,
    });
  }
  // State
  if (values.state) {
    if (values.state.value) {
      filters.push({
        field: 'state',
        filterField: 'state',
        type: 'eq',
        value: `${values.state.value}`,
        where: 'and',
      });
    } else {
      filters.push({
        field: 'state',
        filterField: 'state',
        type: 'isnotnull',
        where: 'and',
      });
    }
    filterLabels.push({
      filterField: 'state',
      key: 'state',
      name: intl.formatMessage(messages.state),
      value: values.state.label,
    });
  }
  // Agent
  if (values.agent1) {
    filters.push({
      alias: 'destination',
      field: 'agent1',
      filterField: 'agent1',
      type: 'eq',
      value: `${values.agent1.value}`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'agent1',
      key: 'agent1',
      name: intl.formatMessage(messages.agent),
      value: values.agent1.label,
    });
  }

  // push the executed flag

  return {
    filters,
    filterLabels,
  };
};
