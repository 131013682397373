/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import DestinationStateService from '../../../../shared/services/destinationstate';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikDate from '../../../FormikDate';

import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';

import messages from '../../Anagrafica/messages';
import appMessages from '../../../../containers/App/messages';

const LinkedDestinationTab = props => {
  const columns = () =>
    [
      {
        key: 'customerCode',
        name: props.intl.formatMessage(messages.customerCode),
        formatter: ({ data }) => _.get(data, 'customer.customerCode'),
        searchable: false,
        useAlias: 'customer',
      },
      {
        key: 'description',
        name: props.intl.formatMessage(messages.destinationState),
        formatter: ({ data }) => _.get(data, 'destinationState.description'),
        useAlias: 'destinationState',
      },
      {
        key: 'destinationCode',
        name: props.intl.formatMessage(messages.destinationCode),
        sortable: true,
      },
      {
        key: 'businessName',
        name: props.intl.formatMessage(messages.destinationArea),
        sortable: true,
      },
      {
        key: 'businessCity',
        name: props.intl.formatMessage(messages.birthCity),
        sortable: true,
        formatter: ({ data }) =>
          _.get(data, 'businessCity', _.get(data, 'residenceCity', '')),
      },
      {
        key: 'businessAddressTitle',
        name: props.intl.formatMessage(messages.address),
        sortable: true,
        formatter: ({ data }) =>
          _.get(
            data,
            'businessAddressTitle',
            _.get(data, 'residenceAddressTitle', ''),
          ),
      },
      {
        key: 'lastOrderDate',
        name: props.intl.formatMessage(messages.lastOrderDate),
        sortable: true,
        formatter: ({ data }) =>
          data.lastOrderDate
            ? moment(_.get(data, 'lastOrderDate')).format('DD-MM-YYYY')
            : '--',
      },
    ].filter(c => c);

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="destination"
          basePath={API.BASE_URL}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          aliases={[
            {
              type: 'innerjoin',
              field: 'customer',
              alias: 'customer',
            },
            // {
            //   type: 'leftjoin',
            //   field: 'destinationState',
            //   alias: 'destinationState',
            // },
          ]}
          columns={columns()}
          canSearchCustom={Filters()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              !props.readOnly && {
                icon: 'edit',
                label: props.intl.formatMessage(appMessages.edit),
                key: 'edit',
                action: data => {
                  props.history.push(`/destination/${data.id}/modify`);
                },
              },
              {
                icon: 'eye',
                label: props.intl.formatMessage(appMessages.action_view),
                key: 'visualize',
                action: data => {
                  props.history.push(`/destination/${data.id}/view`);
                },
              },
            ].filter(x => x),
          }}
          persistentFilter={[
            _.has(props, 'values.customer.value') && {
              type: 'eq',
              field: 'customer',
              value: props.values.customer.value,
            },
            {
              type: 'neq',
              field: 'id',
              value: props.values.id,
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

const Filters = () => connect(({
  active: true,
  title: "Filtri",
  buttonLabel: "Filtra",
  formStructure: (
    <>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="destinationState"
          label="Stato"
          loadOptions={DestinationStateService.getOptions}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="destinationCode"
          label="Codice destinazione"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="businessName"
          label="Ragione sociale"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="businessCity"
          label="Comune"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="businessAddressTitle"
          label="Indirizzo"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikDate
          name="lastOrderDate"
          label="Data ultimo ordine"
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    const destinationState = _.get(values, 'destinationState', null);
    const destinationCode = _.get(values, 'destinationCode', null);
    const businessName = _.get(values, 'businessName', null);
    const businessCity = _.get(values, 'businessCity', null);
    const businessAddressTitle = _.get(values, 'businessAddressTitle', null);
    const lastOrderDate = _.get(values, 'lastOrderDate', null);

    if (destinationState) {
      filterLabels.push({
        key: 'destinationState',
        name: "STATO",
        value: destinationState.label,
        filterField: 'id',
      });

      filters.push({
        type: 'eq',
        where: 'and',
        field: 'destinationState',
        // alias: 'destinationState',
        value: destinationState.value,
      });
    }
    if (destinationCode) {
      filterLabels.push({
        key: 'destinationCode',
        name: "Codice Destinazione",
        value: destinationCode,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'destinationCode',
        value: destinationCode,
      });
    }

    if (businessName) {
      filterLabels.push({
        key: 'businessName',
        name: "Codice Destinazione",
        value: businessName,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'businessName',
        value: `%${businessName}%`,
      });
    }

    if (businessCity) {
      filterLabels.push({
        key: 'businessCity',
        name: "Codice Destinazione",
        value: businessCity,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'businessCity',
        value: `%${businessCity}%`,
      });
    }

    if (businessAddressTitle) {
      filterLabels.push({
        key: 'businessAddressTitle',
        name: "Codice Destinazione",
        value: businessAddressTitle,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'businessAddressTitle',
        value: `%${businessAddressTitle}%`,
      });
    }

    // NOTE: This is a date picker!
    if (lastOrderDate) {
      filterLabels.push({
        key: 'lastOrderDate',
        name: "Data ultimo ordine",
        value: moment(lastOrderDate).format('DD-MM-YYYY'), // NOTE: Correct format for date picker
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'lastOrderDate',
        value: `%${moment(lastOrderDate).format('YYYY-MM-DD')}%`,
      });
    }
    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
}));

LinkedDestinationTab.defaultProps = {
  values: {},
};

LinkedDestinationTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(LinkedDestinationTab);
