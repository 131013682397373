import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import Table from 'semantic-table-grid';
import messages from '../../../../containers/Invoices/messages';
import appMessages from '../../../../containers/App/messages';
import invoiceRowService from '../../../../shared/services/invoicerow';
import { currencyFormatter } from '../../../../utils/currencyFormatter';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function ContactsPart(props) {
  const [rows, setRows] = useState([]);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    invoiceRowService
      .getByInvoice(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setRows(_.get(res, `data._embedded.invoice_row`), []);
      });
  };

  const columns = () =>
    [
      {
        key: 'rowNumber',
        name: props.intl.formatMessage(messages.row_number),
      },
      {
        key: 'product',
        name: props.intl.formatMessage(messages.product),
        formatter: ({ data }) => data.description || `${data.product.code} - ${data.product.displayName}`,
      },
      {
        key: 'quantity',
        name: props.intl.formatMessage(messages.quantity),
      },
      {
        key: 'grossPrice',
        name: props.intl.formatMessage(messages.grossPrice),
        formatter: ({ data }) => currencyFormatter.format(data.grossPrice || 0),
      },
      {
        key: 'Discount',
        name: "Sconto",
        formatter: ({ data }) => `${data.firstDiscount}%` || 0,
      },
      {
        key: 'netPrice',
        name: props.intl.formatMessage(messages.netPrice),
        formatter: ({ data }) => currencyFormatter.format(data.netPrice || 0),
      },
      {
        key: 'totalNetPrice',
        name: props.intl.formatMessage(messages.totalNetPrice),
        formatter: ({ data }) =>
          currencyFormatter.format(data.totalNetPrice || 0),
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={rows}
        rowClassKey="role-row-class"
        columns={columns()}
        canSort={{
          active: false,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: false,
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

ContactsPart.defaultProps = {
  values: {},
};

ContactsPart.propTypes = {
  values: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(ContactsPart);
