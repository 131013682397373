import { get } from 'lodash';
import moment from 'moment';
import { DatePicker } from 'novigo-date-time-picker';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors';
function FormikDate(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched, initialValues },
      }) => (
        <DatePicker // FIX: There seems to be a hardcoded CSS value of 85px in the DatePicker component. This is causing the date picker to be cut off in the UI.
          format="DD/MM/YYYY"
          locale={props.locale}
          value={value ? moment(value) : null}
          name={props.name}
          onChange={internalValue => {
            setFieldTouched(
              props.name,
              get(initialValues, 'props.name', false) &&
                !initialValues[props.name].isSame(internalValue),
            );
            setFieldValue(props.name, internalValue);
          }}
          disabled={props.readOnly}
        />
      )}
    </SimpleFormikField>
  );
}

FormikDate.propTypes = {
  locale: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
  locale,
}));

export default connect(
  mapStateToProps,
  null,
)(FormikDate);
