/* eslint-disable no-unused-vars */
import React from 'react';
import _, { get } from 'lodash';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { connect } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import messages from '../../../../containers/Overdue/messages';
import currencyFormatter from '../../../../utils/currencyFormatter';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikDate from '../../../FormikDate';

const OverdueTab = props => {
  const columns = () => [
    {
      key: 'displayName',
      name: props.intl.formatMessage(messages.customer),
      useAlias: 'customer',
      searchable: false,
      sortable: true,
      formatter: ({ data }) => {
        const isOverdue =
          data.dueDate && data.dueDate.date < moment().format('YYYY-MM-DD');
        const label = `${_.get(data, '_embedded.customer.code', '')} - ${_.get(
          data,
          '_embedded.customer.displayName',
          '',
        )}`;
        return (
          <>
            <span className={isOverdue ? 'hasOverdueRow' : ''}>{label}</span>
          </>
        );
      },
    },
    {
      key: 'documentNumber',
      name: props.intl.formatMessage(messages.document_number),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const isOverdue =
          data.dueDate && data.dueDate.date < moment().format('YYYY-MM-DD');
        const label = `${_.get(data, 'documentNumber', '')}`;
        return (
          <>
            <span className={isOverdue ? 'hasOverdueRow' : ''}>{label}</span>
          </>
        );
      },
    },
    {
      key: 'documentDate',
      name: props.intl.formatMessage(messages.insert_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const isOverdue =
          data.dueDate && data.dueDate.date < moment().format('YYYY-MM-DD');
        const label = _.get(data, 'documentDate', false)
          ? moment(_.get(data, 'documentDate.date')).format('DD/MM/YYYY')
          : '--';
        return (
          <>
            <span className={isOverdue ? 'hasOverdueRow' : ''}>{label}</span>
          </>
        );
      },
    },
    {
      key: 'priceAmount',
      name: props.intl.formatMessage(messages.total_price_amount),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const isOverdue =
          data.dueDate && data.dueDate.date < moment().format('YYYY-MM-DD');
        const label = currencyFormatter.format(
          _.get(data, 'priceAmount', '--'),
        );
        return (
          <>
            <span className={isOverdue ? 'hasOverdueRow' : ''}>{label}</span>
          </>
        );
      },
    },
    {
      key: 'dueDate',
      name: props.intl.formatMessage(messages.due_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const isOverdue =
          data.dueDate && data.dueDate.date < moment().format('YYYY-MM-DD');
        const label = _.get(data, 'dueDate', false)
          ? moment(_.get(data, 'dueDate.date')).format('DD/MM/YYYY')
          : '--';
        return (
          <>
            <span className={isOverdue ? 'hasOverdueRow' : ''}>{label}</span>
          </>
        );
      },
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="destination-payment-deadlines"
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          basePath={API.BASE_URL}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          aliases={[
            {
              type: 'leftjoin',
              field: 'customer',
              alias: 'customer',
            },
          ]}
          columns={columns()}
          canSearchCustom={Filters()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'customer',
              value: get(props, 'values.customer.value', ''),
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

const Filters = () => connect(({
  active: true,
  title: "Filtri",
  buttonLabel: "Filtra",
  formStructure: (
    <>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="documentNumber"
          label="Numero documento"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="priceAmount"
          label="Valore"
        />
      </Grid.Column>
      <div style={{ width: 15 }} />
      <FormikDate
        name="createdAt"
        label="Data inserimento"
      />
      <div style={{ width: 25 }} />
      <FormikDate
        name="dueDate"
        label="Data scadenza"
      />
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    const documentNumber = _.get(values, 'documentNumber', null);
    const createdAt = _.get(values, 'createdAt', null);
    const priceAmount = _.get(values, 'priceAmount', null);
    const dueDate = _.get(values, 'dueDate', null);

    if (documentNumber) {
      filterLabels.push({
        key: 'documentNumber',
        name: "STATO",
        value: documentNumber.label,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'documentNumber',
        value: `%${documentNumber}%`,
      });
    }

    // NOTE: This is a date picker!
    if (createdAt) {
      filterLabels.push({
        key: 'createdAt',
        name: "Data inserimento",
        value: moment(dueDate).format('DD-MM-YYYY'), // NOTE: Correct format for date picker
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'createdAt',
        value: `%${moment(createdAt).format('YYYY-MM-DD')}%`,
      });
    }

    if (priceAmount) {
      filterLabels.push({
        key: 'priceAmount',
        name: "STATO",
        value: priceAmount.label,
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'priceAmount',
        value: `%${priceAmount}%`,
      });
    }

    // NOTE: This is a date picker!
    if (dueDate) {
      filterLabels.push({
        key: 'dueDate',
        name: "Data scadenza",
        value: moment(dueDate).format('DD-MM-YYYY'), // NOTE: Correct format for date picker
        filterField: 'id',
      });

      filters.push({
        type: 'like',
        where: 'and',
        field: 'dueDate',
        value: `%${moment(dueDate).format('YYYY-MM-DD')}%`,
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
}));

OverdueTab.propTypes = {
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(OverdueTab);
