/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import {
  RELATED_TABLES,
  StaffWorkorderColumns,
  StaffWorkorderFilters,
} from '../../../../containers/TechnicalIntervention/tableHelper';
import { addNotification } from '../../../../utils/notification';

const TechnicalInterventionsTab = props => {

  const CanManage = user => {
    const roles = _.get(user, 'roles', []);
    const isAdmin = roles.includes('ROLE_ADMIN');
    return isAdmin;
  };

  const canEdit = item => {
    const isAdmin = CanManage(props.user);
    if (item.state === "Chiuso" && !isAdmin) {
      addNotification({
        title: "Errore",
        message: "L'intervento é chiuso, non é modificabile",
        isError: true,
      });
      return false;
    }
    return true;
  }

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="staff-workorder"
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          basePath={API.BASE_URL}
          permissions={props.user}
          router={props}
          aliases={RELATED_TABLES}
          client={pars => request(pars).then(res => res.data)}
          canSearchCustom={StaffWorkorderFilters(props.intl)}
          columns={StaffWorkorderColumns(props.intl)}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: true,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              {
                icon: 'edit',
                label: 'edit element',
                key: 'editRelatedData',
                action: data => {
                  if (canEdit(data))
                    props.history.push(`/staff-workorder/${data.id}/modify`);
                },
              },
              {
                icon: 'trash',
                label: 'delete element',
                key: 'deleteRelatedData',
                color: 'red',
                action: data => {
                  if (canEdit(data))
                    props.history.push(`/staff-workorder/${data.id}/delete`);
                },
              },
            ],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'destination',
              value: props.values.id,
            },
          ]}
          canSelect={{
            active: false,
            selectAll: false,
            actions: [],
          }}
        />
      </TableGlobalSearch>
    </ListWrapper>
  )
};

TechnicalInterventionsTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(TechnicalInterventionsTab);
