/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid, Button, Icon, Form } from 'semantic-ui-react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Async as AsyncSelect } from 'react-select';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../../../FormikAsyncSelect';

import appMessages from '../../../../../containers/App/messages';
import messages from '../../messages';
import { ModalFakeActions } from '../../../../Layout';
import { addNotification } from '../../../../../utils/notification';
import DestinationService from '../../../../../shared/services/destination';
import OrderService from '../../../../../shared/services/order';
import FullModal from '../../../../Modal';

export default function ChangeCustomerModal(props) {
  const { open, onClose, intl } = props;
  const [order, setOrder] = useState({ id: null, destination: null });

  /**
   * Handles the change of input order.
   */
  useEffect(
    () => {
      if (props.order) {
        const dest = get(props.order, '_embedded.destination');

        setOrder({
          ...props.order,
          destination: {
            label: `${dest.code} ${dest.businessName}`,
            value: dest.id,
          },
        });
      }
    },
    [props.order],
  );

  /**
   * Handles the form submit.
   * @param {*} values
   * @param {*} param1
   */
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const payload = {
      id: values.id,
      destination: values.destination.value,
    };
    OrderService.patch(payload)
      .then(() => {
        setSubmitting(false);

        addNotification({
          title: null,
          message: props.intl.formatMessage(messages.destination_saved),
          isError: false,
        });
        props.onUpdate();
        props.onClose();
      })
      .catch(err => {
        setSubmitting(false);
        let message = get(
          err.data,
          'detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        );
        const destinationErrors = get(
          err.data,
          'validation_messages.destination',
          null,
        );
        if (destinationErrors) {
          message = (
            <>
              <FormattedMessage {...messages.destination_errors} />
              <ul>
                {Object.entries(destinationErrors).map(([key, value]) => (
                  <li key={key}>{value}</li>
                ))}
              </ul>
            </>
          );
        }

        addNotification({
          title: intl.formatMessage(appMessages.error),
          message,
          isError: true,
        });
      });
  };

  return (
    <FullModal
      open={open}
      onClose={onClose}
      title="Modifica cliente"
      size="small"
      scrolling
    >
      <Formik
        enableReinitialize
        initialValues={order}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form>
            <Grid>
              <Grid.Column width={16}>
                <FormikAsyncSelect
                  name="destination"
                  label="Stato"
                  loadOptions={DestinationService.getOptions}
                />
              </Grid.Column>
              <ModalFakeActions>
                <div style={{ display: 'inline-block', width: '50%' }}>
                  <Button
                    icon
                    labelPosition="left"
                    disabled={isSubmitting}
                    onClick={props.onClose}
                    type="button"
                  >
                    <Icon name="remove" />
                    {intl.formatMessage(appMessages.cancel)}
                  </Button>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    width: '50%',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    color="green"
                    icon
                    labelPosition="left"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    <Icon name="checkmark" />
                    {props.intl.formatMessage(appMessages.save)}
                  </Button>
                </div>
              </ModalFakeActions>
            </Grid>
          </Form>
        )}
      </Formik>
    </FullModal>
  );
}

ChangeCustomerModal.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  order: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};
