/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable spaced-comment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import _, { get, isNaN, isEmpty } from 'lodash';
import includes from 'lodash/includes';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Icon,
  ListItem,
  List,
  ListHeader,
  Label,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { store } from '../../../../configureStore';
import appMessages from '../../../../containers/App/messages';
import { makeSelectStyles } from '../../../../containers/App/selectors';
import {
  isSellServicesOffer,
  OFFER_SERVICES_PRODUCT_CATEGORIES_ALLOWED,
  OFFER_STATE_ACCEPTED,
} from '../../../../containers/Offer/constants';
import DiscountTypeService from '../../../../shared/services/discountType';
import OfferService from '../../../../shared/services/offer';
import OfferRowService from '../../../../shared/services/offerrow';
import OfferRowFrequencyService from '../../../../shared/services/offerrowfrequency';
import ProductService from '../../../../shared/services/product';
// Services
import ProductCategoryService from '../../../../shared/services/productcategory';
import request from '../../../../shared/services/request';
import currencyFormatter, {
  numberFormatter,
} from '../../../../utils/currencyFormatter';
import { addNotification } from '../../../../utils/notification';
import percentageFormatter from '../../../../utils/percentageFormatter';
import Accordion from '../../../Accordion';
import {
  FormDuplicateBtn,
  FormExportPdfBtn,
  RemoveBtn,
  SmallCircularActionBtn,
} from '../../../Buttons';
import CurrencyFormatter from '../../../CurrencyFormatter';
import FormActionsBar from '../../../FormActionsBar';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikInput from '../../../FormikInput';
import FormikToggle from '../../../FormikToggle';
import { ModalFakeActions, Wrapper } from '../../../Layout';
import Modal from '../../../Modal';
import PercentageFormatter from '../../../PercentageFormatter';
import PermissionManager from '../../../Permission';
import messages from '../messages';
import { headerKeys, prepareHeaderData } from '../utils/formHelperFunctions';
import DiscountForm from './formParts/discountEditModal/discountForm';
import InsertNewPart from './formParts/InsertNewPart';
import NoteSection from './formParts/NoteSection';
import OfferStateSection from './formParts/offerState';
import './css/styles.css';
import { API } from '../../../../global-constants';
import EditorPopup from '../../../Popup';
import DeterchimicaTableGrid from '../../../DeterchimicaTableGrid';
import { selectNetPrice, getNetPrice } from './functions';

const ManageForm = props => {
  const {
    handleSubmit,
    offerStates,
    setFieldValue,
    setValues,
    values,
    offerData,
    readOnly,
    exportOfferPdf,
    initialValues,
    ...rest
  } = props;
  const [categories, setCategories] = useState([]);
  const [article, setArticle] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [editableRowsData, setEditableRowsData] = useState([]);
  const [offerRows, setOfferRows] = useState([]);
  const [offerRowUpdating, setOfferRowUpdating] = useState(false);
  const [discountModalOpened, setDiscountModalOpened] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [promotionPrice, setPromotionPrice] = useState(null);
  const [notesOpen, setNotesOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isInserting, setInserting] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });

  // eslint-disable-next-line no-unused-vars
  const [frequencies, setFrequencies] = useState([]);
  const [typology, setTypology] = useState(null);
  const [servicesCategory, setServicesCategory] = useState(null);
  const [activeType, setActiveType] = useState('Famiglia');
  const [isNewDiscountItem, setIsNewDiscountItem] = useState(false);
  const [discountTypes, setdiscountTypes] = useState([]);

  const handleNewDiscountItem = bool => {
    setIsNewDiscountItem(bool);
  };

  const handleActiveType = name => {
    setActiveType(name);
  };

  const handleCloseDiscountModal = () => {
    setDiscountModalOpened(false);
    handleNewDiscountItem(false);
    handleActiveType('Famiglia');
  };

  const user = store
    .getState()
    .get('user')
    .toJS();

  const handleUpdateCompliant = offer => {
    setValues({
      ...values,
      avarageDiscount: _.get(offer, 'avarageDiscount', null),
      avarageMarkup: _.get(offer, 'avarageMarkup', null),
      totalPriceAmount: _.get(offer, 'totalPriceAmount', null),
      nonCompliant: _.get(offer, 'nonCompliant', null),
      nonCompliantInsolvent: _.get(offer, 'nonCompliantInsolvent', null),
      nonCompliantAverageDiscount: _.get(
        offer,
        'nonCompliantAverageDiscount',
        null,
      ),
      nonCompliantProductDiscount: _.get(
        offer,
        'nonCompliantProductDiscount',
        null,
      ),
    });
  };

  useEffect(
    () => {
      loadData();
    },
    [values.id, _.get(values, '_embedded.rows._links.self.href', null)],
  );

  useEffect(() => {
    if (!values.discountType) {
      DiscountTypeService.getAllOptions(props.intl).then(res => {
        const defaultOption = res.filter(item => item.code === 'FIXED');
        setFieldValue('discountType', defaultOption[0]);
        setdiscountTypes(res);
      });
    }
  });

  useEffect(() => {
    setTypology(_.get(values, '_embedded.typology.name', null));
  }, _.get(values, '_embedded.typology.name', null));

  useEffect(
    () => {
      if (isSellServicesOffer(typology)) {
        const categoryResponse = categories.filter(item =>
          OFFER_SERVICES_PRODUCT_CATEGORIES_ALLOWED.includes(item.label),
        );
        setCategories(categoryResponse);

        setTimeout(() => {
          ProductCategoryService.getServicesCategory().then(res => {
            const serviceCategory = _.get(
              res.data,
              '_embedded.product-category[0]',
              null,
            );

            setServicesCategory(serviceCategory);
          });
        }, 500);

        //  TODO: make request for frequencies
        OfferRowFrequencyService.getAll()
          .then(response => {
            const frequencyResponse = _.get(
              response.data,
              '_embedded.offer_row_frequency',
              [],
            ).map(item => ({
              value: item.id,
              label: item.description,
            }));
            setFrequencies(frequencyResponse);
          })
          .catch(error => {
            addNotification({
              title: 'Error',
              message: error.responseText,
              isError: true,
            });
          });
      }
    },
    [typology],
  );

  useEffect(
    () => {
      if (isSellServicesOffer(typology)) {
        setFieldValue('category', {
          label: servicesCategory.description,
          value: servicesCategory.id,
        });
      }
    },
    [_.get(servicesCategory, 'id', null)],
  );

  /**
   * When the user changes the destination, update the offer rows as well ('cause there are different customer and offer prices).
   */
  useEffect(
    () => {
      if (offerRows.length > 0) {
        OfferService.patch({
          id: values.id,
          destination: _.get(values, 'destination.value', null),
        }).then(loadData);
      }
    },
    [values.destination],
  );

  const discountValueInput = data => {
    const foundId = editableRowsData.find(row => row.id === data.id);
    const discountType = _.get(foundId, 'discountType', null);
    // const discountValue = _.get(foundId, 'discountValue', '');
    // const discountExtra = _.get(foundId, 'discountExtra', '');
    if (discountType.code === 'PERCENTAGE') {
      return (
        <>
          <FormikInput
            {...props}
            name="discountValue"
            disabled={
              editableRowsData.find(row => row.id === data.id)
                .discountDisabled || false
            }
            label=" "
            placeholder={props.intl.formatMessage(messages.discountValue)}
            initialValue={
              editableRowsData.find(row => row.id === data.id).discountValue
                .value
            }
            onChange={e => {
              const row = editableRowsData.find(item => item.id === data.id);
              let basePrice = getNetPrice(data);
              let discountExtra = data.extraDiscount;

              const totalDiscount =
                parseFloat(e.target.value) + parseFloat(discountExtra);
              if (totalDiscount !== 0) basePrice = data.productMasterPrice;

              const value = parseFloat(e.target.value.replace(',', '.'), 10);
              const discount = value || 0;
              discountExtra = parseFloat(discountExtra) || 0;
              if (discountExtra !== '') {
                const discountedBase = (basePrice / 100) * (100 - discount);
                modifyRowDataPayload(
                  parseFloat(
                    (discountedBase / 100) * (100 - discountExtra),
                  ).toFixed(2),
                  'netPrice',
                  data.id,
                );
              }

              modifyRowDataPayload(
                row.netPrice * parseFloat(row.quantity, 10),
                'amount',
                data.id,
              );

              if (parseFloat(data.buyPrice) !== 0)
                modifyRowDataPayload(
                  (
                    parseFloat((row.netPrice - data.buyPrice) / data.buyPrice) *
                    100
                  ).toFixed(2),
                  'markup',
                  data.id,
                );

              updateDifference(data);

              if (e.target.value !== '')
                modifyRowDataPayloadValue(
                  e.target.value,
                  'discountValue',
                  data.id,
                );
              else modifyRowDataPayloadValue('0.00', 'discountValue', data.id);
            }}
          />
          <FormikInput
            {...props}
            name="discountExtra"
            disabled={
              editableRowsData.find(row => row.id === data.id)
                .discountExtraDisabled || false
            }
            label=" "
            placeholder={props.intl.formatMessage(messages.extraDiscount)}
            initialValue={
              editableRowsData.find(row => row.id === data.id).discountExtra
                .value
            }
            onChange={e => {
              let basePrice = getNetPrice(data);
              const row = editableRowsData.find(item => item.id === data.id);
              let discount = row.discountValue.value;

              //NOTE: If any discount is applied use productMasterPrice as base
              if (parseFloat(discount) + parseFloat(e.target.value) !== 0)
                basePrice = data.productMasterPrice;

              const value = parseFloat(e.target.value, 10);
              const discountExtra = value || 0;
              discount = parseFloat(discount) || 0;
              if (discountExtra !== '') {
                const discountedBase = (basePrice / 100) * (100 - discount);
                modifyRowDataPayload(
                  parseFloat(
                    (discountedBase / 100) * (100 - discountExtra),
                  ).toFixed(2),
                  'netPrice',
                  data.id,
                );
              }

              if (parseFloat(data.buyPrice) !== 0)
                modifyRowDataPayload(
                  (
                    parseFloat((row.netPrice - data.buyPrice) / data.buyPrice) *
                    100
                  ).toFixed(2),
                  'markup',
                  data.id,
                );

              updateDifference(data);

              if (e.target.value !== '')
                modifyRowDataPayloadValue(
                  e.target.value,
                  'discountExtra',
                  data.id,
                );
              else modifyRowDataPayloadValue('0.00', 'discountExtra', data.id);

              updateTotal(data);
            }}
          />
        </>
      );
    }
    if (discountType.code === 'MARKUP') {
      // TODO: Make dynamic
      return (
        <PercentageFormatter
          {...props}
          name="markup"
          label=" "
          placeholder={props.intl.formatMessage(messages.markup)}
          initialValue={editableRowsData.find(row => row.id === data.id).markup}
          onChange={value => modifyRowDataPayload(value, 'markup', data.id)}
        />
      );
    }
    if (discountType.code === 'FLAT') {
      // TODO: Make dynamic
      return (
        <CurrencyFormatter
          {...props}
          name="flatValue"
          label=" "
          placeholder={props.intl.formatMessage(messages.discountValue)}
          initialValue={
            editableRowsData.find(row => row.id === data.id).flatValue
          }
          onChange={value => modifyRowDataPayload(value, 'flatValue', data.id)}
        />
      );
    }
    if (discountType.code === 'FIXED') {
      // TODO: Make dynamic
      return (
        <CurrencyFormatter
          {...props}
          name="netPrice"
          label=" "
          placeholder={props.intl.formatMessage(messages.net_price_modal)}
          initialValue={
            editableRowsData.find(row => row.id === data.id).netPrice
          }
          onChange={value => modifyRowDataPayload(value, 'netPrice', data.id)}
        />
      );
    }
    return null;
  };

  const columns = () => {
    const offerRowColumns = [
      {
        key: 'name',
        width: '400px',
        name: (
          <span>
            {props.intl.formatMessage(messages.article)}
            <br />
            {props.intl.formatMessage(messages.competitorNote)}
            <br />
            {props.intl.formatMessage(messages.description)}
          </span>
        ),
        formatter: ({ data }) => {
          const replacementProducts = get(
            data,
            'product.replacementProducts',
            null,
          )
            ? get(data, 'product.replacementProducts', null)
              .map(p => p.title)
              .toString()
            : '--';

          const dataHover = [
            {
              key: 'Nota offerta',
              value: get(data, 'product.noteOffer', '--') || '--',
            },
            {
              key: 'Prezzo acquisto',
              value: get(data, 'buyPrice', null)
                ? currencyFormatter.format(get(data, 'buyPrice', null))
                : '--',
            },
            {
              key: 'Nota articolo',
              value: get(data, 'product.note', '--') || '--',
            },
            {
              key: 'Prodotto sostitutivo',
              value: replacementProducts,
            },
          ];
          return (
            <EditorPopup
              isActiveHover
              displayAction="hover"
              hoverData={
                <>
                  <Link
                    to={`/product/${data.product.id}/view`}
                    style={{ color: 'black', textDecorationLine: 'underline' }}
                  >
                    <h3>
                      {get(data, 'product.productCode', '--')} -{' '}
                      {_.get(data, 'product.title', '')}
                    </h3>
                  </Link>
                  <div className="col">
                    {dataHover.map(row => (
                      <div style={row.style ? row.style : {}}>
                        {row.key}: {row.value}
                      </div>
                    ))}
                  </div>
                </>
              }
              hasCustom
              position="bottom"
              triggerItem={
                editableRows.includes(data.id) ? (
                  <div>
                    <FormikAsyncSelect
                      {...props}
                      name="product"
                      label=" "
                      initialValue={
                        editableRowsData.find(row => row.id === data.id).product
                      }
                      onChange={product => {
                        handleOnArticleRowChange(product.value, data);
                      }}
                      asyncSelectProps={{
                        isClearable: true,
                      }}
                      loadOptions={(searchText, callback) =>
                        ProductService.getOptions(searchText, callback)
                      }
                    />
                    <FormikInput
                      {...props}
                      label=" "
                      name="competitorNote"
                      placeholder={props.intl.formatMessage(
                        messages.competitorNote,
                      )}
                      initialValue={
                        editableRowsData.find(row => row.id === data.id)
                          .competitorNote
                      }
                      onChange={e =>
                        modifyRowDataPayload(
                          e.target.value,
                          'competitorNote',
                          data.id,
                        )
                      }
                    />
                    <FormikInput
                      {...props}
                      label=" "
                      name="description"
                      placeholder={props.intl.formatMessage(
                        messages.description,
                      )}
                      initialValue={
                        editableRowsData.find(row => row.id === data.id)
                          .description
                      }
                      onChange={e =>
                        modifyRowDataPayload(
                          e.target.value,
                          'description',
                          data.id,
                        )
                      }
                    />
                  </div>
                ) : (
                  (data.isOrdered && (
                    <div>
                      <Label ribbon color="blue">
                        {'Ordinato'}
                        <br />
                      </Label>
                      <div>
                        {_.get(data, 'product.productCode', '')}
                        {data.product &&
                          data.product.productCode &&
                          data.product.title &&
                          ' - '}
                        {_.get(data, 'product.title', '')}
                      </div>
                      <div>
                        {`${_.get(data, 'product.category.description', '')}`}
                        {data.product &&
                          data.product.subcategory &&
                          data.product.subcategory.description &&
                          ' > '}
                        {_.get(data, 'product.subcategory.description', '')}
                        {data.product &&
                          data.product.domain &&
                          data.product.domain.description &&
                          ' > '}
                        {_.get(data, 'product.domain.description', '')}
                      </div>
                    </div>
                  )) || (
                    <div>
                      <div>
                        {_.get(data, 'product.productCode', '')}
                        {data.product &&
                          data.product.productCode &&
                          data.product.title &&
                          ' - '}
                        {_.get(data, 'product.title', '')}
                      </div>
                      <div>
                        {`${_.get(data, 'product.category.description', '')}`}
                        {data.product &&
                          data.product.subcategory &&
                          data.product.subcategory.description &&
                          ' > '}
                        {_.get(data, 'product.subcategory.description', '')}
                        {data.product &&
                          data.product.domain &&
                          data.product.domain.description &&
                          ' > '}
                        {_.get(data, 'product.domain.description', '')}
                      </div>
                    </div>
                  )
                )
              }
              styles={{
                width: '450px',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            />
          );
        },
      },
      PermissionManager.CanI('accessProductInformation', 'custom') && {
        key: 'buyPrice',
        width: 90,
        name: <span>{props.intl.formatMessage(messages.buying_price)}</span>,
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <>
              <FormikInput
                {...props}
                name="buyPrice"
                label=" "
                placeholder={props.intl.formatMessage(messages.buying_price)}
                initialValue={
                  editableRowsData.find(row => row.id === data.id).buyPrice
                }
                onChange={e => {
                  let value = e.target.value.replace(',', '.');
                  value = value === '' ? 0 : parseFloat(value);
                  const editRow = editableRowsData.find(
                    row => row.id === data.id,
                  );

                  modifyRowDataPayload(e.target.value, 'buyPrice', data.id);
                  if (e.target.value !== '' && parseFloat(e.target.value) !== 0 && parseFloat(editRow.netPrice) !== 0) {
                    modifyRowDataPayload(
                      (
                        parseFloat(
                          (editRow.netPrice - parseFloat(value)) /
                          parseFloat(value),
                        ) * 100
                      ).toFixed(2),
                      'markup',
                      data.id,
                    );

                    if (editRow.competitorPrice !== '' && parseFloat(editRow.competitorPrice) !== 0 && editRow.competitorPrice !== null) {
                      modifyRowDataPayload(
                        parseFloat(
                          ((editableRowsData.find(row => row.id === data.id)
                            .competitorPrice -
                            value) /
                            value) *
                          100,
                        ).toFixed(2),
                        'competitorMarkup',
                        data.id,
                      );
                    } else {
                      modifyRowDataPayload('0.00', 'competitorMarkup', data.id);
                    }
                  } else {
                    modifyRowDataPayload('0.00', 'markup', data.id);
                  }
                }}
              />
            </>
          ) : data.buyPrice ? (
            `${currencyFormatter.format(_.get(data, 'buyPrice', ''))}${data.isBuyPriceModified ? '*' : ''
            }`
          ) : (
            '--'
          ),
      },
      PermissionManager.CanI('accessProductInformation', 'custom') && {
        key: 'competitorPrice',
        width: 90,
        name: (
          <span>{props.intl.formatMessage(messages.competitor_price)}</span>
        ),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <>
              <FormikInput
                {...props}
                name="competitorPrice"
                label=" "
                placeholder={props.intl.formatMessage(
                  messages.competitor_price,
                )}
                initialValue={
                  isEmpty(editableRowsData.find(row => row.id === data.id).competitorPrice) ? '0.00' : editableRowsData.find(row => row.id === data.id).competitorPrice
                }
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9.]/g, '');
                  value = value === "" ? "0.00" : value;
                  const editRow = editableRowsData.find(
                    row => row.id === data.id,
                  );

                  if (
                    parseFloat(value) !== 0 &&
                    (editRow.buyPrice === ''
                      ? 0
                      : parseFloat(editRow.buyPrice) !== 0)
                  ) {
                    modifyRowDataPayload(
                      parseFloat(
                        ((value - editRow.buyPrice) / editRow.buyPrice) * 100,
                      ).toFixed(2),
                      'competitorMarkup',
                      data.id,
                    );


                  } else {
                    modifyRowDataPayload('0.00', 'difference', data.id);
                    modifyRowDataPayload('0.00', 'competitorMarkup', data.id);
                  }
                  modifyRowDataPayload(value, 'competitorPrice', data.id);
                  updateDifference(data);
                }}
              />
            </>
          ) : data.buyPrice ? (
            `${currencyFormatter.format(_.get(data, 'competitorPrice', ''))}${data.isBuyPriceModified ? '*' : ''
            }`
          ) : (
            '--'
          ),
      },
      {
        key: 'prices',
        width: 90,
        name: (
          <span>
            {props.intl.formatMessage(messages.unit_gross_price)}
            <br />
            {props.intl.formatMessage(messages.productCustomerPrice)}
            <br />
            {props.intl.formatMessage(messages.productOfferPrice)}
          </span>
        ),
        formatter: ({ data }) => {
          const {
            productCustomerPrice,
            grossPrice,
            offerPrice,
            productMasterPrice,
          } = data;
          return (
            <>
              {productMasterPrice
                ? currencyFormatter.format(productMasterPrice)
                : '--'}
              <br />
              {productCustomerPrice
                ? currencyFormatter.format(productCustomerPrice)
                : '--'}
              <br />
              {offerPrice ? currencyFormatter.format(offerPrice) : '--'}
            </>
          );
        },
      },
      {
        key: 'discountValue',
        width: 110,
        name: props.intl.formatMessage(messages.discount),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            discountValueInput(data)
          ) : (
            <div>
              {data.discountValue
                ? percentageFormatter.format(
                  _.get(data, 'discountValue', '') / 100,
                )
                : ''}
              {data.discountValue && data.extraDiscount && ' + '}
              {data.extraDiscount
                ? percentageFormatter.format(
                  _.get(data, 'extraDiscount', '') / 100,
                )
                : ''}
            </div>
          ),
      },
      {
        key: 'netPrice',
        width: 90,
        name: props.intl.formatMessage(messages.unit_net_price),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <FormikInput
              {...props}
              name="netPrice"
              disabled={
                editableRowsData.find(row => row.id === data.id)
                  .netPriceDisabled || false
              }
              label=" "
              initialValue={
                editableRowsData.find(row => row.id === data.id).netPrice
              }
              onChange={e => {
                const np = getNetPrice(data);
                const editableData = editableRowsData.find(row => row.id === data.id);
                const value = e.target.value.replace(',', '.');
                if (
                  np !== null &&
                  parseFloat(np) !== 0 &&
                  data.productMasterPrice !== 0
                )
                  modifyRowDataPayloadValue(
                    parseFloat(
                      100 - (value * 100) / data.productMasterPrice,
                    ).toFixed(2),
                    'discountValue',
                    data.id,
                  );
                modifyRowDataPayloadValue('0', 'discountExtra', data.id);
                modifyRowDataPayload(value, 'netPrice', data.id);

                updateDifference(data);

                if (parseFloat(editableData.buyPrice) !== 0)
                  modifyRowDataPayload(
                    (
                      parseFloat((value - editableData.buyPrice) / editableData.buyPrice) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );
                updateTotal(data);
              }}
            />
          ) : data.netPrice ? (
            currencyFormatter.format(_.get(data, 'netPrice', ''))
          ) : (
            '--'
          ),
      },
      {
        key: 'minQuantity',
        width: 100,
        name: props.intl.formatMessage(messages.min_quantity),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <FormikInput
              {...props}
              name="quantity"
              label=" "
              initialValue={
                editableRowsData.find(row => row.id === data.id).quantity
              }
              onChange={e => {
                const { netPrice } = editableRowsData.find(
                  row => row.id === data.id,
                );
                modifyRowDataPayload(
                  e.target.value * netPrice,
                  'amount',
                  data.id,
                );
                modifyRowDataPayload(e.target.value, 'quantity', data.id);
              }}
            />
          ) : data.minQuantity ? (
            `${numberFormatter.format(_.get(data, 'minQuantity'))} pz`
          ) : (
            '--'
          ),
      },
      {
        key: 'priceAmount',
        width: 80,
        name: props.intl.formatMessage(messages.priceAmount),
        formatter: ({ data }) => {
          let res = editableRowsData.find(row => row.id === data.id);
          res = res
            ? currencyFormatter.format(
              editableRowsData.find(row => row.id === data.id).amount,
            )
            : data.priceAmount;
          return res;
        },
      },
      PermissionManager.CanI('accessProductInformation', 'custom') && {
        key: 'markup',
        width: 100,
        name: props.intl.formatMessage(messages.markup),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <FormikInput
              name="markup"
              label=" "
              initialValue={
                editableRowsData.find(row => row.id === data.id).markup
              }
              onChange={e => {
                const editRow = editableRowsData.find(row => row.id === data.id);
                const value = e.target.value.replace(',', '.');
                updateDifference(data);

                const np =
                  (value / 100) * parseFloat(editRow.buyPrice) +
                  parseFloat(editRow.buyPrice);

                if (np !== null && parseFloat(np) !== 0 && editRow.buyPrice !== 0) {
                  modifyRowDataPayload(value, 'markup', data.id);
                  modifyRowDataPayload(np, 'netPrice', data.id);
                }

                if (parseFloat(data.productMasterPrice) !== 0 && data.productMasterPrice !== null)
                  modifyRowDataPayloadValue(
                    parseFloat(
                      100 - (np * 100) / data.productMasterPrice,
                    ).toFixed(2),
                    'discountValue',
                    data.id,
                  );

                updateDifference(data);
                modifyRowDataPayloadValue('0', 'discountExtra', data.id);
                modifyRowDataPayload(
                  e.target.value * parseFloat(data.quantity, 10),
                  'priceAmount',
                  data.id,
                );
              }}
            />
          ) : (
            data.netPrice
          ),
      },
      PermissionManager.CanI('accessProductInformation', 'custom') && {
        key: 'competitorMarkup',
        width: 120,
        name: (
          <span>
            {props.intl.formatMessage(messages.competitorMarkup)}
            <br />
            {props.intl.formatMessage(messages.difference)}
            <br />
          </span>
        ),
        formatter: ({ data }) => {
          const row = editableRowsData.find(r => r.id === data.id);
          if (row === undefined) return <div />;
          return (
            <>
              {row.competitorMarkup
                ? percentageFormatter.format(row.competitorMarkup / 100)
                : '--'}
              <br />
              {row.difference
                ? percentageFormatter.format(row.difference / 100)
                : '--'}
            </>
          );
        },
      },
      {
        key: 'goods_discount',
        width: 1,
        name: <span>{props.intl.formatMessage(messages.goods_discount)}</span>,
        formatter: ({ data }) =>
          // eslint-disable-next-line no-nested-ternary
          editableRows.includes(data.id) ? (
            <>
              <Checkbox
                {...props}
                name="goodsDiscount"
                label=" "
                checked={
                  editableRowsData.find(row => row.id === data.id).goodsDiscount
                }
                onChange={(value, toggleData) => {
                  const row = editableRowsData.find(r => r.id === data.id,);
                  applyMerchDiscount(toggleData.checked, data);

                  updateDifference(data);
                  modifyRowDataPayload(
                    toggleData.checked,
                    'goodsDiscount',
                    data.id,
                  );
                }}
              />
            </>
          ) : (
            <>
              {data.goodsDiscount
                ? props.intl.formatMessage(messages.yes)
                : props.intl.formatMessage(messages.no)}
            </>
          ),
      },
      {
        key: 'loan',
        name: <span>{props.intl.formatMessage(messages.loan)}</span>,
        width: 1,
        formatter: ({ data }) =>
          // eslint-disable-next-line no-nested-ternary
          editableRows.includes(data.id) ? (
            <>
              <Checkbox
                {...props}
                name="loan"
                label=" "
                disabled={!data.product.sellLoan}
                checked={editableRowsData.find(row => row.id === data.id).loan}
                onChange={(value, toggleData) => {
                  const row = editableRowsData.find(r => r.id === data.id,);
                  applyMerchDiscount(toggleData.checked, data);
                  modifyRowDataPayload(toggleData.checked, 'loan', data.id);
                  updateDifference(data);
                }}
              />
            </>
          ) : (
            <>
              {data.loan
                ? props.intl.formatMessage(messages.yes)
                : props.intl.formatMessage(messages.no)}
            </>
          ),
      },
    ].filter(c => c);

    // new fields when offer sells only services
    if (isSellServicesOffer(_.get(values, '_embedded.typology.name'))) {
      offerRowColumns.push({
        key: 'frequency',
        name: props.intl.formatMessage(messages.frequency),
        formatter: ({ data }) =>
          _.get(data, '_embedded.frequency', null)
            ? _.get(data, '_embedded.frequency.description')
            : '--',
      });

      offerRowColumns.push({
        key: 'amount',
        name: props.intl.formatMessage(messages.amount),
        formatter: ({ data }) =>
          data.amount
            ? currencyFormatter.format(_.get(data, 'amount', ''))
            : '--',
      });
    }

    if (!readOnly) {
      // push actions here to be the last column of table
      offerRowColumns.push({
        key: 'actions',
        name: 'Actions',
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <SmallCircularActionBtn
              loading={offerRowUpdating[data.id]}
              disabled={offerRowUpdating[data.id]}
              icon={
                editableRows.includes(data.id) ? 'check' : 'pencil alternate'
              }
              color={editableRows.includes(data.id) ? 'yellow' : 'blue'}
              action={() =>
                editableRows.includes(data.id)
                  ? saveRow(data.id)
                  : manageEditableRow(data)
              }
            />
            {editableRows.includes(data.id) ? (
              <SmallCircularActionBtn
                disabled={offerRowUpdating[data.id]}
                icon="remove"
                color="orange"
                action={() => cancelEditRow(data.id)}
              />
            ) : (
              <RemoveBtn
                action={() => removeArticle(data.id)}
                intl={props.intl}
              />
            )}
          </div>
        ),
        width: '80px',
      });
    }

    return offerRowColumns;
  };

  /**
   * Edit inline a single row.
   * @param {*} rowData The row data.
   */
  const manageEditableRow = rowData => {
    const discountType = _.get(
      rowData,
      '_embedded.discountType',
      discountTypes.find(item => item.code === 'PERCENTAGE'),
    );

    const rowPayload = {
      id: rowData.id,
      product: {
        label: `${_.get(rowData, 'product.productCode', null)} -
        ${_.get(rowData, 'product.title', null)}`,
        value: _.get(rowData, 'product.id', null),
      },
      competitorNote: _.get(rowData, 'competitorNote', null),
      competitorMarkup: _.get(rowData, 'competitorMarkup', null),
      difference: _.get(rowData, 'difference', null),
      discountType: {
        label: props.intl.formatMessage(messages[discountType.code]),
        value: discountType.value ? discountType.value : discountType.id,
        code: discountType.code,
      },
      quantity: _.get(rowData, 'minQuantity', 1),
      buyPrice: _.get(rowData, 'buyPrice', null),
      competitorPrice: _.get(rowData, 'competitorPrice', null),
      discountValue: { value: _.get(rowData, 'discountValue', null) },
      discountExtra: { value: _.get(rowData, 'extraDiscount', null) },
      markup: _.get(rowData, 'markup', null),
      flatValue: _.get(rowData, 'flatValue', null),
      netPrice: parseFloat(_.get(rowData, 'netPrice', null)).toFixed(2),
      loan: _.get(rowData, 'loan', ''),
      amount: _.get(rowData, 'priceAmount', ''),
      goodsDiscount: _.get(rowData, 'goodsDiscount', ''),
      description: _.get(rowData, 'description', ''),
    };
    switch (_.get(rowData, '_embedded.discountType.code')) {
      case 'PERCENTAGE':
        delete rowPayload.flatValue;
        delete rowPayload.netPrice;
        delete rowPayload.markup;
        break;
      case 'FLAT':
        delete rowPayload.discountValue;
        delete rowPayload.discountExtra;
        delete rowPayload.netPrice;
        delete rowPayload.markup;
        break;
      case 'FIXED':
        delete rowPayload.discountValue;
        delete rowPayload.discountExtra;
        delete rowPayload.flatValue;
        delete rowPayload.markup;
        break;
      case 'MARKUP':
        delete rowPayload.discountValue;
        delete rowPayload.discountExtra;
        delete rowPayload.flatValue;
        delete rowPayload.netPrice;
        break;
      default:
        break;
    }
    setEditableRowsData([...editableRowsData, rowPayload]);
    setEditableRows([...editableRows, rowData.id]);
  };

  /**
   * Edit a list of rows.
   * @param {*} rowsData The row data.
   */
  const manageEditableRows = rowsData => {
    const arrEditableData = [];
    const arrEditableRows = [];

    rowsData.forEach(rowData => {
      const discountType = _.get(
        rowData,
        '_embedded.discountType',
        discountTypes.find(item => item.code === 'PERCENTAGE'),
      );
      const rowPayload = {
        id: rowData.id,
        product: {
          label: `${_.get(rowData, 'product.productCode', null)} -
          ${_.get(rowData, 'product.title', null)}`,
          value: _.get(rowData, 'product.id', null),
        },
        competitorNote: _.get(rowData, 'competitorNote', null),
        discountType: {
          label: props.intl.formatMessage(messages[discountType.code]),
          value: discountType.value ? discountType.value : discountType.id,
          code: discountType.code,
        },
        amount: _.get(rowData, 'amount', 1),
        quantity: _.get(rowData, 'minQuantity', 1),
        buyPrice: _.get(rowData, 'buyPrice', null),
        competitorPrice: _.get(rowData, 'competitorPrice', null),
        discountValue: {
          value: parseFloat(_.get(rowData, 'discountValue', null)).toFixed(2),
        },
        discountExtra: {
          value: parseFloat(_.get(rowData, 'extraDiscount', null)).toFixed(2),
        },
        markup: _.get(rowData, 'markup', null),
        flatValue: _.get(rowData, 'flatValue', null),
        netPrice: parseFloat(_.get(rowData, 'netPrice', null)).toFixed(2),
        loan: _.get(rowData, 'loan', ''),
        goodsDiscount: _.get(rowData, 'goodsDiscount', ''),
        description: _.get(rowData, 'description', ''),
        difference: _.get(rowData, 'difference', ''),
        competitorMarkup: _.get(rowData, 'competitorMarkup', ''),
      };

      switch (_.get(rowData, '_embedded.discountType.code')) {
        case 'PERCENTAGE':
          delete rowPayload.flatValue;
          // delete rowPayload.netPrice;
          // delete rowPayload.markup;
          break;
        case 'FLAT':
          // delete rowPayload.discountValue;
          // delete rowPayload.discountExtra;
          delete rowPayload.netPrice;
          delete rowPayload.markup;
          break;
        case 'FIXED':
          // delete rowPayload.discountValue;
          // delete rowPayload.discountExtra;
          delete rowPayload.flatValue;
          delete rowPayload.markup;
          break;
        case 'MARKUP':
          // delete rowPayload.discountValue;
          // delete rowPayload.discountExtra;
          delete rowPayload.flatValue;
          delete rowPayload.netPrice;
          break;
        default:
          break;
      }

      arrEditableData.push(rowPayload);
      arrEditableRows.push(rowData.id);
    });
    setEditableRowsData(arrEditableData);
    setEditableRows(arrEditableRows);
    return arrEditableData;
  };

  const cancelEditRow = rowId => {
    setEditableRows(editableRows.filter(item => item !== rowId));
    setEditableRowsData(editableRowsData.filter(item => item.id !== rowId));
  };

  const applyMerchDiscount = (checked, data) => {
    if (checked) {
      modifyRowDataPayload(true, 'discountDisabled', data.id);
      modifyRowDataPayload(true, 'discountExtraDisabled', data.id);
      modifyRowDataPayload(true, 'netPriceDisabled', data.id);
      modifyRowDataPayloadValue(100, 'discountValue', data.id);
      modifyRowDataPayloadValue('0', 'discountExtra', data.id);
      modifyRowDataPayload('0', 'netPrice', data.id);
    }
    if (!checked) {
      modifyRowDataPayload(false, 'discountDisabled', data.id);
      modifyRowDataPayload(false, 'discountExtraDisabled', data.id);
      modifyRowDataPayload(false, 'netPriceDisabled', data.id);
      modifyRowDataPayloadValue('0', 'discountValue', data.id);
      modifyRowDataPayloadValue('0', 'discountExtra', data.id);

      const netPrice = getNetPrice(data);
      updateNetPrice(netPrice, data.id, 0, 0);
    }

    updateTotal(data);
  };

  const modifyRowDataPayload = (data, name, id) => {
    const dataToModify = [...editableRowsData];
    const row = dataToModify.find(item => item.id === id);
    row[name] = data;
    const index = dataToModify.findIndex(item => item.id === id);
    dataToModify[index] = row;
    setEditableRowsData(dataToModify);
  };

  const modifyRowDataPayloadValue = (data, name, id) => {
    const dataToModify = [...editableRowsData];
    const row = dataToModify.find(item => item.id === id);
    row[name].value = data;
    const index = dataToModify.findIndex(item => item.id === id);
    dataToModify[index] = row;
    setEditableRowsData(dataToModify);
  };

  const saveRow = (id, silentMode = false) => {
    setOfferRowUpdating({ [id]: true });
    const row = editableRowsData.find(item => item.id === id);
    const payload = {
      ...row,
      product: _.get(row, 'product.value', null),
      discountType: _.get(
        row,
        'discountType.value',
        _.get(row, 'discountType.label', null),
      ),
      loan: _.get(row, 'loan') === true ? 1 : 0,
      goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
      discountValue: parseFloat(
        _.get(row, 'discountValue.value', null) ||
        _.get(row, 'discountValue', null),
      ).toFixed(2),
      extraDiscount: parseFloat(
        _.get(row, 'discountExtra.value', null) ||
        _.get(row, 'discountExtra', null),
      ).toFixed(2),
      markup: _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
      flatValue:
        _.get(row, 'flatValue.value', null) || _.get(row, 'flatValue', null),
      netPrice:
        _.get(row, 'netPrice.value', null) || _.get(row, 'netPrice', null),
      difference: _.get(row, 'difference', null),
      competitorMarkup: _.get(row, 'competitorMarkup', null),
      minQuantity: _.get(row, 'quantity', null),
    };

    if (isNaN(payload.discountExtra) || payload.discountExtra === 'NaN')
      payload.extraDiscount = 0;
    if (isNaN(payload.discountValue) || payload.discountValue === 'NaN')
      payload.discountValue = 0;

    switch (_.get(row, 'discountType.code')) {
      case 'PERCENTAGE':
        delete payload.flatValue;
        // delete payload.netPrice;
        delete payload.markup;
        break;
      case 'FLAT':
        delete payload.discountValue;
        delete payload.discountExtra;
        delete payload.netPrice;
        delete payload.markup;
        break;
      case 'FIXED':
        delete payload.discountValue;
        delete payload.discountExtra;
        delete payload.flatValue;
        delete payload.markup;
        break;
      case 'MARKUP':
        delete payload.discountValue;
        delete payload.discountExtra;
        delete payload.flatValue;
        delete payload.netPrice;
        break;
      default:
        break;
    }
    OfferRowService.update(payload)
      .then(response => {
        if (!silentMode) {
          const dataToModify = [...offerRows];
          const index = dataToModify.findIndex(
            item => item.id === response.data.id,
          );
          dataToModify[index] = response.data;
          setOfferRows(dataToModify);
          cancelEditRow(response.data.id);
          setOfferRowUpdating({ [id]: false });
          addNotification({
            title: null,
            message: 'Success',
            isError: false,
          });
          loadData();
        }
      })
      .catch(err => {
        setOfferRowUpdating(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const saveRows = () =>
    new Promise((resolve, reject) => {
      const rows = [];
      editableRowsData.forEach(row => {
        const rowPayload = {
          ...row,
          product: _.get(row, 'product.value', null),
          discountType: _.get(
            row,
            'discountType.value',
            _.get(row, 'discountType.label', null),
          ),
          loan: _.get(row, 'loan') === true ? 1 : 0,
          goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
          discountValue:
            _.get(row, 'discountValue.value', null) ||
            _.get(row, 'discountValue', null),
          extraDiscount:
            _.get(row, 'discountExtra.value', null) ||
            _.get(row, 'discountExtra', null),
          markup:
            _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
          flatValue:
            _.get(row, 'flatValue.value', null) ||
            _.get(row, 'flatValue', null),
          netPrice:
            _.get(row, 'netPrice.value', null) || _.get(row, 'netPrice', null),
        };
        delete rowPayload.discountExtra;

        switch (_.get(row, 'discountType.code')) {
          case 'PERCENTAGE':
            delete rowPayload.flatValue;
            // delete rowPayload.netPrice;
            delete rowPayload.markup;
            break;
          case 'FLAT':
            delete rowPayload.discountValue;
            delete rowPayload.discountExtra;
            delete rowPayload.netPrice;
            delete rowPayload.markup;
            break;
          case 'FIXED':
            delete rowPayload.discountValue;
            delete rowPayload.discountExtra;
            delete rowPayload.flatValue;
            delete rowPayload.markup;
            break;
          case 'MARKUP':
            delete rowPayload.discountValue;
            delete rowPayload.discountExtra;
            delete rowPayload.flatValue;
            delete rowPayload.netPrice;
            break;
          default:
            break;
        }

        rows.push(rowPayload);
      });
      const payLoad = {
        offerRows: rows,
      };

      OfferRowService.updateRows(payLoad)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });

  const loadData = () => {
    if (!values.id) {
      return;
    }

    setLoading(true);
    request({
      baseURL: API.BASE_URL,
      url: `${API.OFFER}/${values.id}`,
      method: 'GET',
      params: {},
      paramsSerializer: params => qs.stringify(params),
    }).then(response => {
      const offer = _.get(response, 'data');
      setValues({
        ...values,
        nonCompliant: _.get(offer, 'nonCompliant', null),
        nonCompliantAverageDiscount: _.get(
          offer,
          'nonCompliantAverageDiscount',
          null,
        ),
        nonCompliantProductDiscount: _.get(
          offer,
          'nonCompliantProductDiscount',
          null,
        ),
        avarageDiscount: _.get(offer, 'avarageDiscount', null),
        avarageMarkup: _.get(offer, 'avarageMarkup', null),
        totalPriceAmount: _.get(offer, 'totalPriceAmount', null),
        quantity: null,
        product: null,
        discountValue: null,
        extraDiscount: null,
        markup: null,
        flatValue: null,
        netPrice: null,
        category: null,
        subcategory: null,
        domain: null,
      });
      // Update compliants fields
      handleUpdateCompliant(offer);

      let rows = _.get(response, `data._embedded.rows`, []);
      rows = rows.sort((a, b) => (a.rowNumber < b.rowNumber ? -1 : 1));
      if (rows.length === 0) {
        setLoading(false);
        setOfferRows([]);
      } else {
        /** Check if there are already ordered offer-rows */
        ProductService.getAlreadyCreated(false, values.destination.value).then(
          res => {
            setLoading(false);
            const ids = _.get(res, 'data._embedded.product', []).map(
              product => product.id,
            );
            rows.forEach((row, index) => {
              rows[index].isOrdered = ids.includes(row.product.id);
              rows[index].isBuyPriceModified =
                row.buyPrice !==
                _.get(row, 'product.productStock.buyPrice', null);

              let discount = 0;
              let discount1 = parseFloat(rows[index].discountValue, 10);
              let discount2 = parseFloat(rows[index].extraDiscount, 10);

              if (!isNaN(discount1)) discount += discount1;
              else discount1 = 0;
              if (!isNaN(discount2)) discount += discount2;
              else discount2 = 0;

              if (discount > 0) {
                rows[index].discountValue = parseFloat(
                  discount1.toString(),
                ).toFixed(2);
                rows[index].extraDiscount = parseFloat(
                  discount2.toString(),
                ).toFixed(2);
              }

              rows[index].rowClassKey = row.notCompliant
                ? 'row-not-compliant'
                : 'row-compliant';
            });


            rows = rows.map(r => {
              if (r.buyPrice === null || r.buyPrice === undefined || r.buyPrice === '') {
                r.buyPrice = "0.00";
              }
              return r;
            });

            setOfferRows(rows);
            const editRows = manageEditableRows(rows);

            editRows.forEach(row => {
              if (row.loan || row.goodsDiscount) {
                row.discountDisabled = true;
                row.discountExtraDisabled = true;
                row.netPriceDisabled = true;
              }
            });
          },
        );
      }
    });
  };

  const handleDuplication = () => {
    setIsDuplicating(true);
    const duplicateData = {
      expirationDate:
        _.get(values, 'expirationDate', null) &&
          moment(values.expirationDate).isValid()
          ? moment(values.expirationDate).format('YYYY-MM-DD')
          : null,
      typology: _.get(values, 'typology.value', null),
      destination: _.get(values, 'destination.value', null),
      isDuplicate: true,
      finalized: 1,
      offerId: _.get(values, 'id', null),
      totalBuyPriceAmount: _.get(values, 'totalBuyPriceAmount', 0),
      totalGrossPriceAmount: _.get(values, 'totalGrossPriceAmount', 0),
      totalPriceAmount: _.get(values, 'totalPriceAmount', 0),
      port: _.get(values, 'port', ''),
      payment: _.get(values, 'payment', ''),
      subject: _.get(values, 'subject', ''),
      delivery: _.get(values, 'delivery', ''),
      avarageDiscount: _.get(values, 'avarageDiscount', null),
      avarageMarkup: _.get(values, 'avarageMarkup', null),
      nonCompliant: _.get(values, 'nonCompliant', null),
      nonCompliantAvarageDiscount: _.get(
        values,
        'nonCompliantAvarageDiscount',
        null,
      ),
      nonCompliantProductDiscount: _.get(
        values,
        'nonCompliantProductDiscount',
        null,
      ),
      nonCompliantProductMarkup: _.get(
        values,
        'nonCompliantProductMarkup',
        null,
      ),
      maxDiscount: _.get(values, 'maxDiscount', null),
    };
    OfferService.duplicate(duplicateData)
      .then(() => {
        setOpenDuplicateModal(false);
        addNotification({
          title: null,
          message: props.intl.formatMessage(messages.duplicateSuccess),
          isError: false,
        });
        props.history.goBack();
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => {
        setIsDuplicating(false);
      });
  };

  const insertOfferRow = (flagPromotion = null) => {
    setInserting(true);
    const payload = {
      destination: _.get(values, 'destination.value', null),
      offer: values.id,
      products: _.get(values, 'product.value')
        ? [_.get(values, 'product.value')]
        : [],
      productKit: _.get(values, 'productKit.value', null),
      category: _.get(values, 'productCategory.value', null),
      subcategory: _.get(values, 'subcategory.value', null),
      quantity: _.get(values, 'quantity', 1),
      markup: _.get(values, 'markup', 0),
      flatValue: _.get(values, 'flatValue', 0),
      netPrice: _.get(values, 'netPrice', 0),
      domain: _.get(values, 'productDomain.value', null),
      frequency: _.get(values, 'frequency.value', null),
    };

    if (promotionPrice && promotionPrice !== null) {
      if (flagPromotion === 1) {
        payload.applyPromotion = 1;
      } else if (flagPromotion === 0) {
        payload.applyPromotion = 0;
      }
    } else {
      delete payload.applyPromotion;
    }
    OfferRowService.create(payload)
      .then(res => {
        setInserting(false);
        if (
          _.get(res, 'data.promotionStatus') &&
          _.get(res, 'data.promotionStatus') === 1
        ) {
          setPromotionPrice(_.get(res, 'data.promotionPrice'));
        } else {
          setPromotionPrice(null);
          addNotification({
            title: props.intl.formatMessage(appMessages.success),
            message: _.get(
              res,
              'data.message',
              props.intl.formatMessage(appMessages.success),
            ),
            isError: false,
          });
          loadData();
          setValues({
            ...values,
            productKit: null,
            product: null,
            productCategory: null,
            subcategory: null,
          });

          OfferService.findOneById(values.id).then(offer => {
            if (_.get(offer, 'data.state', null)) {
              setFieldValue('state', _.get(offer, 'data.state', ''));
            }
          });
        }
      })
      .catch(err => {
        setInserting(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const handleOnArticleRowChange = (products, data) => {
    setLoading(true);
    OfferRowService.remove([{ id: data.id }]).then(removeResponse => {
      const row = editableRowsData.find(x => x.id === data.id);
      const payload = {
        destination: _.get(values, 'destination.value', null),
        offer: values.id,
        products: [products],
        quantity: row.quantity,
        flatValue: data.flatValue,
        discountValue: row.discountValue.value,
        extraDiscount: row.discountExtra.value,
        domain: _.get(values, 'productDomain.value', null),
        frequency: _.get(values, 'frequency.value', null),
        goodsDiscount: row.goodsDiscount,
        loan: row.loan,
      };

      OfferRowService.create(payload)
        .then(() => {
          setLoading(false);
          loadData();
          OfferService.findOneById(values.id).then(offer => {
            if (_.get(offer, 'data.state', null)) {
              setFieldValue('state', _.get(offer, 'data.state', ''));
            }
          });
        })
        .catch(err => {
          setInserting(false);
          addNotification({
            title: props.intl.formatMessage(appMessages.error),

            message: _.get(
              err,
              'data.detail',
              props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          });
        });
    });
  };

  const reconciliation = (Ids, elements) =>
    elements.map(elemento =>
      Object.assign({}, elemento, {
        'entitygrid-selected': Boolean(includes(Ids, elemento.id)),
      }),
    );

  const getElements = () => {
    const recon = reconciliation(selectedRows, offerRows);
    return recon;
  };

  const onSelectedRow = row => {
    setSelectedRows([...selectedRows, row.id]);
  };

  const onDeselectedRow = row => {
    setSelectedRows(selectedRows.filter(id => id !== row.id));
  };

  const onSelectAllRows = () => {
    const selectedVisibleIds = offerRows.map(el => el.id);
    setSelectedRows([...new Set([...selectedRows, ...selectedVisibleIds])]);
  };

  const onDeselectAllRows = () => {
    const selectedVisibleIds = offerRows.map(el => el.id);
    const cleanIds = selectedRows.filter(
      id => !includes(selectedVisibleIds, id),
    );
    setSelectedRows(cleanIds);
  };

  const removeArticle = id => {
    OfferRowService.remove([{ id }])
      .then(res => {
        loadData();
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
      })
      .catch(error =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  const removeSelectedArticles = () => {
    const data = selectedRows.map(row => ({ id: row }));
    OfferRowService.remove(data)
      .then(res => {
        setSelectedRows([]);
        loadData();
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
      })
      .catch(error =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  const validateOfferRow = () => {
    if (
      values.quantity &&
      values.quantity !== '' &&
      (values.discountType && values.discountType !== null)
    ) {
      switch (values.discountType.code) {
        case 'PERCENTAGE':
          if (
            (values.discountValue && values.discountValue !== '') ||
            values.discountValue === 0
          ) {
            return false;
          }
          return true;
        case 'FLAT':
          if (
            (values.flatValue && values.flatValue !== '') ||
            values.flatValue === 0
          ) {
            return false;
          }
          return true;
        case 'FIXED':
          if (
            (values.netPrice && values.netPrice !== '') ||
            values.netPrice === 0
          ) {
            return false;
          }
          return true;
        case 'MARKUP':
          if ((values.markup && values.markup !== '') || values.markup === 0) {
            return false;
          }
          return true;
        default:
          return false;
      }
    }
    return true;
  };

  const closeModal = () => {
    handleActiveType('Famiglia');
  };

  const onSubmit = (event, data) => {
    // Save the rows
    saveRows()
      .then(() => {
        // Push the offer submit to the parent
        handleSubmit(event, data);
      })
      .catch(err => {
        addNotification({
          title: null,
          message: get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const updateTotal = data => {
    const { netPrice, quantity } = editableRowsData.find(
      row => row.id === data.id,
    );
    modifyRowDataPayload(
      netPrice * parseFloat(quantity, 10),
      'amount',
      data.id,
    );
  };

  const updateDifference = data => {
    const row = editableRowsData.find(
      r => r.id === data.id,
    );

    modifyRowDataPayload(
      ((row.netPrice - row.competitorPrice) * 100 * 1) / row.competitorPrice,
      'difference',
      data.id,
    );
    if (row.difference === Infinity) {
      modifyRowDataPayload(
        '0.00',
        'difference',
        data.id,
      );
    }
  }

  const updateNetPrice = (netPrice, id, discount1, discount2) => {
    if (discount1 + discount2 < 100)
      if (discount1 !== '') {
        const percentage = 100 - (discount1 + discount2);
        modifyRowDataPayload(
          parseFloat((netPrice / 100) * percentage).toFixed(2),
          'netPrice',
          id,
        );
      }

    return (netPrice / 100) * 100 - (discount1 + discount2);
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <OfferStateSection
          {...props}
          isDisabled={!PermissionManager.CanI('statusUpdate', 'custom')}
        />
        <Accordion title={props.intl.formatMessage(messages.insertArticle)}>
          <Wrapper style={{ margin: '1rem 0' }}>
            <InsertNewPart
              {...props}
              isOffer
              handleActiveType={handleActiveType}
              activeType={activeType}
              categories={categories}
              isInserting={isInserting}
              closeModal={closeModal}
              insertOrderRow={insertOfferRow}
              setDiscountModalOpened={setDiscountModalOpened}
              handleNewDiscountItem={handleNewDiscountItem}
              readOnly={readOnly}
            />
          </Wrapper>
        </Accordion>
        {props.values.nonCompliant && (
          <Wrapper>
            <List>
              <ListHeader
                style={{
                  color: 'red',
                  fontStyle: 'bold',
                }}
              >
                {props.intl.formatMessage(messages.attention)}
              </ListHeader>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantAverageDiscount &&
                  props.intl.formatMessage(
                    messages.non_compliant_average_discount,
                  )}
              </ListItem>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantProductDiscount > 0 &&
                  `${props.values.nonCompliantProductDiscount
                  } ${props.intl.formatMessage(
                    messages.non_compliant_product_discount,
                    {
                      value: props.values.nonCompliantProductDiscount,
                    },
                  )}`}
              </ListItem>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantInsolvent &&
                  props.intl.formatMessage(messages.non_compliant_insolvent)}
              </ListItem>
            </List>
          </Wrapper>
        )}
        <Accordion title={props.intl.formatMessage(messages.article)}>
          <Wrapper style={{ margin: '1rem 0' }}>
            <div
              style={{ width: '100%', display: 'block', overflowX: 'scroll' }}
            >
              <DeterchimicaTableGrid
                isLoading={isLoading}
                elements={getElements()}
                rowClassKey="rowClassKey"
                columns={columns()}
                canSort={{
                  active: true,
                }}
                canSelect={{
                  active: true,
                  selectAll: true,
                  onSelect: row => onSelectedRow(row),
                  onDeselect: row => onDeselectedRow(row),
                  onSelectAll: () => onSelectAllRows(),
                  onDeselectAll: () => onDeselectAllRows(),
                  isSelectedProperty: 'entitygrid-selected',
                }}
                canPaginate={{
                  active: false,
                  pageCount: pagination.pageCount,
                  pageSize: pagination.pageSize,
                  totalItems: pagination.totalItems,
                  page: pagination.page,
                  onSelect: pageNumber => {
                    setPagination({ ...pagination, page: pageNumber });
                    loadData();
                  },
                  pageMax: pagination.pageCount,
                }}
                canAction={{
                  active: true,
                  actions: [
                    <Grid stackable style={{ marginBottom: 0 }}>
                      <Grid.Row>
                        <Grid.Column width={6}>
                          <div
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ marginLeft: 10 }}>
                              {`${props.intl.formatMessage(
                                messages.total_offer,
                              )} € ${values.totalPriceAmount
                                ? parseFloat(values.totalPriceAmount).toFixed(
                                  2,
                                )
                                : 0
                                } -
                              ${props.intl.formatMessage(
                                  messages.avarageDiscount,
                                )} % ${values.avarageDiscount
                                  ? parseFloat(values.avarageDiscount).toFixed(
                                    2,
                                  )
                                  : 0
                                } -
                              ${props.intl.formatMessage(
                                  messages.avarageMarkup,
                                )} % ${values.avarageMarkup
                                  ? parseFloat(values.avarageMarkup).toFixed(2)
                                  : 0
                                }
                            `}
                            </span>
                          </div>
                        </Grid.Column>
                        {!readOnly && (
                          <Grid.Column
                            style={{ textAlign: 'right' }}
                            width={10}
                          >
                            <Button
                              type="button"
                              icon
                              labelPosition="left"
                              disabled={selectedRows.length === 0}
                              onClick={() => {
                                setDiscountModalOpened(true);
                                setArticle({});
                              }}
                              color="green"
                            >
                              <Icon name="plus" />
                              {props.intl.formatMessage(
                                messages.discount_selected,
                              )}
                            </Button>
                            <Button
                              type="button"
                              icon
                              labelPosition="left"
                              disabled={selectedRows.length === 0}
                              onClick={() =>
                                confirm(
                                  props.intl.formatMessage(
                                    appMessages.are_you_sure,
                                  ),
                                )
                                  ? removeSelectedArticles()
                                  : null
                              }
                              color="red"
                            >
                              <Icon name="trash" />
                              {props.intl.formatMessage(
                                messages.remove_selected,
                              )}
                            </Button>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>,
                  ],
                }}
                hiddenHeaderIfEmpty
                emptyResults={<div>No results!</div>}
              />
            </div>
          </Wrapper>
        </Accordion>
        <Modal
          open={discountModalOpened}
          onClose={handleCloseDiscountModal}
          title={isNewDiscountItem ? 'Nuovo' : 'Modifica'}
          size="mini"
          style={{ width: 650 }}
          scrolling
        >
          <DiscountForm
            entityId={props.values.id}
            isNew={activeType}
            destination={_.get(props, 'values.destination.value', null)}
            entity="offer"
            close={handleCloseDiscountModal}
            selectedRows={selectedRows}
            initialValues={article}
            update={() => loadData()}
            updateCompliant={offer => handleUpdateCompliant(offer)}
            isNewDiscountItem={isNewDiscountItem}
          />
        </Modal>
        <NoteSection
          {...props}
          isOrder={false}
          isOpen={notesOpen}
          setIsNotesOpen={setNotesOpen}
        />
        <Modal
          open={openDuplicateModal}
          onClose={() => {
            setOpenDuplicateModal(false);
            // setFieldValue('articleType', 'Famiglia');
          }}
          title={props.intl.formatMessage(messages.duplication)}
          size="mini"
          style={{ width: 400 }}
        >
          <ModalFakeActions noBorder>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <Button
                type="button"
                icon
                labelPosition="left"
                onClick={() => setOpenDuplicateModal(false)}
              >
                <Icon name="remove" />
                {props.intl.formatMessage(appMessages.cancel)}
              </Button>
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                textAlign: 'right',
              }}
            >
              <Button
                type="button"
                icon
                labelPosition="left"
                color="green"
                disabled={isDuplicating}
                loading={isDuplicating}
                onClick={handleDuplication}
              >
                <Icon name="checkmark" />
                {props.intl.formatMessage(messages.duplicate)}
              </Button>
            </div>
          </ModalFakeActions>
        </Modal>
        <FormActionsBar
          {...props}
          editRoute={`/offer/${values.id}/modify`}
          moreButtons={[
            (initialValues.state === OFFER_STATE_ACCEPTED ||
              user.userIdentity.roles.includes('ROLE_BACKOFFICE') ||
              user.userIdentity.roles.includes('ROLE_ADMIN') ||
              user.userIdentity.roles.includes('ROLE_SUPERVISOR')) && (
              <FormExportPdfBtn
                loading={false}
                action={() => exportOfferPdf(values.id)}
              />
            ),
            <FormDuplicateBtn
              loading={false}
              action={() => setOpenDuplicateModal(true)}
              label={props.intl.formatMessage(messages.duplicate)}
            />,
          ].filter(b => b)}
        />
      </Form>
    </>
  );
};

// const extractFromState = ({field, state}) => state[field] || '';

ManageForm.propTypes = {
  handleSubmit: PropTypes.func,
  intl: intlShape.isRequired,
  data: PropTypes.shape().isRequired,
};

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ManageForm);
