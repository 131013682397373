import appMessages from '../../../containers/App/messages';
import messages from './messages';

function Validation(values, intl, isProspect = false) {
  const errors = {};

  /* Customer only requirements */

  if (!isProspect) {

    // Customer must be selected
    if (!values.customer) {
      errors.customer = {
        required: intl.formatMessage(appMessages.required_field),
      };
    }
    // Vat number OR fiscal code is required
    if (!values.vatNumber && !values.businessFiscalCode) {
      errors.vatNumber = {
        required: intl.formatMessage(appMessages.required_field),
      };
      errors.businessFiscalCode = {
        required: intl.formatMessage(appMessages.required_field),
      };
    }
    /** Delivery is required */
    if (!values.delivery) {
      errors.delivery = {
        required: intl.formatMessage(appMessages.required_field),
      };
    }
  }

  /** Prospect only requirements */
  if (isProspect) {
    // If customer is selected, the assign button must be used instead of the subimit one

    if (!values.companyName && values.createdAt !== undefined) {
      errors.companyName = {
        required: intl.formatMessage(appMessages.required_field),
      };
    }
    if (values.customer) {
      errors.customer = {
        required: intl.formatMessage(messages.use_assign_button),
      };
    }
  }

  /* Common requirements both for customer and prospect */

  // Agent must be selected
  if (!values.agent1) {
    errors.agent1 = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }
  // Business name is required
  if (!values.businessName) {
    errors.businessName = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }
  // TODO restore after data sanitation
  // Business street is required
  // if (!values.businessStreet) {
  //   errors.businessStreet = { required: intl.formatMessage(appMessages.required_field) };
  // }
  // Business city is required
  if (!values.businessCity) {
    errors.businessCity = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }
  // Business province (short) is required
  if (!values.businessProvinceShort) {
    errors.businessProvinceShort = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }
  // Business postal code is required
  if (!values.businessPostalCode) {
    errors.businessPostalCode = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }
  // TODO restore after data sanitation
  // Business country is required
  // if (!values.businessCountry) {
  //   errors.businessCountry = { required: intl.formatMessage(appMessages.required_field) };
  // }

  // Phone number is required
  if (!values.phoneNumber) {
    errors.phoneNumber = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }

  // Contact person is required
  if (!values.contactPerson) {
    errors.contactPerson = {
      required: intl.formatMessage(appMessages.required_field),
    };
  }

  // Phone number must be valid
  if (values.phoneNumber) {
    const validRegex = '^[0-9]*$';
    if (!values.phoneNumber.match(validRegex)) {
      errors.phoneNumber = {
        required: intl.formatMessage(appMessages.not_valid_phone_number),
      };
    }
  }

  // Mobile phone number must be valid
  if (values.mobileNumber) {
    const validRegex = '^[0-9]*$';
    if (!values.mobileNumber.match(validRegex)) {
      errors.mobileNumber = {
        required: intl.formatMessage(appMessages.not_valid_phone_number),
      };
    }
  }

  // Email must be valid
  if (values.email) {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!values.email.match(validRegex)) {
      errors.email = {
        required: intl.formatMessage(appMessages.not_valid_email),
      };
    }
  }

  return errors;
}

export default Validation;
